<template>
  <div>
    <v-dialog
      eager
      @update:model-value="cancel"
      :model-value="state.showWarnings"
      scrim="rgba(33, 33, 33)"
      max-width="500"
      :transition="false"
      @keydown.esc="cancel"
      opacity="0"
      content-class="changesWarning-for-test"
    >
      <v-card tile>
        <v-toolbar flat color="primary" :height="4"> </v-toolbar>
        <v-card-title>{{ target.message || state.title }}</v-card-title>
        <v-card-text class="text-subtitle-1 text-body-1 text-medium-emphasis py-3"> {{ state.message }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn size="small" variant='tonal' @click="cancel" test-id="cancel-btn"> Cancel </v-btn>
          <v-btn size="small" variant='tonal' @click="leave" test-id="leave-btn"> Leave </v-btn>
          <v-btn size="small" variant='flat'  color="primary" @click="save" v-if="target.onSave" test-id="save-btn"> Save & Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import ChangeManager from "@/services/ChangeManager";
import { Component, Vue, toNative } from "vue-facing-decorator";

@Component({
  components: {},
})
class ChangesWarning extends Vue {
  currentKey = "";
  state = ChangeManager.state();

  get target() {
    const key = this.getActiveKey();

    if (!key) return {};
    this.currentKey = key;
    const data = this.state.targets[key];
    return data;
  }

  getActiveKey(): void | string {
    const key = Object.keys(this.state.targets).find((key) => this.state.targets[key]?.isChanged);
    return key;
  }

  cancel() {
    ChangeManager.hide({ isCancel: true });
  }

  leave() {
    ChangeManager.leave();
  }

  isErrorAfterSave(targetKey: string) {
    return this.getActiveKey() === targetKey;
  }

  isStackChanged(stackLength: number) {
    return stackLength !== ChangeManager.stack.length;
  }

  async save() {
    const currentKey = this.currentKey;
    const stackLength = ChangeManager.stack?.length;
    ChangeManager.hide();
    await this.target.onSave();

    setTimeout(() => {
      if (!this.isErrorAfterSave(currentKey) && (!this.isStackChanged(stackLength) || this.state.location)) {
        ChangeManager.show();
      }
    }, 500);
  }
}

export default toNative(ChangesWarning);
</script>
