<template>
  <v-card flat rounded="0" min-height="100%" class="main-navigation">
    <v-list-item density="comfortable" class="bg-primary py-3">
      <v-row align="center" no-gutters>
        <v-col cols="8" class="pa-0 pr-2 text-dark">
          <v-list-item-title class="text-h6 font-weight-medium">Safedrive</v-list-item-title>
          <v-list-item-subtitle opacity="1" class="text-darkgrey">Studio</v-list-item-subtitle>
        </v-col>
        <v-col cols="4" class="pa-0 text-right">
          <v-chip v-if="environment" label :color="environmentColor" variant="flat" class="font-weight-bold text-white">{{
            environment
          }}</v-chip>
        </v-col>
      </v-row>
    </v-list-item>

    <v-list v-if="user">
      <v-list-item to="/dashboard" class="py-4">
        <template v-slot:prepend>
          <v-icon class="mr-8">mdi-view-dashboard</v-icon>
        </template>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="canViewCustomers" to="/customers" class="py-4">
        <template v-slot:prepend>
          <v-icon class="mr-8">mdi-account-box-multiple</v-icon>
        </template>
        <v-list-item-title>Customers</v-list-item-title>
      </v-list-item>

      <v-list-group v-if="canViewDevices" group="devices" active-class="nav-active" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Devices" class="py-4">
            <template v-slot:prepend>
              <v-icon class="mr-8">mdi-tablet-cellphone</v-icon>
            </template>
          </v-list-item>
        </template>

        <v-list-item v-if="canViewDeviceList" active-class="nav-active" to="/devices/list">
          <v-list-item-title>List</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="canViewDeviceLogs" active-class="nav-active" to="/devices/logs">
          <v-list-item-title>Logs</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewPoi" group="poi" active-class="nav-active" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="POI" class="py-4">
            <template v-slot:prepend>
              <v-icon class="mr-8">mdi-map-marker-multiple</v-icon>
            </template>
          </v-list-item>
        </template>

        <v-list-item active-class="nav-active" to="/poi/list">
          <v-list-item-title>List</v-list-item-title>
        </v-list-item>

        <v-list-item active-class="nav-active" to="/poi/map">
          <v-list-item-title>Map</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewAds" group="advertisement" active-class="nav-active" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Advertisement" class="py-4">
            <template v-slot:prepend>
              <v-icon class="mr-8">mdi-basket</v-icon>
            </template>
          </v-list-item>
        </template>

        <v-list-item active-class="nav-active" to="/advertisement/adverts">
          <v-list-item-title>Adverts</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="canEditAdsSettings" active-class="nav-active" to="/advertisement/settings">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewSupport" group="support" active-class="nav-active" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Support" class="py-4">
            <template v-slot:prepend>
              <v-icon class="mr-8">mdi-message-reply-text</v-icon>
            </template>
          </v-list-item>
        </template>

        <v-list-item v-if="canViewIssues" active-class="nav-active" to="/support/issues">
          <v-list-item-title>Issues</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewAdmin" group="tools" active-class="nav-active" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Tools" class="py-4">
            <template v-slot:prepend>
              <v-icon class="mr-8">mdi-wrench</v-icon>
            </template>
          </v-list-item>
        </template>

        <v-list-item active-class="nav-active" to="/tools/geohash-map">
          <v-list-item-title>Geohash Map</v-list-item-title>
        </v-list-item>

        <v-list-item active-class="nav-active" to="/tools/device-message-coding">
          <v-list-item-title>Device msg de/encode</v-list-item-title>
        </v-list-item>

        <v-list-item active-class="nav-active" to="/tools/location-history">
          <v-list-item-title>Device Location History</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="canViewAdmin" group="admin" active-class="nav-active" prepend-icon="" no-action>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="Admin" class="py-4">
            <template v-slot:prepend>
              <v-icon class="mr-8">mdi-cog</v-icon>
            </template>
          </v-list-item>
        </template>

        <v-list-item active-class="nav-active" to="/admin/users">
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item>

        <v-list-item active-class="nav-active" to="/admin/user-groups">
          <v-list-item-title>User Groups</v-list-item-title>
        </v-list-item>
        <v-list-item active-class="nav-active" to="/admin/api-keys">
          <v-list-item-title>API Keys</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import appResource from "@/resources/AppResource";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";

@Component({
  components: {},
})
class SidebarNav extends Vue {
  environment = "";
  environmentColor = "blue darken-2";
  get user() {
    return userProfileService.currentUser;
  }
  get canViewCustomers() {
    return (
      this.user &&
      (this.user.isAdministrator || this.user.userPermissions.some((p) => p === UserPermissionType.ViewCustomers))
    );
  }
  get canViewDeviceList() {
    return (
      this.user && (this.user.isAdministrator || this.user.userPermissions.some((p) => p === UserPermissionType.ViewDevices))
    );
  }
  get canViewDeviceLogs() {
    return (
      this.user &&
      (this.user.isAdministrator || this.user.userPermissions.some((p) => p === UserPermissionType.ViewDeviceLogs))
    );
  }
  get canViewDevices() {
    return this.canViewDeviceList;
  }
  get canViewPoi() {
    return (
      this.user && (this.user.isAdministrator || this.user.userPermissions.some((p) => p === UserPermissionType.ViewPoi))
    );
  }
  get canViewIssues() {
    return (
      this.user && (this.user.isAdministrator || this.user.userPermissions.some((p) => p === UserPermissionType.ViewIssues))
    );
  }
  get canViewSupport() {
    return this.canViewIssues;
  }
  get canViewAds() {
    return (
      this.user && (this.user.isAdministrator || this.user.userPermissions.some((p) => p === UserPermissionType.ViewAds))
    );
  }
  get canEditAdsSettings() {
    return (
      this.user &&
      (this.user.isAdministrator || this.user.userPermissions.some((p) => p === UserPermissionType.EditAdsSettings))
    );
  }
  get canViewAdmin() {
    return this.user && this.user.isAdministrator;
  }

  get isLocalOrTest() {
    return document.location.host.includes("localhost") || document.location.host.includes("studio-test.mysafedrive.io");
  }

  created() {
    appResource
      .getInfo()
      .then((resp) => {
        if (resp.data.environment) {
          this.environment = resp.data.environment;
          this.environmentColor = "red";
        }
      })
      .catch(appResource.defaultErrorHandler);
  }
}
export default toNative(SidebarNav);
</script>

<style scoped></style>
