export default class FileHelper {
    static isAvailableForViewer(mime?: string): boolean {
        return [FileHelper.isAudio, FileHelper.isVideo, FileHelper.isImage, FileHelper.isPdf].some((f) => f(mime));
    }

    static isAudio(mime?: string) {
        return mime?.includes("audio/");
    }
    static isVideo(mime?: string) {
        return mime?.includes("video/");
    }

    static isImage(mime?: string) {
        return mime?.includes("image/");
    }

    static isPdf(mime?: string) {
        return mime?.includes("/pdf");
    }
}
