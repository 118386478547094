<template>
  <v-card-text v-if="canEditRepairs" class="pt-0 pb-10">
    <div class="text-subtitle-1 mb-1">Common repair template</div>
    <div class="d-flex d-flex align-center">
      <v-autocomplete v-model="selectedTemplate" item-title="name" return-object :items="templates" density="compact"
        hide-details class="select" filterable variant="outlined" placeholder="Prefill work items and link issues using a template">
      </v-autocomplete>

      <v-btn class="ml-3" color="primary" @click="applyTemplate"
        :disabled="!selectedTemplate || templateLoading">Apply</v-btn>
    </div>
  </v-card-text>
</template>

<script setup lang="ts">
import { UserPermissionType } from "@/types/UserPermissionType";
import userProfileService from "@/services/UserProfileService";
import RepairTemplate from "@/types/RepairTemplate";
import axios, { CancelTokenSource } from "axios";
import repairResource from "@/resources/RepairResource";
import { computed, onMounted, ref, watch } from "vue";

const emit = defineEmits(["applyTemplate"]);

const templateLoading = ref(false);

const templates = ref<RepairTemplate[]>([]);
const selectedTemplate = ref<RepairTemplate | null>(null);

const canEditRepairs = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.EditRepairs);
});

let templateLoadToken: CancelTokenSource | undefined = undefined;

const getTemplates = () => {
  if (templateLoadToken) {
    templateLoadToken.cancel();
  }

  templateLoadToken = axios.CancelToken.source();
  templateLoading.value = true;
  repairResource
    .getRepairTemplates(templateLoadToken)
    .then((resp) => {
      templates.value = resp.data;
    })
    .catch(repairResource.defaultErrorHandler)
    .finally(() => {
      templateLoading.value = false;
      templateLoadToken = undefined;
    });
};

onMounted(() => {
  getTemplates();
});

const applyTemplate = () => {
  emit("applyTemplate", selectedTemplate.value);
  selectedTemplate.value = null;
};
</script>
<style scoped>
.templateName {
  font-size: 13px;
  font-weight: 500;
}
</style>
