import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3e79898c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "icon-container"
}
const _hoisted_2 = {
  key: 2,
  class: "icon-container"
}
const _hoisted_3 = {
  key: 3,
  class: "icon-container"
}
const _hoisted_4 = { class: "text-icon mt-1" }
const _hoisted_5 = { class: "tools" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    onClick: _ctx.open,
    class: "mr-4 mt-4 preview-card",
    "max-width": "102"
  }, {
    default: _withCtx(() => [
      (_ctx.FileHelper.isImage(_ctx.mime) && !_ctx.isFailedLoad)
        ? (_openBlock(), _createBlock(_component_v_img, {
            key: 0,
            cover: true,
            class: "img",
            height: "100",
            src: _ctx.imgUrl,
            onError: _ctx.showErrIcon
          }, null, 8, ["src", "onError"]))
        : (_ctx.FileHelper.isImage(_ctx.mime) && _ctx.isFailedLoad)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_v_icon, {
                color: "#8e8f90",
                size: "27"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-download-off ")
                ]),
                _: 1
              })
            ]))
          : (_ctx.isIcon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.FileHelper.isVideo(_ctx.mime) || _ctx.FileHelper.isAudio(_ctx.mime))
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      color: "primary",
                      size: "50"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-play-circle")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.textIcon), 1)
              ])),
      (!_ctx.disabled)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 4,
            onClick: _withModifiers(_ctx.removeFile, ["stop","prevent"]),
            class: "close-btn",
            icon: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "17" }, {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-close-circle ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_v_tooltip, {
          location: "bottom",
          color: "black",
          transition: "fade",
          "z-index": "999999"
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("div", _mergeProps(props, { class: "text-caption text-no-wrap text-truncate text-right px-1" }), _toDisplayString(_ctx.name), 17)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, "Filename: " + _toDisplayString(_ctx.name), 1),
            (!_ctx.localFileUrl)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Created: " + _toDisplayString(_ctx.moment(_ctx.file.createdAt).format("lll")) + " by " + _toDisplayString(_ctx.file.createdBy), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["onClick"]))
}