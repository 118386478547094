import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-603db8fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
    scrim: "rgba(33, 33, 33)",
    width: "600"
  }, {
    activator: _withCtx(({ props: activatorProps }) => [
      _createVNode(_component_v_badge, {
        dot: "",
        bordered: "",
        "model-value": _ctx.headersUpdated,
        class: "align-self-end",
        color: "primary",
        "offset-x": "3",
        "offset-y": "3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, _mergeProps({
            variant: "text",
            icon: "",
            size: "x-small",
            title: "Table configuration"
          }, activatorProps, { onClick: _ctx.openDialog }), {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { size: "24" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-format-columns")
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1040, ["onClick"])
        ]),
        _: 2
      }, 1032, ["model-value"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            flat: "",
            color: "primary",
            height: 4
          }),
          _createVNode(_component_v_card_title, { class: "pt-4 pb-3" }, {
            default: _withCtx(() => [
              _createTextVNode(" Table configuration ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, {
            class: _normalizeClass({
          'd-sm-flex list-container pb-1 pt-0': true,
          'mobile-list-container': _ctx.breakpoint.smAndDown.value,
        })
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass({
            'column mr-sm-2 fill-sm-height': true,
            'mobile-column': _ctx.breakpoint.smAndDown.value,
          })
              }, [
                _createVNode(_component_v_list_subheader, { class: "heading" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Available columns")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card, {
                  class: "columns-card",
                  tile: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, {
                      density: "compact",
                      class: "items-list"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.availableColumns.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableColumns, (item, i) => {
                                return (_openBlock(), _createBlock(_component_v_list_item, {
                                  key: i,
                                  selectable: false,
                                  ripple: false,
                                  active: false,
                                  link: false
                                }, {
                                  append: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      variant: "text",
                                      size: "small",
                                      density: "compact",
                                      icon: "",
                                      onClick: ($event: any) => (_ctx.add(item)),
                                      class: "table-settings-remove-btn"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "small" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-arrow-right")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.title), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]))
                          : (_openBlock(), _createBlock(_component_v_list_item, { key: 1 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, { class: "text-grey" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("No more columns available")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass({
            'column ml-sm-2 fill-sm-height': true,
            'mobile-column': _ctx.breakpoint.smAndDown.value,
          })
              }, [
                _createVNode(_component_v_list_subheader, { class: "heading mt-4 mt-sm-auto" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Selected columns")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card, {
                  class: "columns-card",
                  tile: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, {
                      density: "compact",
                      class: "items-list"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.selectedHeaders.length)
                          ? (_openBlock(), _createBlock(_component_draggable, {
                              key: 0,
                              modelValue: _ctx.draggableList,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.draggableList) = $event)),
                              delay: "200",
                              touchStartThreshold: "4",
                              delayOnTouchOnly: true,
                              "ghost-class": "dragged-item-ghost",
                              "item-key": "value"
                            }, {
                              item: _withCtx(({ element, index }) => [
                                _createVNode(_component_v_list_item, {
                                  selectable: false,
                                  ripple: false,
                                  inactive: ""
                                }, {
                                  append: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      variant: "text",
                                      size: "small",
                                      density: "compact",
                                      disabled: index === 0,
                                      icon: "",
                                      onClick: ($event: any) => (_ctx.moveUp(element))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "small" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" mdi-arrow-up ")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1032, ["disabled", "onClick"]),
                                    _createVNode(_component_v_btn, {
                                      variant: "text",
                                      size: "small",
                                      density: "compact",
                                      disabled: index === _ctx.selectedHeaders.length - 1,
                                      icon: "",
                                      onClick: ($event: any) => (_ctx.moveDown(element))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "small" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" mdi-arrow-down ")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1032, ["disabled", "onClick"]),
                                    _createVNode(_component_v_btn, {
                                      variant: "text",
                                      density: "compact",
                                      size: "small",
                                      icon: "",
                                      onClick: ($event: any) => (_ctx.remove(element))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-close")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item_title, { class: "drag" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(element.title), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 1
                            }, 8, ["modelValue"]))
                          : (_openBlock(), _createBlock(_component_v_list_item, {
                              key: 1,
                              disabled: true
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, { class: "text-grey" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("No columns selected")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ], 2)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                variant: "text",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Close ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}