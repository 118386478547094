<template>
  <v-container v-if="canViewLogs" fluid class="fill-height">
    <v-row style="height:100%;margin:0;">
      <v-col cols="12" sm="12" md="12" style="flex: 1 1 auto; padding: 0">
        <iframe
          :src="`${host}/kibana/app/dashboards#/view/${dashboardId}?embed=true&isDark=false&show-query-input=true&show-time-filter=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))&_a=(expandedPanelId:'${panelId}',filters:!(),query:(language:kuery,query:'${queryParams}'))`"
          style="width: 100%; min-height: 100%"
          frameborder="0"
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue , toNative } from "vue-facing-decorator";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import appConfigService from "@/services/AppConfigService";
import commonHelper from "@/helpers/commonHelper";

@Component({
  name: "DeviceLogs", // name is needed for keep-alive
  components: {
  },
})
class DeviceLogs extends Vue {
  get canViewLogs() {
    return userProfileService.hasPermission(UserPermissionType.ViewDeviceLogs);
  }

  get host() {
    return commonHelper.apiHost;
  }

  get dashboardId() {
    return appConfigService.kibanaLogsDashboardId;
  }

  get panelId() {
    return appConfigService.kibanaLogsPanelId;
  }

  get queryParams() {
    var query = [] as string[];
    if(this.$route.query.deviceId) {
      query.push(`ClientId : ${this.$route.query.deviceId}`);
    }
    return query.join(",");
  }
}

export default toNative(DeviceLogs)
</script>

<style scoped>
</style>