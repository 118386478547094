import { IssueStatus } from "@/types/IssueStatus";
import { IssuePriority } from "@/types/IssuePriority";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
export default class IssueHelper {
    static getIssueStatusDisplayName(type: IssueStatus): string {
        switch (type) {
            case IssueStatus.New:
                return "New";
            case IssueStatus.Active:
                return "Active";
            case IssueStatus.Resolved:
                return "Resolved";
            case IssueStatus.Closed:
                return "Closed";
            default:
                return type;
        }
    }

    static getDeviceStatusDisplayName(type: IssueDeviceStatus): string {
        switch (type) {
            case IssueDeviceStatus.New:
                return "New";
            case IssueDeviceStatus.Active:
                return "Active";
            case IssueDeviceStatus.Resolved:
                return "Resolved";
            default:
                return type;
        }
    }

    static getIssueStatusColor(status?: IssueStatus, isText?: boolean) {
        if (isText) {
            return {
                "text-red": IssueStatus.New === status,
                "text-blue": IssueStatus.Active === status,
                "text-green": IssueStatus.Resolved === status,
                "text-grey": IssueStatus.Closed === status,
            };
        }
        return {
            'bg-red': IssueStatus.New === status,
            'bg-blue': IssueStatus.Active === status,
            'bg-green': IssueStatus.Resolved === status,
            'bg-grey': IssueStatus.Closed === status,
        };
    }

    static getDeviceStatusColor(status?: IssueDeviceStatus, asValue?: boolean) {
        if (asValue) {
            switch (status) {
                case IssueDeviceStatus.New:
                    return "red";
                case IssueDeviceStatus.Active:
                    return "blue";
                case IssueDeviceStatus.Resolved:
                    return "green";
            }
        }
        return {
            "text-red": IssueDeviceStatus.New === status,
            "text-blue": IssueDeviceStatus.Active === status,
            "text-green": IssueDeviceStatus.Resolved === status,
        };
    }

    static getIssueFilter() {
        return [
            { text: this.getIssueStatusDisplayName(IssueStatus.New), value: IssueStatus.New },
            { text: this.getIssueStatusDisplayName(IssueStatus.Active), value: IssueStatus.Active },
            { text: this.getIssueStatusDisplayName(IssueStatus.Resolved), value: IssueStatus.Resolved },
            { text: this.getIssueStatusDisplayName(IssueStatus.Closed), value: IssueStatus.Closed },
        ];
    }

    static getIssuePriorityColor(priority: IssuePriority) {
        switch (priority) {
            case IssuePriority.Lowest:
                return "blue";
            case IssuePriority.Low:
                return "blue";
            case IssuePriority.Normal:
                return "yellow";
            case IssuePriority.High:
                return "red";    
            case IssuePriority.Highest:
                return "red";    
        }
    }

    static getIssuePriorityIcon(priority: IssuePriority) {
        switch (priority) {
            case IssuePriority.Lowest:
                return "mdi-chevron-double-down";
            case IssuePriority.Low:
                return "mdi-chevron-down";
            case IssuePriority.Normal:
                return "mdi-equal";
            case IssuePriority.High:
                return "mdi-chevron-up";    
            case IssuePriority.Highest:
                return "mdi-chevron-double-up";    
        }
    }

    static getIssuePriorityName(priority: IssuePriority) {
        switch (priority) {
            case IssuePriority.Lowest:
                return "Lowest";
            case IssuePriority.Low:
                return "Low";
            case IssuePriority.Normal:
                return "Normal";
            case IssuePriority.High:
                return "High";    
            case IssuePriority.Highest:
                return "Highest";    
        }
    }
}
