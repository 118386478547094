<template>
  <v-container>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        Device message decoder/encoder
      </v-card-title>
      <v-card-text>
        <v-sheet>
          <v-row>
            <v-col cols="12" sm="4" lg="3" xl="2">
              <v-select
                v-model="selectedDeviceType" 
                :items="deviceTypes"
                label="Device type"
              ></v-select>
              <v-select
                v-model="selectedMessageProtocolVer" 
                :items="messageProtocolVers"
                label="Message protocol ver."
              ></v-select>
            </v-col>
            <v-col cols="12" sm="8" lg="9" xl="10">
              <v-textarea
                v-model="hexValue" 
                label="HEX"
                class="code-textarea"
                auto-grow 
                filled
                variant="outlined"
                hide-details
                rows="2"
              />
              <div class="my-4">
                <v-btn color="primary" @click="convertToJson">
                  <v-icon start>mdi-arrow-down</v-icon>
                  To JSON
                </v-btn>
                <v-btn color="primary" @click="convertToHex" class="ml-4">
                  <v-icon start>mdi-arrow-up</v-icon>
                  To HEX
                </v-btn>
              </div>
              <v-textarea
                v-model="jsonValue" 
                label="JSON"
                class="code-textarea" 
                auto-grow 
                filled
                variant="outlined"
                hide-details
                rows="4"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
    
  </v-container>
</template>

<script lang="ts">
import { Component, Vue , toNative } from "vue-facing-decorator";
import deviceMessageCodingResource from "@/resources/DeviceMessageCodingResource";

@Component({
  components: {

  },
})
class DeviceMessageCoding extends Vue {
  hexValue = "";
  jsonValue = "";

  selectedDeviceType = "prox";
  deviceTypes = [
    { title: "ProX", value: "prox" }
  ];

  selectedMessageProtocolVer = "6";
  messageProtocolVers = [
    { title: "6", value: "6" }
  ];

  async convertToJson() {
    if (!this.hexValue) {
      return;
    }
    try {
      let response = await deviceMessageCodingResource.getHexToJson(this.hexValue);
      this.jsonValue = response.data;
    } catch (error) {
      deviceMessageCodingResource.defaultErrorHandler(error);
    }
  }

  async convertToHex() {
    if (!this.jsonValue) {
      return;
    }
    try {
      let response = await deviceMessageCodingResource.getJsonToHex(this.jsonValue);
      this.hexValue = response.data;
    } catch (error) {
      deviceMessageCodingResource.defaultErrorHandler(error);
    }
  }
}
export default toNative(DeviceMessageCoding)
</script>

<style scoped>
</style>
