import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoMsg = _resolveComponent("InfoMsg")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "auth-layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_InfoMsg),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, { include: "Login" }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}