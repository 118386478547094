import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["name", "desc", "valign"]
const _hoisted_2 = { class: "property-editor-header" }
const _hoisted_3 = { class: "text-subtitle-1" }
const _hoisted_4 = {
  key: 0,
  class: "desc text-caption text-medium-emphasis text--secondary font-weight-light"
}
const _hoisted_5 = { class: "property-editor-content flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    name: _ctx.name,
    desc: _ctx.desc,
    valign: _ctx.valign,
    class: _normalizeClass(["d-block d-sm-flex property-editor", [_ctx.valignClass, _ctx.titleSizeClass]])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
      (_ctx.desc)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.desc), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 10, _hoisted_1))
}