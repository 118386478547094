<template>
  <div>
    <div v-if="canAddComment" class="mb-6">
      <div class="text-subtitle-1 mb-2">New comment</div>
      <AddDeviceComment v-on:created="addComment" :deviceId="deviceId" />
    </div>

    <div v-if="comments && comments.length">
      <div class="text-subtitle-1 mt-0 mb-2">Comments</div>

      <div v-for="comment in comments" v-bind:key="comment.commentId">
        <v-divider class="my-2" />
        <DeviceComment
          :comment="comment"
          v-on:delete="deleteComment"
          v-on:update="updateComment"
          :deviceId="deviceId"
        />
      </div>

      <div class="d-flex justify-center">
        <v-btn v-if="!loading && moreAvailable" class="my-2" text size="small" @click="getComments">
          <v-icon start>mdi-arrow-down-bold</v-icon>
          Load more
        </v-btn>

        <v-progress-circular v-if="loading" indeterminate color="primary" size="30" class="my-2" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop , toNative } from "vue-facing-decorator";
import Comment from "@/types/Comment";
import { UserPermissionType } from "@/types/UserPermissionType";
import AddDeviceComment from "@/components/devices/DeviceComments/AddDeviceComment.vue";
import DeviceComment from "@/components/devices/DeviceComments/DeviceComment.vue";
import userProfileService from "@/services/UserProfileService";
import deviceResource from "@/resources/DeviceResource";

@Component({
  components: { DeviceComment, AddDeviceComment },
  emits: ["update"]
})
class DeviceComments extends Vue {
  @Prop()
  readonly deviceId!: number | undefined;

  comments: Comment[] = [];
  loading = false;
  moreAvailable = false;

  get canAddComment() {
    return userProfileService.hasPermission(UserPermissionType.ViewDevices);
  }

  mounted() {
    this.getComments();
  }

  getComments() {
    if ((this.comments.length && !this.moreAvailable) || this.deviceId === undefined) {
      return;
    }
    this.loading = true;
    let createdBefore;

    if (this.comments.length) {
      const minDateAsMilliseconds = Math.min(...this.comments.map(({ createdAt }) => new Date(createdAt).getTime()));
      createdBefore = new Date(minDateAsMilliseconds);
    }

    deviceResource
      .getDeviceComments(this.deviceId, undefined, createdBefore)
      .then((resp) => {
        this.comments = [...this.comments, ...resp.data.comments];
        this.moreAvailable = resp.data.moreAvailable;
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  addComment(newComment: Comment) {
    this.comments.unshift(newComment);
    this.$emit('update')
  }

  updateComment(updComment: Comment) {
    this.comments = this.comments.map((comment) => (comment.commentId === updComment.commentId ? updComment : comment));
  }

  deleteComment(deleteCommentId: number) {
    this.comments = this.comments.filter((comment) => comment.commentId !== deleteCommentId);
    this.$emit('update')
  }
}

export default  toNative(DeviceComments)
</script>

<style scoped></style>
