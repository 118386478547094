<template>
  <v-menu
    v-model="showContextMenu"
    position="absolute"
    offset-y
    z-index="999"
    light
    :style="{ left: `${contextMenuX}px`, top: `${contextMenuY}px` }"
  >
    <v-list density="compact" class="py-0">
      <v-list-item @click="contextMenuCopyText">
        <template v-slot:prepend>
          <v-icon size="x-small" class="mr-2">mdi-content-copy</v-icon>
        </template>
        <v-list-item-title>Copy</v-list-item-title>
      </v-list-item>
      <slot />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Model, Component, Prop, Vue, Watch, toNative } from "vue-facing-decorator";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  components: {},
  emits: ["update:modelValue"]
})
class DataTableContextMenu extends Vue {
  @Model({ default: null })
  value!: MouseEvent | null;

  showContextMenu = false;
  contextMenuX = 0;
  contextMenuY = 0;

  @Watch("modelValue")
  onContextItemChanged() {
    if (this.value) {
      this.value.preventDefault();
      this.openContenxMenu();
    }
  }

  @Watch("showContextMenu")
  onShowMenuChanged() {
    if (this.showContextMenu === false) {
      this.$emit("update:modelValue", null);
    }
  }

  openContenxMenu() {
    this.contextMenuX = this.value!.clientX;
    this.contextMenuY = this.value!.clientY;
    this.showContextMenu = true;
  }

  contextMenuCopyText() {
    var elm = this.value!.target as HTMLElement;
    if (elm && elm.textContent) {
      navigator.clipboard.writeText(elm.textContent).catch((e) => {
        infoMessageService.show(InfoMessageType.Error, "Failed to copy text");
      });
    }
  }
}

export default toNative(DataTableContextMenu);
</script>
<style scoped>
:deep() .v-list-item__spacer {
  width: 0px !important;
}
</style>
