import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-right align-self-end mt-0 d-block d-sm-none" }
const _hoisted_2 = { class: "d-flex flex-grow-1" }
const _hoisted_3 = { class: "text-right align-self-end mt-2 d-none d-sm-block" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateIssue = _resolveComponent("CreateIssue")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_TableConfiguration = _resolveComponent("TableConfiguration")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_DataTableFooter = _resolveComponent("DataTableFooter")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-block d-sm-flex" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_CreateIssue)
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.searchTerm,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
                  "prepend-inner-icon": "mdi-magnify",
                  label: "Search",
                  "hide-details": "",
                  clearable: "",
                  "onClick:clear": _cache[1] || (_cache[1] = ($event: any) => (_ctx.search())),
                  onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.search())),
                  onKeypress: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.search(true)), ["enter"])),
                  variant: "underlined",
                  color: "primary"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_btn, {
                  size: "x-small",
                  variant: "text",
                  icon: "",
                  class: "align-self-end ml-4",
                  onClick: _ctx.reload,
                  disabled: _ctx.loading,
                  title: "Refresh"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { size: "24" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-reload")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_TableConfiguration, {
                  allHeaders: _ctx.headers,
                  modelValue: _ctx.selectedHeaders,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedHeaders) = $event)),
                  tableKey: "issuesTableColumns"
                }, null, 8, ["allHeaders", "modelValue"]),
                _createVNode(_component_v_btn, {
                  variant: "text",
                  size: "x-small",
                  icon: "",
                  class: "align-self-end",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showFilter = !_ctx.showFilter)),
                  color: _ctx.showFilter ? 'primary' : undefined,
                  disabled: _ctx.loading,
                  title: "Filters"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { size: "24" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-filter-variant")
                      ]),
                      _: 1
                    }),
                    (_ctx.numberOfFilter)
                      ? (_openBlock(), _createBlock(_component_v_badge, {
                          key: 0,
                          transition: "v-fade-transition",
                          dot: "",
                          bordered: "",
                          "offset-x": "-1",
                          "offset-y": "-10",
                          color: "primary"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["color", "disabled"])
              ]),
              _createVNode(_component_v_spacer, { class: "d-none d-sm-block" }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_CreateIssue)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Filters, {
            show: _ctx.showFilter,
            filter: _ctx.filter,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showFilter = false)),
            onUpdate: _ctx.updateFilter,
            disabled: _ctx.loading
          }, null, 8, ["show", "filter", "onUpdate", "disabled"]),
          _createVNode(_component_v_data_table_server, {
            density: "compact",
            "row-props": _ctx.rowClass,
            headers: _ctx.selectedHeaders,
            items: _ctx.items,
            "items-length": _ctx.total,
            "sort-by": _ctx.sortBy,
            "onUpdate:sortBy": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.sortBy) = $event)),
            "must-sort": true,
            hover: "",
            mobile: false,
            loading: _ctx.loading,
            "mobile-breakpoint": 0,
            "onClick:row": _cache[10] || (_cache[10] = (event, { item }) => _ctx.rowClick(item))
          }, {
            [`item.status`]: _withCtx(({ item }) => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.IssueHelper.getIssueStatusColor(item.status, true))
              }, _toDisplayString(_ctx.getIssueStatusName(item.status)), 3)
            ]),
            [`item.priority`]: _withCtx(({ item }) => [
              _createVNode(_component_v_icon, {
                color: _ctx.IssueHelper.getIssuePriorityColor(item.priority),
                title: _ctx.IssueHelper.getIssuePriorityName(item.priority)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.IssueHelper.getIssuePriorityIcon(item.priority)), 1)
                ]),
                _: 2
              }, 1032, ["color", "title"])
            ]),
            [`item.tags`]: _withCtx(({ item }) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.tags, (tag) => {
                return (_openBlock(), _createBlock(_component_v_chip, {
                  size: "small",
                  class: "ma-1px",
                  key: tag.tagId
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(tag.name), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            [`item.createdAt`]: _withCtx(({ item }) => [
              (item.createdAt)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.moment(item.createdAt).format("lll")), 1))
                : _createCommentVNode("", true)
            ]),
            [`item.lastModifiedAt`]: _withCtx(({ item }) => [
              (item.lastModifiedAt)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.moment(item.lastModifiedAt).format("lll")), 1))
                : _createCommentVNode("", true)
            ]),
            [`item.isArchived`]: _withCtx(({ item }) => [
              _createElementVNode("div", _hoisted_6, [
                (item.isArchived)
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      size: "small",
                      color: "green"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-check")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            bottom: _withCtx(() => [
              _createVNode(_component_DataTableFooter, {
                page: _ctx.page,
                "onUpdate:page": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.page) = $event)),
                items: _ctx.items,
                itemsLength: _ctx.total,
                itemsPerPage: _ctx.itemsPerPage,
                "onUpdate:itemsPerPage": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
                itemsPerPageOptions: [15, 25, 50]
              }, null, 8, ["page", "items", "itemsLength", "itemsPerPage"])
            ]),
            _: 2
          }, 1032, ["row-props", "headers", "items", "items-length", "sort-by", "loading"]),
          _createVNode(_component_v_overlay, {
            "model-value": _ctx.loading,
            opacity: "0",
            contained: "",
            persistent: ""
          }, null, 8, ["model-value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}