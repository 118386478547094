import { DeviceType } from "@/types/DeviceType";
import { DeviceStatus } from "@/types/DeviceStatus";
export default class DeviceHelper {
    static getDeviceTypeDisplayName(type: DeviceType): string {
        switch (type) {
            case DeviceType.GpsRadarPro:
                return "Pro";
            case DeviceType.GpsRadarProX:
                return "ProX";
            case DeviceType.MobileApp:
                return "Mobile App";
            default:
                return type;
        }
    }

    static getDeviceFilterByType() {
        return [
            { text: this.getDeviceTypeDisplayName(DeviceType.GpsRadarPro), value: DeviceType.GpsRadarPro },
            { text: this.getDeviceTypeDisplayName(DeviceType.GpsRadarProX), value: DeviceType.GpsRadarProX },
            { text: this.getDeviceTypeDisplayName(DeviceType.MobileApp), value: DeviceType.MobileApp },
        ];
    }

    static getDeviceStatusDisplayName(state: DeviceStatus): string {
        switch (state) {
            case DeviceStatus.InProduction:
                return "In Production";
            case DeviceStatus.InStore:
                return "In Store";
            case DeviceStatus.Operational:
                return "Operational";
            case DeviceStatus.UnderRepair:
                return "Under Repair";
            case DeviceStatus.Irreparable:
                return "Irreparable";
            case DeviceStatus.Decommissioned:
                return "Decommissioned";
            default:
                return "";
        }
    }

    static getDeviceFilterByStatus() {
        return [
            { text: this.getDeviceStatusDisplayName(DeviceStatus.InProduction), value: DeviceStatus.InProduction },
            { text: this.getDeviceStatusDisplayName(DeviceStatus.InStore), value: DeviceStatus.InStore },
            { text: this.getDeviceStatusDisplayName(DeviceStatus.Operational), value: DeviceStatus.Operational },            
            { text: this.getDeviceStatusDisplayName(DeviceStatus.UnderRepair), value: DeviceStatus.UnderRepair },
            { text: this.getDeviceStatusDisplayName(DeviceStatus.Irreparable), value: DeviceStatus.Irreparable },
            { text: this.getDeviceStatusDisplayName(DeviceStatus.Decommissioned), value: DeviceStatus.Decommissioned },
        ];
    }
}
