import { CustomerType } from "@/types/CustomerType";

export default class CustomerHelper {
    static getCustomerTypeDisplayName(type: CustomerType): string {
        switch (type) {
            case CustomerType.Unknown: return "Unknown";
            case CustomerType.Private: return "Private";
            case CustomerType.Professional: return "Business";
            case CustomerType.Reseller: return "Reseller";
            default: return type;
        }
    }
}