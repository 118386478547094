import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarNav = _resolveComponent("SidebarNav")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_WhatIsNewBtn = _resolveComponent("WhatIsNewBtn")!
  const _component_AppBar = _resolveComponent("AppBar")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_InfoMsg = _resolveComponent("InfoMsg")!
  const _component_ChangesWarning = _resolveComponent("ChangesWarning")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_AppUpdateNotification = _resolveComponent("AppUpdateNotification")!
  const _component_AuthRefresh = _resolveComponent("AuthRefresh")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_navigation_drawer, {
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
        app: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_SidebarNav)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_app_bar, {
        app: "",
        density: "compact",
        class: "pr-4",
        style: {"z-index":"6 !important"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_nav_icon, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drawer = !_ctx.drawer))
          }),
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$route.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_WhatIsNewBtn),
          _createVNode(_component_AppBar)
        ]),
        _: 1
      }),
      (_ctx.inited)
        ? (_openBlock(), _createBlock(_component_v_main, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_InfoMsg),
              _createVNode(_component_ChangesWarning),
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component }) => [
                  (_openBlock(), _createBlock(_KeepAlive, { include: "Dashboard,Customers,DeviceList,DeviceLocationHistory,DeviceLogs,PoiList,PoiMap,Adverts,GeohashMap" }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ], 1024))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_AppUpdateNotification),
      _createVNode(_component_AuthRefresh),
      _createVNode(_component_v_overlay, {
        scrim: false,
        "model-value": !_ctx.inited,
        opacity: "0.3",
        "z-index": "99999",
        class: "align-center justify-center",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_progress_circular, {
            indeterminate: "",
            color: "primary",
            size: "64"
          })
        ]),
        _: 1
      }, 8, ["model-value"])
    ]),
    _: 1
  }))
}