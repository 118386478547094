import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "flex-grow-0" }
const _hoisted_3 = {
  class: "pa-4 flex-grow-1 overflow-auto",
  style: {"overflow-x":"hidden !important"}
}
const _hoisted_4 = { class: "side-sheet-actions pa-4 flex-grow-0 text-right d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_items = _resolveComponent("v-toolbar-items")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_overlay = _resolveComponent("v-overlay")!

  return (_openBlock(), _createBlock(_component_v_overlay, {
    scrim: "rgba(33, 33, 33)",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.show) = $event)),
    opacity: ".33",
    "z-index": "1000"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_dialog, {
        "data-side-sheet": "",
        ref: "sideSheet",
        value: _ctx.value,
        heading: _ctx.heading,
        modelValue: _ctx.show,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event)),
        "no-click-animation": _ctx.noClickAnimation,
        "onClick:outside": _ctx.clickOutside,
        origin: "top center 0",
        transition: "slide-x-reverse-transition",
        class: "side-sheet-dialog",
        "content-class": 'side-sheet ' + _ctx.contentClass,
        opacity: 0,
        onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.show = false), ["esc"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            flat: "",
            rounded: "0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_toolbar, {
                  density: "compact",
                  flat: "",
                  color: "primary text-dark",
                  class: "flex-grow-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.heading), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_toolbar_items, null, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "heading-menu"),
                        _createVNode(_component_v_btn, {
                          icon: "",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { color: "dark" }, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-close")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 3
                    })
                  ]),
                  _: 3
                }),
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "tabs")
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "actions")
                ])
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["value", "heading", "modelValue", "no-click-animation", "onClick:outside", "content-class"])
    ]),
    _: 3
  }, 8, ["modelValue"]))
}