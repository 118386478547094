import authService from "@/services/AuthService";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import axios, { AxiosInstance } from "axios";

export const httpClient = axios.create({
    baseURL: `${process.env?.VUE_APP_API_URL}/api`,
    headers: {
        "Content-type": "application/json",
    },
});

// Auth
httpClient.interceptors.request.use(
    (config) => {
        const token = authService.authInfo.token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
httpClient.interceptors.response.use(
    async (response: any) => {
        if (response?.status === 401) {
            authService.signOut();
        }
        return response;
    },
    async (error: { config: any; response: { status: number; }; }) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            authService.signOut();
        }
        return Promise.reject(error);
    },
);

const vanillaHttpClient = axios.create();

export default class ResourceBase {
    protected apiClient: AxiosInstance = httpClient;
    protected httpClient: AxiosInstance = vanillaHttpClient;

    public defaultErrorHandler(error: any): void {
        // Ignore google geocoder ZERO_RESULTS error or request cancellation error
        if (error.code === "ZERO_RESULTS" || axios.isCancel(error)) {
            return;
        }
  
        if (!error.request || !error.request.status) {
            if (error.message) {
                infoMessageService.show(InfoMessageType.Error, error.message);
            }
            console.log({ error });
        } else {
            ResourceBase.readErrors(error).forEach((errorMsg) => {
                infoMessageService.show(InfoMessageType.Error, errorMsg);
            });
        }
    }

    public static readErrors(error: any): string[] {
        var errors = [] as string[];
        if (
            error.response &&
            error.response.data &&
            error.response.data.errors
        ) {
            for (var key in error.response.data.errors) {
                if (key.length == 1) {
                    // simple error
                    errors.push(`${error.response.data.errors[key]}`);
                } else {
                    // model binder error
                    for (var msg of error.response.data.errors[key]) {
                        errors.push(`${key}: ${msg}`);
                    }
                }
            }
        } else {
            console.log({ error });
            errors.push(error.message + ": " + error.response?.statusText);
        }

        return errors;
    }
}
