import ResourceBase from "@/resources/ResourceBase";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import TopDevice from "@/types/TopDevice";
import ActiveDevicesSum from "@/types/ActiveDevicesSum";
import HourlyDevicesStats from "@/types/HourlyDevicesStats";
import DeviceTypesByVersion from "@/types/DeviceTypesByVersion";

class StatsResource extends ResourceBase {
    getTopActiveDevices(cancelToken?: any): Promise<AxiosResponse<TopDevice[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.get<TopDevice[]>("/stats/top-active-devices", config);
    }

    getActiveDevicesSummary(cancelToken?: any): Promise<AxiosResponse<ActiveDevicesSum>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.get<ActiveDevicesSum>("/stats/active-devices-summary", config);
    }

    getActiveDevicesHourly(
        cancelToken?: any
    ): Promise<AxiosResponse<{ current: HourlyDevicesStats[]; lastYear: HourlyDevicesStats[] }>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.get<{ current: HourlyDevicesStats[]; lastYear: HourlyDevicesStats[] }>(
            "/stats/active-devices-hourly",
            config
        );
    }

    getDeviceTypesByVersion(cancelToken?: any): Promise<AxiosResponse<DeviceTypesByVersion[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.get<DeviceTypesByVersion[]>("/stats/device-ver", config);
    }
}

const statsResource = new StatsResource();
export default statsResource;
