import ResourceBase from "@/resources/ResourceBase";

class GoogleMapsResource extends ResourceBase {
    geocodeAddress(address: string)
        : Promise<google.maps.GeocoderResponse> {
        var geocoder = new google.maps.Geocoder();
        
        var request = {
            address: address,
            region: "no",
        } as google.maps.GeocoderRequest;

        return geocoder.geocode(request);
    }

    reverseGeocodeLocation(latitude: number, longitude: number)
        : Promise<google.maps.GeocoderResponse> {
        var geocoder = new google.maps.Geocoder();
        
        var request = {
            location: new google.maps.LatLng(latitude, longitude),
            region: "no",
        } as google.maps.GeocoderRequest;

        return geocoder.geocode(request);
    }
}

const googleMapsResource = new GoogleMapsResource();
export default googleMapsResource;
