import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6599314f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = {
  key: 0,
  class: "overflow-hidden flex-grow-1"
}
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = ["src", "type"]
const _hoisted_5 = ["src", "type"]
const _hoisted_6 = {
  key: 1,
  class: "overflow-hidden flex-grow-1"
}
const _hoisted_7 = {
  controls: "",
  autoplay: "",
  class: "audio"
}
const _hoisted_8 = ["src", "type"]
const _hoisted_9 = ["data"]
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      scrim: "rgba(33, 33, 33)",
      modelValue: _ctx.dialog,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialog) = $event)),
      "overlay-opacity": "0.75",
      "overlay-color": "#111"
    }, {
      default: _withCtx(() => [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.url && _ctx.isTools)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.toolsStyles)
                }, [
                  (_ctx.url && !_ctx.localFileUrl)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        size: "small",
                        text: "",
                        class: "ma-2 text-white",
                        onClick: _ctx.download
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Download "),
                          _createVNode(_component_v_icon, {
                            end: "",
                            dark: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" mdi-cloud-download ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_btn, {
                    icon: "",
                    density: "compact",
                    class: "ma-2 text-white",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" mdi-close ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isVideo && _ctx.url)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.isUnsupportedMediaErr)
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_3, [
                      _createTextVNode(" Video not supported. Download the video "),
                      _createElementVNode("a", {
                        href: "",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)), ["prevent"]))
                      }, "here"),
                      _createTextVNode(". ")
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isUnsupportedMediaErr)
                  ? (_openBlock(), _createElementBlock("video", {
                      key: 1,
                      controls: "",
                      autoplay: "",
                      class: "video",
                      src: _ctx.url,
                      type: _ctx.mime,
                      onError: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mediaErrorHandler && _ctx.mediaErrorHandler(...args)))
                    }, [
                      _createElementVNode("source", {
                        src: _ctx.url,
                        type: _ctx.mime
                      }, null, 8, _hoisted_5),
                      _createTextVNode(" Your browser doesn't support HTML5 video tag. ")
                    ], 40, _hoisted_4))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isAudio && _ctx.url)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("audio", _hoisted_7, [
                  _createElementVNode("source", {
                    src: _ctx.url,
                    type: _ctx.mime
                  }, null, 8, _hoisted_8)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isPdf && _ctx.url)
            ? (_openBlock(), _createElementBlock("object", {
                key: 2,
                data: _ctx.url,
                type: "application/pdf",
                frameborder: "0",
                width: "100%",
                height: "100%"
              }, [
                _createElementVNode("embed", {
                  src: _ctx.url,
                  width: "100%",
                  height: "100%"
                }, null, 8, _hoisted_10)
              ], 8, _hoisted_9))
            : _createCommentVNode("", true),
          (_ctx.isImage && _ctx.url)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "image",
                style: _normalizeStyle(_ctx.imgStyle)
              }, null, 4))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}