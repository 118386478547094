import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, {
    modelValue: _ctx.showContextMenu,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showContextMenu) = $event)),
    position: "absolute",
    "offset-y": "",
    "z-index": "999",
    light: "",
    style: _normalizeStyle({ left: `${_ctx.contextMenuX}px`, top: `${_ctx.contextMenuY}px` })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list, {
        density: "compact",
        class: "py-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, { onClick: _ctx.contextMenuCopyText }, {
            prepend: _withCtx(() => [
              _createVNode(_component_v_icon, {
                size: "x-small",
                class: "mr-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-content-copy")
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Copy")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["modelValue", "style"]))
}