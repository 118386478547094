<template>
  <div>
    <side-sheet
      v-if="value"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow :show-arrows="false" color="primary" slider-color="primary">
          <v-tab value="key">Api key</v-tab>
          <v-tab value="stats">Stats</v-tab>
        </v-tabs>
      </template>

      <v-window v-model="tab" :touch="false" class="full-height-tabs-wrap tabs-w-100">
        <!-- API KEY -->
        <v-tabs-window-item :reverse-transition="false" value="key" transition="none">
          <v-form ref="apiKeyForm" v-model="valid" lazy-validation>
            <PropEditor v-if="value.apiKeyId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">Api key ID:</span> {{ value.apiKeyId }}</div>
                <div v-if="value.createdAt">
                  <span class="info-label">Created:</span> {{ moment(value.createdAt).format("lll") }}
                </div>
                <div v-if="value.createdBy"><span class="info-label">Created by:</span> {{ value.createdBy }}</div>
                <div v-if="value.lastModifiedAt">
                  <span class="info-label">Updated:</span> {{ moment(value.lastModifiedAt).format("lll") }}
                </div>
                <div v-if="value.lastModifiedBy"><span class="info-label">Updated by:</span> {{ value.lastModifiedBy }}</div>
              </div>
            </PropEditor>

            <PropEditor name="Name" desc="Short description of the service provider or app that uses API key.">
              <v-text-field density="compact" variant="outlined" v-model="value.name" :rules="nameRules"></v-text-field>
            </PropEditor>
            <PropEditor
              name="Allowed IP addresses"
              desc="Allow-list of comma separated IP addresses or IP address ranges. Leave empty to allow any IP."
            >
              <v-text-field
                density="compact"
                variant="outlined"
                v-model="value.ipFilter"
                autocomplete="off"
                hint="Example: 192.168.0.1, 192.168.0.2-192.168.0.10, 192.168.1.0/24"
                persistent-hint
              ></v-text-field>
            </PropEditor>
            <PropEditor
              name="Key"
              desc="Generated unique key that should be included the X-API-KEY header during API requests."
              v-if="value.apiKeyId"
            >
              <!-- A hack (not sure it helps actually) to stop password managers to treat API key as password that needs to be stored. -->
              <input
                name="disable-pwd-mgr-1"
                type="password"
                id="disable-pwd-mgr-1"
                style="display: none"
                value="disable-pwd-mgr-1"
              />
              <input
                name="disable-pwd-mgr-2"
                type="password"
                id="disable-pwd-mgr-2"
                style="display: none"
                value="disable-pwd-mgr-2"
              />
              <input
                name="disable-pwd-mgr-3"
                type="password"
                id="disable-pwd-mgr-3"
                style="display: none"
                value="disable-pwd-mgr-3"
              />

              <div class="d-flex align-center">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  v-model="value.key"
                  @click:append="displayKey = !displayKey"
                  :append-icon="displayKey ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="displayKey ? 'text' : 'password'"
                  readonly
                  autocomplete="off"
                  data-lpignore="true"
                  accept="numbers"
                ></v-text-field>
                <v-btn
                  class="ml-2"
                  variant="text"
                  density="compact"
                  icon
                  size="small"
                  title="Copy API key to clipboard"
                  @click="copyAPIToClipboard"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                  size="small"
                  density="compact"
                  variant="text"
                  icon
                  class="ml-2 d-inline"
                  @click="confirmAPIKeyRegeneration"
                  :loading="regenerating"
                  title="Rotate key"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </div>
            </PropEditor>
            <div class="mt-10">Permissions</div>
            <v-divider class="mt-2 mb-4" />
            <PropEditor v-for="pg in permissions" :key="pg.name" :name="pg.name" :valign="'top'">
              <v-switch
                v-for="p in pg.permissions"
                :key="p.name"
                class="my-0"
                v-model="p.active"
                @click="setChangesStatus"
                :label="p.name"
              />
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <!-- STATS -->
        <v-tabs-window-item :reverse-transition="false" value="stats" transition="none">
          <ApiKeyStats v-if="tab === 'stats' && value" v-model="value" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="allowDelete && value.apiKeyId"
          color="secondary"
          @click="deleteApiKeyConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn color="primary" class="ml-4" @click="submit" :loading="loading" :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>

    <v-dialog scrim="rgba(33, 33, 33)" v-model="showNewApiKeyDialog" width="500">
      <v-card>
        <v-toolbar flat color="primary" :height="4"> </v-toolbar>
        <v-card-title> New API key created </v-card-title>

        <v-card-text>
          <p>You can also view and copy this key later.</p>
          <div class="d-flex align-center">
            <v-text-field hide-details density="compact" variant="outlined" v-model="newApiKey" readonly></v-text-field>

            <v-btn class="ml-2" icon size="small" title="Copy API key to clipboard" @click="copyNewAPIToClipboard">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="newApiKey = null"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, toNative, Model } from "vue-facing-decorator";
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import ApiKeyStats from "@/components/apiKeys/ApiKeyStats.vue";
import ApiKey from "@/types/ApiKey";
import { UserPermissionType } from "@/types/UserPermissionType";
import apiKeysResource from "@/resources/ApiKeysResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import ChangeManager from "@/services/ChangeManager";
import moment from "moment";
import { VForm } from "vuetify/components";

interface Permission {
  name: string;
  type: UserPermissionType;
  active: boolean;
}

interface PermissionsGroup {
  name: string;
  permissions: Permission[];
}

@Component({
  components: {
    SideSheet,
    PropEditor,
    ApiKeyStats,
  },
  emits: ["update:modelValue", "updated"],
})
export default class EditApiKey extends Vue {
  tab: string | null = null;
  @Watch("tab")
  onTabChange(val: string | null) {
    if (this.value?.apiKeyId) {
      this.$setComponentQuery("apiKeyTab", val);
    }
  }

  @Model({ default: null })
  readonly value!: ApiKey | null;

  @Prop()
  readonly initTab!: string | null;

  // begin change management
  @Watch("value")
  setChangeManager(val: ApiKey | null, oldValue: ApiKey | null) {
    this.changesControl = ChangeManager.modalController({
      controller: this.changesControl,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.apiKeyId !== val.apiKeyId,
      data: { apiKey: val },
      message: "You have unsaved API key changes.",
      target: `apiKey_${val?.apiKeyId}`,
      onLeave: () => (this.showDialog = false),
      onSave: this.submit,
    });
  }

  @Watch("value", { deep: true })
  checkChangesStatus() {
    this.setChangesStatus();
  }

  @Watch("permissions", { deep: true })
  setPermissionsChangeManager() {
    this.setChangesStatus();
  }

  setChangesStatus() {
    if (!this.value) {
      return;
    }

    const origApiKey = { ...this.changesControl?.data?.origData?.apiKey };

    const origPermissions = this.changesControl?.data?.origData?.permissions;
    if (!this.changesControl || !origApiKey) return;

    //ipFilter can be null or an empty string. Set the same type
    const ipFilter = this.value?.ipFilter;
    if (!origApiKey.ipFilter && !ipFilter && this.value?.hasOwnProperty("ipFilter")) origApiKey.ipFilter = ipFilter;

    if (!ChangeManager.isObjectEqual(origApiKey, this.value || {}, { isOrigPartial: true })) {
      this.changesControl?.activate();
      return;
    }

    const currentPermissions = JSON.parse(JSON.stringify(this.permissions)).map((item: PermissionsGroup) => {
      item.permissions = item.permissions.map((v) => {
        // @ts-ignore
        if (!v.active) delete v.active;
        return v;
      });
      return item;
    });

    if (!ChangeManager.isObjectEqual(origPermissions, currentPermissions, { isOrigPartial: true })) {
      this.changesControl?.activate();
      return;
    }

    this.changesControl?.deactivate();
  }
  // end change management

  get disabledSubmitBtn() {
    return !ChangeManager.state().isChanged;
  }

  @Watch("value")
  onValueChanged(newValue: ApiKey | null, oldValue: ApiKey | null) {
    if (newValue != null) {
      this.populatePermissionsList();
      this.tab = this.initTab || null;
    } else {
      this.tab = null;
      this.$setComponentQuery("apiKeyTab", null);
    }

    this.$setComponentQuery("apiKeyId", this.value?.apiKeyId ? this.value.apiKeyId : null);
  }

  get showDialog() {
    return this.value != null;
  }
  set showDialog(value: boolean) {
    this.displayKey = false;
    this.$emit("update:modelValue", null);
  }

  get dialogHeading() {
    let heading = "";
    if (this.value) {
      heading = this.value?.apiKeyId ? `${this.value.name} (ID: ${this.value.apiKeyId})` : "New API key";
    }
    return heading;
  }

  moment = moment;
  changesControl: ChangeManager | null = null;
  permissions: PermissionsGroup[] = [];
  allPermissions = [
    {
      name: "Customers",
      permissions: [
        { name: "View customers", type: UserPermissionType.ViewCustomers },
        { name: "Add customers", type: UserPermissionType.AddCustomers },
        { name: "Edit customers", type: UserPermissionType.EditCustomers },
        { name: "Delete customers", type: UserPermissionType.DeleteCustomers },
      ],
    },
    {
      name: "Devices",
      permissions: [
        { name: "View devices", type: UserPermissionType.ViewDevices },
        { name: "View firmware update URL", type: UserPermissionType.ViewFirmwareUpdateUrl },
        { name: "View device location", type: UserPermissionType.ViewDeviceLocation },
        { name: "View device logs", type: UserPermissionType.ViewDeviceLogs },
        { name: "Edit devices", type: UserPermissionType.EditDevices },
        { name: "Modify Allow Updates (requires Edit devices)", type: UserPermissionType.EditAllowUpdateProperty },
        { name: "Delete devices", type: UserPermissionType.DeleteDevices },
      ],
    },
    {
      name: "POI",
      permissions: [
        { name: "View POI", type: UserPermissionType.ViewPoi },
        { name: "Add POI", type: UserPermissionType.AddPoi },
        { name: "Edit POI", type: UserPermissionType.EditPoi },
        { name: "Delete POI", type: UserPermissionType.DeletePoi },
      ],
    },
    {
      name: "Advertisement",
      permissions: [
        { name: "View adverts", type: UserPermissionType.ViewAds },
        { name: "Add adverts", type: UserPermissionType.AddAds },
        { name: "Edit adverts", type: UserPermissionType.EditAds },
        { name: "Delete adverts", type: UserPermissionType.DeleteAds },
        { name: "Manage advert settings", type: UserPermissionType.EditAdsSettings },
      ],
    },
    {
      name: "Issues",
      permissions: [
        { name: "View issues", type: UserPermissionType.ViewIssues },
        { name: "Manage issue devices", type: UserPermissionType.ManageIssueDevices },
        { name: "Add issues", type: UserPermissionType.AddIssues },
        { name: "Edit issues", type: UserPermissionType.EditIssues },
        { name: "Delete issues", type: UserPermissionType.DeleteIssues },
      ],
    },
  ] as PermissionsGroup[];

  valid = true;
  loading = false;
  regenerating: boolean = false;
  nameRules = [
    (v: any) => !!v || "Name is required",
    (v: any) => v.length > 2 || "Api key name must be at least 3 characters long",
    (v: any) => v.length < 250 || "Maximum API key character limit is 250",
  ];

  get allowDelete() {
    return true;
  }
  deleting = false;
  newApiKey: string | null = null;
  displayKey: boolean = false;

  get showNewApiKeyDialog() {
    return this.newApiKey != null;
  }
  set showNewApiKeyDialog(value: boolean) {
    this.newApiKey = null;
  }

  populatePermissionsList() {
    this.permissions = JSON.parse(JSON.stringify(this.allPermissions));
    if (this.value !== null) {
      for (var permission of this.value.apiKeyPermissions) {
        for (var pg of this.permissions) {
          for (var p of pg.permissions) {
            if (p.type === permission) {
              p.active = true;
            }
          }
        }
      }
    }
    this.changesControl?.addOrigData({ permissions: this.permissions });
  }

  applyPermissionsFromList() {
    if (this.value !== null) {
      var permToApply = [] as number[];
      for (var pg of this.permissions) {
        for (var p of pg.permissions) {
          if (p.active) {
            permToApply.push(p.type);
          }
        }
      }

      this.value.apiKeyPermissions = permToApply;
    }
  }

  async submit() {
    if (this.value === null) {
      return;
    }

    // Validate form
    const { valid } = await (this.$refs.apiKeyForm as InstanceType<typeof VForm>).validate();

    if (valid) {
      this.applyPermissionsFromList();

      if (this.value.apiKeyId) {
        // Update API key
        apiKeysResource
          .updateApiKey(this.value)
          .then(() => {
            this.showDialog = false;
            this.$emit("updated");
          })
          .catch(apiKeysResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      } else {
        // New API key
        apiKeysResource
          .addApiKey(this.value)
          .then((resp) => {
            infoMessageService.show(InfoMessageType.Success, "New API key created");
            this.showDialog = false;
            this.newApiKey = resp.data;
            this.$emit("updated");
          })
          .catch(apiKeysResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }

  deleteApiKeyConfirm() {
    if (!this.allowDelete || this.value == null) {
      return;
    }

    this.$confirm.show(`Delete API key '${this.value.name}'?`).then((confirmed) => {
      if (confirmed) {
        this.deleteApiKey();
      }
    });
  }

  deleteApiKey() {
    if (!this.allowDelete || this.value == null) {
      return;
    }

    this.deleting = true;
    apiKeysResource
      .deleteApiKey(this.value.apiKeyId)
      .then(() => {
        this.showDialog = false;
        this.$emit("updated");
      })
      .catch(apiKeysResource.defaultErrorHandler)
      .finally(() => {
        this.deleting = false;
      });
  }

  close(value: boolean) {
    if (!value && ChangeManager.state().isChanged) {
      ChangeManager.show();
      return;
    }

    this.showDialog = value;
  }

  copyNewAPIToClipboard() {
    if (this.newApiKey) {
      navigator.clipboard
        .writeText(this.newApiKey)
        .then(() => infoMessageService.show(InfoMessageType.Success, "Copied to clipboard"))
        .catch(() => infoMessageService.show(InfoMessageType.Error, "Failed to copy content"));
    }
  }

  copyAPIToClipboard() {
    if (this.value) {
      navigator.clipboard
        .writeText(this.value.key)
        .then(() => infoMessageService.show(InfoMessageType.Success, "Copied to clipboard"))
        .catch(() => infoMessageService.show(InfoMessageType.Error, "Failed to copy content"));
    }
  }

  confirmAPIKeyRegeneration() {
    if (!this.allowDelete || this.value == null) {
      return;
    }

    this.$confirm
      .show(
        `
    <div>
      <h3 class='mb-3 mt-3'>WARNING</h3>
      <p>
        Rotating the API key will invalidate the previous key. 
        You can rotate your API key if you believe that the current key is no longer safe to use.
      </p>
    </div>
    `,
        {
          width: 600,
          color: "error",
        }
      )
      .then((confirmed) => {
        if (confirmed) {
          this.regenerateKey();
        }
      });
  }

  regenerateKey() {
    if (this.value == null) {
      return;
    }

    this.regenerating = true;
    apiKeysResource
      .regenerateApiKey(this.value.apiKeyId)
      .then((resp) => {
        if (this.value) {
          this.value.key = resp.data;
        }
        this.changesControl?.addOrigData({ apiKey: { ...this.changesControl?.data?.origData?.apiKey, key: resp.data } });
        this.displayKey = true;
        this.$emit("updated");
      })
      .catch(apiKeysResource.defaultErrorHandler)
      .finally(() => {
        this.regenerating = false;
      });
  }
}
</script>
