<template>
  <div>
    <div v-if="canAddIssue || canManageIssueDevices">
      <AddDeviceIssue :deviceId="deviceId" @update="onAddIssue" />
    </div>
    <div class="text-subtitle-1">Related issues</div>
    <div class="d-block d-sm-flex mb-2">
      <div class="d-flex flex-grow-1">
        <v-text-field
          v-model="searchTerm"
          append-inner-icon="mdi-magnify"
          color="primary"
          variant="underlined"
          label="Search"
          hide-details
          clearable
          @click:clear="search()"
          v-on:input="search()"
          v-on:keypress.enter="search(true)"
        >
        </v-text-field>
        <v-btn density="compact" icon class="align-self-end ml-3" @click="reload" :disabled="loading" title="Refresh">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </div>

    <v-data-table-server
      class="issue-table"
      style="cursor: pointer"
      density="compact"
      :headers="headers"
      :items="items"
      :items-length="total"
      v-model:sort-by="sortBy"
      :must-sort="true"
      hover
      :mobile="false"
      :loading="loading"
      :mobile-breakpoint="0"
      :row-props="rowClass"
      @click:row="(event, { item }) => rowClick(item)"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span :class="IssueHelper.getIssueStatusColor(item.status, true)">{{ getIssueStatusName(item.status) }}</span>
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        <v-icon
          :color="IssueHelper.getIssuePriorityColor(item.priority)"
          :title="IssueHelper.getIssuePriorityName(item.priority)"
        >
          {{ IssueHelper.getIssuePriorityIcon(item.priority) }}
        </v-icon>
      </template>

      <template v-slot:[`item.tags`]="{ item }">
        <v-chip size="small" class="ma-1px" v-for="tag in item.tags" v-bind:key="tag.tagId">
          {{ tag.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.deviceIssueStatus`]="{ item }">
        <div @click="$event.stopPropagation()">
          <v-select
            :loading="deviceStatusLoading.includes(item.issueId)"
            v-model="item.deviceIssueStatus"
            :items="issueDeviceStatuses"
            density="compact"
            flat
            background-color="transparent"
            hide-details
            class="status-select"
            :readonly="!item.isArchived && !canManageIssueDevices"
            :disabled="!item.isArchived && !canManageIssueDevices"
            @update:modelValue="(newStatus) => setDeviceIssueStatus(newStatus, item)"
            variant="solo"
          >
            <template v-slot:selection="{ item }">
              <span class="text-body-2" :class="item.raw.textColor">{{ item.title }}</span>
            </template>
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" class="text-body-2" :class="item.raw.textColor"> </v-list-item>
            </template>
          </v-select>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <v-btn
            link
            :href="`/support/issues/${item.issueId}`"
            @click="$event.stopPropagation()"
            target="_blank"
            icon
            title="Open in new tab"
            density="compact"
            size="small"
            variant="text"
          >
            <v-icon size="small">mdi-open-in-new</v-icon>
          </v-btn>

          <v-btn
            v-if="item && !item.isArchived && canManageIssueDevices"
            @click.stop="() => deleteAssociatedIssueConfirm(item)"
            :loading="deletingIssueId.includes(item.issueId)"
            :disabled="deletingIssueId.includes(item.issueId)"
            icon
            density="compact"
            size="small"
            variant="text"
            class="ml-2"
          >
            <v-icon size="small">mdi-close-thick</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:bottom>
        <DataTableFooter
          v-model:page="page"
          :items="items"
          :itemsLength="total"
          v-model:itemsPerPage="itemsPerPage"
          :itemsPerPageOptions="[15, 25, 50]"
        />
      </template>
    </v-data-table-server>

    <v-overlay  :scrim="false"  contained :model-value="loading"  persistent/>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, toNative, Setup } from "vue-facing-decorator";
import Issue from "@/types/Issue";
import { IssueStatus } from "@/types/IssueStatus";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import AddDeviceIssue from "./AddDeviceIssue.vue";
import deviceResource from "@/resources/DeviceResource";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import { useRoute } from "vue-router";
//@ts-ignore
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  components: { AddDeviceIssue, DataTableFooter },
  emits: ["countUpdate"],
})
class AssociatedIssues extends Vue {
  @Prop()
  readonly deviceId!: number | null;

  IssueHelper = IssueHelper;
  total = 0;
  items: (Issue & { deviceIssueStatus?: IssueDeviceStatus })[] = [];
  loading = false;
  deletingIssueId: number[] = [];
  deviceStatusLoading: number[] = [];

  itemsPerPage = 15;
  page = 1;
  sortBy: { key: string; order: boolean | "asc" | "desc" }[] = [{ key: "issueId", order: true }];

  searchTerm = "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  deviceIssueStatusToken: CancelTokenSource | undefined = undefined;
  cancelDeviceStatusToken: CancelTokenSource | undefined = undefined;

  @Watch("itemsPerPage")
  @Watch("sortBy")
  @Watch("page")
  onPropertyChanged() {
    this.getData();
  }

  @Watch("deviceId")
  onDeviceChanged() {
    this.getData();
  }

  @Setup(() => useRoute())
  route = useRoute();

  mounted() {
    this.getData();
  }

  headers = [
    { title: "ID", align: "start", key: "issueId" },
    { title: "Status", key: "status" },
    { title: "Priority", key: "priority" },
    { title: "Title", key: "name" },
    { title: "Tags", key: "tags" },
    { title: "Device status", key: "deviceIssueStatus", sortable: false },
    { title: "", key: "actions", sortable: false },
  ];
  get canViewIssue() {
    return userProfileService.hasPermission(UserPermissionType.ViewIssues);
  }
  get canAddIssue() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }

  get canManageIssueDevices() {
    return (
      userProfileService.hasPermission(UserPermissionType.EditIssues) ||
      userProfileService.hasPermission(UserPermissionType.ManageIssueDevices)
    );
  }

  get canDeleteIssue() {
    return userProfileService.hasPermission(UserPermissionType.DeleteIssues);
  }

  issueDeviceStatuses = [
    {
      title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.New),
      value: IssueDeviceStatus.New,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.New),
    },
    {
      title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Active),
      value: IssueDeviceStatus.Active,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Active),
    },
    {
      title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Resolved),
      value: IssueDeviceStatus.Resolved,
      textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Resolved),
    },
  ];

  getIssueStatusName(status: number) {
    return IssueHelper.getIssueStatusDisplayName(status);
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    if (this.deviceIssueStatusToken) {
      this.deviceIssueStatusToken.cancel();
    }

    if (!this.deviceId || !this.canViewIssue) return;
    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();
      this.deviceIssueStatusToken = axios.CancelToken.source();

      if (!this.deviceId) return;

      const orderDesc =
        typeof this.sortBy[0].order === "boolean" ? this.sortBy[0].order : this.sortBy[0].order.toString() === "desc";
      const promise = Promise.all([
        issueResource.getIssuesPaged(
          this.itemsPerPage,
          this.page,
          this.searchTerm,
          this.sortBy[0].key,
          orderDesc,
          undefined,
          undefined,
          undefined,
          undefined,
          this.deviceId,
          this.cancelToken
        ),
        deviceResource.getIssueStatusesByDeviceId(this.deviceId, this.deviceIssueStatusToken),
      ]);
      promise
        .then((resp) => {
          if (resp.some((v) => axios.isCancel(v))) return;
          const allDeviceIssueStatuses = resp[1].data;
          this.items = resp[0].data.items.map((v) => {
            const deviceIssueStatus = allDeviceIssueStatuses.find(({ issueId }) => issueId === v.issueId)?.status;
            return { ...v, deviceIssueStatus };
          });
          this.total = resp[0].data.totalItems;
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData();
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData();
      }, 1000);
    }
  }

  setDeviceIssueStatus(status: IssueDeviceStatus, issueItem: Issue & { deviceIssueStatus: IssueDeviceStatus }) {
    const previousStatus = issueItem.deviceIssueStatus;
    // Cancel existing request
    if (this.cancelDeviceStatusToken) {
      this.cancelDeviceStatusToken.cancel();
    }

    if (!this.canManageIssueDevices || !issueItem) {
      return;
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began

      if (!this.deviceId) return;
      this.cancelDeviceStatusToken = axios.CancelToken.source();
      this.deviceStatusLoading.push(issueItem.issueId);
      issueResource
        .updateIssueDeviceStatus(issueItem.issueId, this.deviceId, status, this.cancelDeviceStatusToken)
        .then(() => {
          this.$emit("countUpdate");
        })
        .catch((e) => {
          issueResource.defaultErrorHandler(e);
          this.items = this.items.map((item) => {
            if (item.issueId === issueItem.issueId) {
              return { ...item, deviceIssueStatus: previousStatus };
            }
            return item;
          });
        })
        .finally(() => {
          this.cancelDeviceStatusToken = undefined;
          this.deviceStatusLoading = this.deviceStatusLoading.filter((v) => v !== issueItem.issueId);
        });
    }, 10);
  }

  reload() {
    this.getData();
  }

  rowClick(item: Issue) {
    if (this.route?.name === "Issue") {
      let route = this.$router.resolve({ path: `/support/issues/${item.issueId}` });
      window.open(route.href, "_blank");
      return;
    }
    this.$router.push(`/support/issues/${item.issueId}`).catch((e) => {
      if (e.message.includes("Navigation aborted")) return;
      if (e.message.includes("Avoided redundant navigation to current location")) {
        let route = this.$router.resolve({ path: `/support/issues/${item.issueId}` });
        window.open(route.href, "_blank");
        return;
      }
      console.error(e);
    });
  }

  rowClass({ item }: { item: Issue }) {
    return { class: { "text-red": item.status === IssueStatus.Closed, "cursor-pointer": true } };
  }

  onAddIssue() {
    this.itemsPerPage = 15;
    this.page = 1;
    this.sortBy = [{ key: "issueId", order: true }];
  }

  deleteAssociatedIssueConfirm(issue: Issue) {
    if (!this.canManageIssueDevices || !this.deviceId) {
      return;
    }

    this.$confirm
      .show(`Remove issue ID ${issue.issueId} from related issue list?`, {
        width: 360,
      })
      .then((confirmed) => {
        if (confirmed) {
          this.removeAssociatedIssue(issue);
        }
      });
  }

  removeAssociatedIssue(issue: Issue) {
    if (!this.canManageIssueDevices || !this.deviceId) {
      return;
    }

    this.deletingIssueId.push(issue.issueId);
    issueResource
      .removeDevice(issue.issueId, this.deviceId)
      .then(() => this.reload())
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.deletingIssueId = this.deletingIssueId.filter((v) => v !== issue.issueId);
      });
  }
}

export default toNative(AssociatedIssues);
</script>

<style scoped>
.issue-table :deep(td),
.issue-table :deep(th) {
  padding-right: 14px !important;
  padding-left: 14px !important;
}

.status-select {
  width: 90px;
  padding: 0;
  margin: 0;
}

.status-select :deep(.v-input__control) > .v-field--active {
  padding: 0 !important;
  padding-inline-end: 0;
}

.status-select :deep(.v-input__control) > .v-field--active .v-field__input {
  padding: 0 !important;
  padding-inline-end: 0;
}
.issue-table :deep(.v-data-table-header th) {
  white-space: nowrap;
}
</style>
