<template>
  <div
    v-bind:name="name"
    v-bind:desc="desc"
    v-bind:valign="valign"
    class="d-block d-sm-flex property-editor"
    :class="[valignClass, titleSizeClass]"
  >
    <div class="property-editor-header">
      <div class="text-subtitle-1">{{ name }}</div>
      <div v-if="desc" class="desc text-caption text-medium-emphasis text--secondary font-weight-light">
        {{ desc }}
      </div>
    </div>
    <div class="property-editor-content flex-grow-1">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative } from "vue-facing-decorator";

@Component({})
class PropEditor extends Vue {
  @Prop({ default: "" })
  readonly name!: string;

  @Prop({ default: "" })
  readonly desc!: string;

  @Prop({ default: "middle" })
  readonly valign!: string;

  @Prop({ default: "default" })
  readonly titleSize!: "default" | "large";

  get valignClass() {
    if (this.valign === "top") {
      return "align-top";
    } else if (this.valign === "middle") {
      return "align-center";
    } else if (this.valign === "bottom") {
      return "align-bottom";
    }
  }

  get titleSizeClass() {
    if (this.titleSize === "large") {
      return "large-title";
    }
    return null;
  }
}

export default toNative(PropEditor);
</script>
