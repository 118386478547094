import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8201018e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "map-wrap"
}
const _hoisted_2 = { class: "poi-tooltip" }
const _hoisted_3 = { class: "mt-1" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_l_tile_layer = _resolveComponent("l-tile-layer")!
  const _component_l_control_scale = _resolveComponent("l-control-scale")!
  const _component_l_icon = _resolveComponent("l-icon")!
  const _component_l_tooltip = _resolveComponent("l-tooltip")!
  const _component_l_marker_rotate = _resolveComponent("l-marker-rotate")!
  const _component_l_map = _resolveComponent("l-map")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showMap && _ctx.deviceLocation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_l_map, {
            class: "map-container",
            zoom: _ctx.mapZoom,
            center: _ctx.mapCenter,
            onContextmenu: () => false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_l_tile_layer, {
                url: _ctx.leaflet.url,
                attribution: _ctx.leaflet.attribution
              }, null, 8, ["url", "attribution"]),
              _createVNode(_component_l_control_scale, {
                position: "bottomleft",
                imperial: false,
                metric: true
              }),
              _createVNode(_component_l_marker_rotate, {
                "lat-lng": [_ctx.deviceLocation.latitude, _ctx.deviceLocation.longitude],
                rotationAngle: _ctx.mapIcon.roatationOrigin ? _ctx.deviceLocation.heading : 0,
                rotationOrigin: _ctx.mapIcon.roatationOrigin ? _ctx.mapIcon.roatationOrigin : null
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_l_icon, {
                    "icon-url": _ctx.mapIcon.iconUrl,
                    "icon-size": _ctx.mapIcon.iconSize,
                    "icon-anchor": _ctx.mapIcon.iconAnchor,
                    tooltipAnchor: [25, 0]
                  }, null, 8, ["icon-url", "icon-size", "icon-anchor"]),
                  _createVNode(_component_l_tooltip, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", null, "Date: " + _toDisplayString(_ctx.moment(_ctx.deviceLocation.timestamp).format("lll")), 1),
                        _createElementVNode("div", _hoisted_3, "Speed: " + _toDisplayString(_ctx.deviceLocation.speed) + " km/h", 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["lat-lng", "rotationAngle", "rotationOrigin"])
            ]),
            _: 1
          }, 8, ["zoom", "center"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.deviceLocation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMap = true)),
            style: _normalizeStyle({ cursor: _ctx.showMap ? 'default' : 'pointer' })
          }, _toDisplayString(_ctx.deviceLocation.latitude) + ", " + _toDisplayString(_ctx.deviceLocation.longitude), 5),
          _createVNode(_component_v_btn, {
            class: "ml-2",
            icon: "",
            "x-small": "",
            variant: "plain",
            title: "Copy coordinates to clipboard",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(`${_ctx.deviceLocation?.latitude},${_ctx.deviceLocation?.longitude }`)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-content-copy")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.noData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, "No data available"))
      : _createCommentVNode("", true)
  ]))
}