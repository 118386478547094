import ResourceBase from "@/resources/ResourceBase";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import AttachedFile from "@/types/AttachedFile";
import GenericResponse from "@/types/GenericResponse";

class FileResource extends ResourceBase {
    getFile(url: string): Promise<AxiosResponse<File>> {
        const config = {
            responseType: "arraybuffer",
        } as AxiosRequestConfig;

        return this.apiClient.get(url, config);
    }
    attachFile(
        targetType: AttachmentTargetType,
        targetId: number,
        files: File[],
        onUploadProgress?: ({ loaded, total }: { loaded: number; total: number }) => void,
        cancelToken?: any
    ): Promise<AxiosResponse<{ uploaded: AttachedFile[]; errors: string[] }>> {
        let formData = new FormData();

        files.forEach((f) => formData.append("files", f, f.name));

        const config = {
            onUploadProgress,
            params: { targetType, targetId },
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.post<{ uploaded: AttachedFile[]; errors: string[] }>("/attachments", formData, config);
    }

    deleteFile(attachmentId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/attachments/${attachmentId}`, config);
    }
}

const fileResource = new FileResource();
export default fileResource;
