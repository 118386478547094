class UserStorageService {
    set(key: string, value: any) {
        if (value) {
            window.localStorage[key] = JSON.stringify(value);
        } else {
            window.localStorage[key] = value;
        }
    }

    get(key: string): any {
        if (window.localStorage[key]) {
            try {
                return JSON.parse(window.localStorage[key]);
            } catch {}
        }
        return window.localStorage[key];
    }

    remove(key: string) {
        window.localStorage.removeItem(key);
    }
}

const userStorage = new UserStorageService();
export default userStorage;