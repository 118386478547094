<template>
  <div>
    <side-sheet
      v-if="value"
      :heading="dialogHeading"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow color="primary" slider-color="primary">
          <v-tab value="advert">Advert</v-tab>
          <v-tab value="stats">Stats</v-tab>
        </v-tabs>
      </template>

      <v-window class="full-height-tabs-wrap tabs-w-100" v-model="tab" :touch="false">
        <!-- POI -->
        <v-tabs-window-item :reverse-transition="false" value="advert" transition="none">
          <v-form ref="advertForm" v-model="valid" lazy-validation>
            <PropEditor v-if="value.advertId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">Advert ID:</span> {{ value.advertId }}</div>
                <div v-if="value.createdDate">
                  <span class="info-label">Created:</span> {{ moment(value.createdDate).format("lll") }}
                </div>
              </div>
            </PropEditor>
            <PropEditor name="Image">
              <img :src="imagePreview" alt="" class="ad-image" />
              <div class="img-field" v-if="canEditAds">
                <v-file-input
                  accept="image/png, image/bmp"
                  :rules="[fileInputValidation]"
                  prepend-icon="mdi-camera"
                  label="Upload new image"
                  :loading="imageLoading"
                  @update:modelValue="newImageSelected"
                ></v-file-input>
              </div>
            </PropEditor>
            <PropEditor name="Active" desc="">
              <v-switch v-model="value.isActive" density="compact" :readonly="!canEditAds" class="my-0" />
            </PropEditor>
            <PropEditor name="Organisation ID" desc="">
              <v-text-field
                v-model="value.organisationId"
                density="compact"
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Campaign ID" desc="">
              <v-text-field
                v-model="value.campaignId"
                density="compact"
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Advert type">
              <v-select
                v-model="value.advertType"
                :items="advertTypes"
                density="compact"
                single-line
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
                attach
              ></v-select>
            </PropEditor>
            <PropEditor v-if="value.advertType == 1" name="Area">
              <v-select
                v-model="value.advertLocationId"
                :items="areas"
                density="compact"
                single-line
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
                attach
              ></v-select>
            </PropEditor>
            <PropEditor v-else-if="value.advertType == 2" name="Location" valign="top">
              <v-autocomplete
                v-model="geocodeSelectedItem"
                :items="geocodeItems"
                :loading="geocodeLoading"
                v-model:search="geocodeSearchTerm"
                density="compact"
                variant="outlined"
                clearable
                hide-no-data
                no-filter
                label="Address search"
                item-title="formatted_address"
                class="mb-2 map-search-field"
                :loader-height="4"
                return-object
              ></v-autocomplete>
              <div class="map-wrap">
                <l-map
                  v-if="showMap && value"
                  class="map-container"
                  :zoom.sync="mapZoom"
                  :center="mapCenter"
                  @click="setMarker"
                  @contextmenu="() => false"
                >
                  <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
                  <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                  <!-- ACTIVE MARKER -->
                  <l-marker-rotate
                    v-if="mapIcon"
                    :lat-lng="[value.advertLocation.latitude, value.advertLocation.longitude]"
                    :draggable="canEditAds"
                    :rotationAngle="mapIcon.roatationOrigin ? value.direction : 0"
                    :rotationOrigin="mapIcon.roatationOrigin ? mapIcon.roatationOrigin : null"
                    @dragend="markerPositionUpdated"
                    @click="
                      (e) => {
                        return false;
                      }
                    "
                  >
                    <l-icon
                      :icon-url="mapIcon.iconUrl"
                      :icon-size="mapIcon.iconSize"
                      :icon-anchor="mapIcon.iconAnchor"
                    ></l-icon>
                  </l-marker-rotate>
                </l-map>
              </div>
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    v-model="latLng"
                    label="Advert coordinates (latitude, longitude)"
                    density="compact"
                    variant="outlined"
                    :flat="!canEditAds"
                    :readonly="!canEditAds"
                    clearable
                    @update:model-value="applyLatLngToAdvert"
                    :rules="latLngRule"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Promo text" desc="">
              <v-text-field
                v-model="value.promoText"
                density="compact"
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
              ></v-text-field>
            </PropEditor>
            <PropEditor v-if="showDirection" name="Radius">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    max="2000"
                    v-model="value.activeRadius"
                    step="50"
                    hide-details
                    :readonly="!canEditAds"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="value.activeRadius"
                    step="50"
                    type="number"
                    variant="outlined"
                    density="compact"
                    :readonly="!canEditAds"
                    style="width: 80px"
                    label="Meters"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor v-if="showDirection" name="Direction">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="-1"
                    max="359"
                    v-model="value.direction"
                    step="1"
                    hide-details
                    :readonly="!canEditAds"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="value.direction"
                    type="number"
                    variant="outlined"
                    density="compact"
                    :readonly="!canEditAds"
                    style="width: 80px"
                    label="Degrees"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Maximum shows">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    max="100"
                    v-model="value.maximumShows"
                    step="1"
                    hide-details
                    :readonly="!canEditAds"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="value.maximumShows"
                    type="number"
                    variant="outlined"
                    density="compact"
                    step="1"
                    min="0"
                    max="100"
                    :readonly="!canEditAds"
                    style="width: 80px"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Screen time">
              <div class="d-flex mb-2">
                <v-text-field
                  v-model="screenTime[0]"
                  class="flex-grow-0"
                  style="width: 140px"
                  type="number"
                  step="1"
                  label="Min screen time, sec"
                  variant="outlined"
                  density="compact"
                  :readonly="!canEditAds"
                  @change="$set(screenTime, 0, $event)"
                ></v-text-field>
                <v-spacer />
                <v-text-field
                  v-model="screenTime[1]"
                  class="flex-grow-0"
                  style="width: 140px"
                  type="number"
                  step="1"
                  label="Max screen time, sec"
                  variant="outlined"
                  density="compact"
                  :readonly="!canEditAds"
                  @change="$set(screenTime, 1, $event)"
                ></v-text-field>
              </div>
              <v-range-slider
                min="0"
                max="60"
                v-model="screenTime"
                :readonly="!canEditAds"
                step="1"
                hide-details
              ></v-range-slider>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <v-tabs-window-item :reverse-transition="false" value="stats" transition="none">
          <AdvertStatsView :advertId="statsAdvertId" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="canDeleteAds && value.advertId"
          color="secondary"
          @click="deleteAdvertConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          v-if="canEditAds"
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue, Model } from "vue-facing-decorator";
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { UserPermissionType } from "@/types/UserPermissionType";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControlScale, LIcon, LTooltip, LPopup, LControl } from "@vue-leaflet/vue-leaflet";
//@ts-ignore
import { LMarkerRotate } from "vue-leaflet-rotate-marker";
import MapHelper from "@/helpers/mapHelper";
import googleMapsResource from "@/resources/GoogleMapsResource";
import MapIcons from "@/types/MapIcons";
import AdvertHelper from "@/helpers/advertHelper";
import { AdvertType } from "@/types/AdvertType";
import advertResource from "@/resources/AdvertResource";
import Advert from "@/types/Advert";
import AdvertStatsView from "./AdvertStatsView.vue";
import ChangeManager from "@/services/ChangeManager";
import { VForm } from "vuetify/components";

@Component({
  components: {
    SideSheet,
    PropEditor,
    L,
    LMap,
    LTileLayer,
    LMarker,
    LMarkerRotate,
    LControlScale,
    LIcon,
    LTooltip,
    LPopup,
    LControl,
    AdvertStatsView,
  },
  emits: ["update:modelValue", "updated"],
})
export default class EditAdvert extends Vue {
  moment = moment;
  changesControl: ChangeManager | null = null;

  @Model({ default: null })
  readonly value!: Advert | null;

  @Prop()
  readonly advertInitTab!: string | null;
  // begin change management
  @Watch("value")
  setChangeManager(val: Advert | null, oldValue: Advert | null) {
    this.changesControl = ChangeManager.modalController({
      controller: this.changesControl,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.advertId !== val.advertId,
      data: { advert: val },
      message: "You have unsaved Advert changes.",
      target: `advert_${val?.advertId}`,
      onLeave: () => {
        this.showDialog = false;
      },
      onSave: this.submit,
    });
  }

  @Watch("value", { deep: true })
  checkChangesStatus() {
    this.setChangesStatus();
  }

  @Watch("screenTime", { deep: true })
  onChangeScreenTime() {
    this.setChangesStatus();
  }

  fileInputValidation([value]: [File]) {
    return !value || Boolean(["image/png", "image/bmp"].includes(value.type)) || "Only png and bmp files allowed";
  }

  setChangesStatus() {
    if (!this.value) {
      return;
    }

    const origAdvert = this.changesControl?.data?.origData?.advert;
    if (!this.changesControl || !origAdvert) return;

    if (
      !ChangeManager.isObjectEqual(origAdvert, this.value || {}, {
        isOrigPartial: true,
        exclude: ["direction", "imageDataBase64"],
      })
    ) {
      this.changesControl?.activate();
      return;
    }

    if ((origAdvert.direction || -1) !== this.value.direction) {
      this.changesControl?.activate();
      return;
    }

    if (!origAdvert.imageDataBase64 && Boolean(this.value.imageDataBase64)) {
      this.changesControl?.activate();
      return;
    }

    if (
      origAdvert.imageDataBase64 &&
      this.value.imageDataBase64 &&
      this.value.imageDataBase64 !== origAdvert.imageDataBase64
    ) {
      this.changesControl?.activate();
      return;
    }

    if (this.value.minTimeOnScreen !== this.screenTime[0] || this.value.maxTimeOnScreen !== this.screenTime[1]) {
      this.changesControl?.activate();
      return;
    }

    this.changesControl?.deactivate();
  }

  close(value: boolean) {
    if (!value && ChangeManager.state().isChanged) {
      ChangeManager.show();
      return;
    }

    this.showDialog = value;
  }
  // end change management

  get disabledSubmitBtn() {
    return !ChangeManager.state().isChanged;
  }

  @Watch("value")
  onAdvertChange() {
    if (this.value) {
      if (this.value.advertType == AdvertType.Spot) {
        this.mapCenter = [this.value.advertLocation.latitude, this.value.advertLocation.longitude];
        this.setLatLngFromAdvert();
      }
      this.mapZoom = 15;
      this.screenTime = [this.value.minTimeOnScreen, this.value.maxTimeOnScreen];
      this.imagePreview = this.value.imageDataBase64;
      this.value.imageDataBase64 = null; // Reset image data. When updating image, this field should contain new image data.

      // delay map init
      setTimeout(() => {
        this.showMap = true;
      }, 100);

      if (this.advertInitTab) {
        this.tab = this.advertInitTab || null;
      }
    } else {
      this.$setComponentQuery("advertTab", null);
      this.tab = null;
      this.showMap = false;
      this.mapCenter = [0, 0];
      this.geocodeSelectedItem = null;
      this.geocodeItems = [];
      this.statsAdvertId = null;
    }

    this.$setComponentQuery("advertId", this.value?.advertId ? this.value.advertId : null);
  }

  statsAdvertId: number | null = null;

  tab: string | null = null;
  @Watch("tab")
  onTabChange(val: string | null) {
    if (val === "stats") {
      this.statsAdvertId = this.value!.advertId;
    }
    if (this.value?.advertId) {
      this.$setComponentQuery("advertTab", val);
    }
  }

  get showDialog() {
    return this.value != null;
  }
  set showDialog(value: boolean) {
    this.$emit("update:modelValue", null);
  }

  get dialogHeading() {
    let heading = "";
    if (this.value) {
      heading = this.value?.advertId ? `Advert ID: ${this.value.advertId}` : "New Advert";
    }
    return heading;
  }

  valid = true;
  loading = false;

  imageLoading = false;
  imagePreview: string | null = null;

  advertTypes = [
    // { text: AdvertHelper.getAdvertTypeDisplayName(AdvertType.National), value: AdvertType.National },
    { title: AdvertHelper.getAdvertTypeDisplayName(AdvertType.Area), value: AdvertType.Area },
    { title: AdvertHelper.getAdvertTypeDisplayName(AdvertType.Spot), value: AdvertType.Spot },
  ];

  areas = [
    { title: "Namsos", value: 367 },
    { title: "Trondheim", value: 170 },
  ];

  screenTime = [0, 0];

  get showDirection() {
    return this.value && this.value.advertType === AdvertType.Spot;
  }

  showMap = false;
  mapCenter = [0, 0];
  mapZoom = 15;
  leaflet = {
    url: MapHelper.defaultMapTilesUrl,
    attribution: MapHelper.defaultMapAttr,
  };

  mapIcon = MapIcons.default;

  setMarker(e: any) {
    if (this.canEditAds && this.value) {
      this.latLng = `${e.latlng.lat.toFixed(6)}, ${e.latlng.lng.toFixed(6)}`;
      this.applyLatLngToAdvert(false);
    }
  }

  markerPositionUpdated(e: any) {
    if (this.canEditAds && this.value) {
      var latlng = e.target.getLatLng();
      this.latLng = `${latlng.lat.toFixed(6)}, ${latlng.lng.toFixed(6)}`;
      this.applyLatLngToAdvert(false);
    }
  }

  latLng: string | undefined = "";
  latLngRule = [
    (v: string) => !!v || "Field is required",
    (v: string) =>
      /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(v) ||
      "Correct format is 'lat, lng', ex. 63.123456, 10.123456",
  ];

  setLatLngFromAdvert() {
    this.latLng = `${this.value!.advertLocation.latitude.toFixed(6)}, ${this.value!.advertLocation.longitude.toFixed(6)}`;
  }

  async applyLatLngToAdvert(recenterMap: boolean = true) {
    const { valid } = await (this.$refs.advertForm as InstanceType<typeof VForm>).validate();

    if (!this.latLng || this.latLng === undefined || !valid) {
      return;
    }

    infoMessageService.clear();

    var latLngArray = this.latLng.split(",").map((elm) => parseFloat(elm.trim()));
    if (latLngArray.length === 2 && !latLngArray.some((elm) => isNaN(elm))) {
      this.value!.advertLocation.latitude = parseFloat(latLngArray[0].toFixed(6));
      this.value!.advertLocation.longitude = parseFloat(latLngArray[1].toFixed(6));

      if (recenterMap) {
        this.mapCenter = latLngArray;
      }
    } else {
      infoMessageService.show(InfoMessageType.Error, "Cannot parse latitude, longitude value.");
    }
  }

  get canAddAds() {
    return userProfileService.hasPermission(UserPermissionType.AddPoi);
  }
  get canEditAds() {
    return (
      userProfileService.hasPermission(UserPermissionType.EditAds) ||
      (this.value && this.value.advertId === 0 && this.canAddAds)
    );
  }
  get canDeleteAds() {
    return userProfileService.hasPermission(UserPermissionType.DeleteAds);
  }

  deleting = false;

  geocodeSearchTerm = "";
  geocodeLoading = false;
  geocodeSelectedItem: google.maps.GeocoderResult | null = null;
  geocodeItems: google.maps.GeocoderResult[] = [];
  geocodeTimeout = 0;

  @Watch("geocodeSearchTerm")
  onGeocodeSearch(term: string) {
    // Search threshold
    if (this.geocodeTimeout) {
      clearTimeout(this.geocodeTimeout);
      this.geocodeTimeout = 0;
    }

    if (!term) {
      this.geocodeSelectedItem = null;
      this.geocodeItems = [];
      this.geocodeLoading = false;
      return;
    }

    if (this.geocodeSelectedItem != null) return;

    this.geocodeItems = [];
    this.geocodeLoading = true;
    this.geocodeTimeout = setTimeout(() => {
      this.geocode();
    }, 2000);
  }

  @Watch("geocodeSelectedItem")
  onGeocodeItemSelected() {
    if (this.geocodeSelectedItem) {
      this.mapZoom = 15;
      this.mapCenter = [this.geocodeSelectedItem.geometry.location.lat(), this.geocodeSelectedItem.geometry.location.lng()];
    }
  }

  geocode() {
    this.geocodeLoading = true;
    googleMapsResource
      .geocodeAddress(this.geocodeSearchTerm)
      .then((resp) => {
        if (resp.results) {
          this.geocodeItems = resp.results;
        }
      })
      .catch(googleMapsResource.defaultErrorHandler)
      .finally(() => {
        this.geocodeLoading = false;
      });
  }

  async submit() {
    if (this.value === null) {
      return;
    }

    // Validate form
    const { valid } = await (this.$refs.advertForm as InstanceType<typeof VForm>).validate();
    if (valid) {
      // Get value
      this.value.minTimeOnScreen = this.screenTime[0];
      this.value.maxTimeOnScreen = this.screenTime[1];

      if (this.value.advertId) {
        // Update
        this.loading = true;
        advertResource
          .updateAdvert(this.value)
          .then((resp) => {
            this.showDialog = false;
            this.$emit("updated", this.value);
          })
          .catch(advertResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      } else {
        // New
        this.loading = true;
        advertResource
          .addAdvert(this.value)
          .then((resp) => {
            infoMessageService.show(InfoMessageType.Success, `New Advert with ID ${resp.data} created`);
            this.showDialog = false;
            this.$emit("updated", this.value);
          })
          .catch(advertResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }

  newImageSelected(file: any) {
    if (!file || typeof this.fileInputValidation([file]) === "string") {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      var imgData = reader.result!.toString();

      this.imageLoading = true;
      advertResource
        .generateImagePreview(imgData)
        .then((resp) => {
          this.value!.imageDataBase64 = imgData;
          this.imagePreview = resp.data;
        })
        .catch(advertResource.defaultErrorHandler)
        .finally(() => {
          this.imageLoading = false;
        });
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  deleteAdvertConfirm() {
    if (!this.canDeleteAds || this.value == null) {
      return;
    }

    this.$confirm.show(`Delete Advert ID '${this.value.advertId}'?`).then((confirmed) => {
      if (confirmed) {
        this.deleteAdvert();
      }
    });
  }

  deleteAdvert() {
    if (!this.canDeleteAds || this.value == null) {
      return;
    }

    this.deleting = true;
    advertResource
      .deleteAdvert(this.value.advertId)
      .then((resp) => {
        this.showDialog = false;
        this.$emit("updated");
      })
      .catch(advertResource.defaultErrorHandler)
      .finally(() => {
        this.deleting = false;
      });
  }
}
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 360px;
}
.ad-image {
  display: block;
  max-width: 320px;
  max-height: 180px;
  margin-bottom: 1rem;
}

.img-field {
  position: relative;
}
.img-field :deep(.v-input__details) {
  position: static;
  min-height: 0;
  margin-top: 5px;
}

.img-field :deep(.v-messages) {
  min-height: 0;
}
</style>
