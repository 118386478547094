<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            append-inner-icon="mdi-magnify"
            variant="underlined"
            color="primary"
            label="Search"
            hide-details
            clearable
          ></v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn size="small" color="primary" class="align-self-end" @click="newApiKey()" test-id="newApiKey">
            New API key
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        density="compact"
        :headers="headers"
        :items="items"
        :row-props="rowClass"
        :options.sync="options"
        :loading="loading"
        :search="searchTerm"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
        @click:row="(event, { item }) => rowClick(item)"
        test-id="api-keys-table"
        :must-sort="true"
        hover
        :mobile="false"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span v-if="item.createdAt">
            {{ `${moment(item.createdAt).format("lll")} (${item.createdBy})` }}
          </span>
        </template>

        <template v-slot:[`item.lastModifiedAt`]="{ item }">
          <span v-if="item.lastModifiedAt">
            {{ `${moment(item.lastModifiedAt).format("lll")} (${item.lastModifiedBy})` }}
          </span>
        </template>
        <template #bottom></template>
      </v-data-table>

      <v-overlay contained :model-value="loading" persistent opacity="0" style="z-index: 999 !important" />
    </v-card>

    <EditAPIKey v-model="apiKeyToEdit" v-on:updated="reload" :initTab="editInitTab" />
  </v-container>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop, toNative } from "vue-facing-decorator";
import apiKeysResource from "@/resources/ApiKeysResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import ApiKey from "@/types/ApiKey";
import EditAPIKey from "@/components/apiKeys/EditApiKey.vue";
import userStorage from "@/services/UserStorageService";

@Component({ components: { EditAPIKey } })
class ApiKeys extends Vue {
  @Prop({ default: null })
  initData!: { apiKeyId: number; apiKeyTab: string } | null;

  moment = moment;

  items: ApiKey[] = [];
  loading = false;
  cancelToken: CancelTokenSource | undefined = undefined;

  searchTerm: string = "";

  editInitTab: string | null = null;

  optionsStorageKey = "apiKeysTable";
  options = userStorage.get(this.optionsStorageKey) ?? {
    sortBy: [],
    sortDesc: [false],
  };

  @Watch("options", { deep: true })
  onPropertyChanged() {
    userStorage.set(this.optionsStorageKey, this.options);
  }

  headers = [
    { title: "ID", align: "start", key: "apiKeyId" },
    { title: "Name", key: "name" },
    { title: "Created", key: "createdAt", sortable: false },
    { title: "Updated", key: "lastModifiedAt", sortable: false },
  ];

  mounted() {
    if (this.initData?.apiKeyId) {
      this.getInitApiKeyById(this.initData?.apiKeyId);
    }
  }

  getInitApiKeyById(apiKeyId: number) {
    apiKeysResource
      .getApiKeyById(apiKeyId)
      .then((resp) => {
        this.rowClick(resp.data);
        this.editInitTab = this.initData?.apiKeyTab || null;
      })
      .catch(apiKeysResource.defaultErrorHandler);
  }

  apiKeyToEdit: ApiKey | null = null;

  @Watch("apiKeyToEdit")
  onChangeCustomerToEdit() {
    if (!this.apiKeyToEdit) {
      this.editInitTab = null;
    }
  }

  created() {
    this.getData();
  }

  newApiKey() {
    this.apiKeyToEdit = {
      apiKeyId: 0,
      name: "",
      apiKeyPermissions: [] as number[],
    } as ApiKey;
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      apiKeysResource
        .getApiKeys(this.cancelToken)
        .then((resp) => {
          this.items = resp.data;
        })
        .catch(apiKeysResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }

  reload() {
    this.getData();
  }

  rowClick(item: ApiKey) {
    this.apiKeyToEdit = Object.assign({}, item);
  }

  rowClass({ item }: { item: ApiKey }) {
    return { class: { "cursor-default": true } };
  }
}

export default toNative(ApiKeys);
</script>
