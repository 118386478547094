import MapIcon from "@/types/MapIcon";

export default class MapIcons {
    static readonly default = {
        iconUrl: "/assets/img/map-default.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        roatationOrigin: undefined,
    } as MapIcon;

    static readonly speedControl = {
        iconUrl: "/assets/img/map-speed-control.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        roatationOrigin: undefined,
    } as MapIcon;

    static readonly seatbeltControl = {
        iconUrl: "/assets/img/map-seatbelt-control.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        roatationOrigin: undefined,
    } as MapIcon;

    static readonly technicalControl = {
        iconUrl: "/assets/img/map-tech-control.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        roatationOrigin: undefined,
    } as MapIcon;

    static readonly customsControl = {
        iconUrl: "/assets/img/map-customs-control.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        roatationOrigin: undefined,
    } as MapIcon;

    static readonly roadCondition = {
        iconUrl: "/assets/img/map-road-condition.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        roatationOrigin: undefined,
    } as MapIcon;

    static readonly animal = {
        iconUrl: "/assets/img/map-animal.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        roatationOrigin: undefined,
    } as MapIcon;

    static readonly camera = {
        iconUrl: "/assets/img/map-camera.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 26],
        roatationOrigin: "20px 26px",
    } as MapIcon;

    static readonly cameraAvgStart = {
        iconUrl: "/assets/img/map-camera-avg-start.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 26],
        roatationOrigin: "20px 26px",
    } as MapIcon;

    static readonly cameraAvgEnd = {
        iconUrl: "/assets/img/map-camera-avg-end.svg?ver=2",
        iconSize: [40, 40],
        iconAnchor: [20, 26],
        roatationOrigin: "20px 26px",
    } as MapIcon;

    static readonly error = {
        iconUrl: "/assets/img/map-error.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        roatationOrigin: undefined,
    } as MapIcon;

    static readonly device = {
        iconUrl: "/assets/img/map-device.svg",
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        roatationOrigin: "20px 20px",
    } as MapIcon;

    static readonly smallArrow = {
        iconUrl: "/assets/img/map-arrow.svg",
        iconSize: [26, 26],
        iconAnchor: [13, 13],
        roatationOrigin: "13px 13px",
    } as MapIcon;
}