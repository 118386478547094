<template>
  <div>
    <side-sheet
      v-if="value"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:heading-menu>
        <DeviceMenu v-model="value.deviceId" :dark="true" size="small" />
      </template>
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow :show-arrows="false" color="primary" slider-color="primary">
          <v-tab value="device">Device</v-tab>
          <v-tab value="comments">
            <span>
              Comments
              <v-badge
                density="compact"
                :color="tab === 'comments' ? 'primary' : 'badge'"
                :content="commentCount"
                inline
                class="ml-1"
                v-if="commentCount"
              />
            </span>
          </v-tab>
          <v-tab v-if="canViewIssues" value="issues">
            Issues
            <v-badge
              :color="tab === 'issues' ? 'primary' : 'badge'"
              :content="issueCount"
              inline
              class="ml-1"
              v-if="issueCount"
            />
          </v-tab>
          <v-tab value="service" v-if="canViewRepairs || canAddRepair || canViewManufacturerWarranty">Service</v-tab>
          
          <v-tab v-if="isProDevice" value="mobileNetProvider">Network Provider</v-tab>
          <v-tab value="history">History</v-tab>
          <v-tab v-if="canViewLogs" value="logs">Logs</v-tab>
        </v-tabs>
      </template>

      <v-window
        :transition="false"
        v-model="tab"
        :touch="false"
        :hide-slider="false"
        class="full-height-tabs-wrap tabs-w-100"
      >
        <!-- DEVICE -->
        <v-tabs-window-item :reverse-transition="false" value="device" :transition="false">
          <v-form ref="deviceForm" v-model="valid" lazy-validation>
            <PropEditor v-if="value.deviceId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">Device ID:</span> {{ value.deviceId }}</div>
                <div><span class="info-label">Device type:</span> {{ getDeviceTypeName() }}</div>
                <div v-if="isProDevice">
                  <div><span class="info-label">Firmware:</span> {{ firmwareVer }}</div>
                  <div><span class="info-label">Bootloader:</span> {{ bootloaderVer }}</div>
                </div>
                <div v-if="value.createdDate">
                  <span class="info-label">Created:</span>
                  {{ moment(value.createdDate).format("lll") }}
                </div>
                <div v-if="value.lastSeenDate">
                  <span class="info-label">Last seen:</span>
                  {{ moment(value.lastSeenDate).format("lll") }}
                </div>
                <div v-if="isProDevice">
                  <span
                    v-if="!deviceIdentity"
                    class="text-decoration-underline cursor-pointer"
                    @click="fetchDeviceIdentity()"
                    >Show details</span
                  >
                  <div v-if="deviceIdentity">
                    <v-divider class="my-3" />
                    <div>
                      <span class="info-label">Firmware:</span>
                      {{ deviceIdentity.firmwareVersion }}
                    </div>
                    <div>
                      <span class="info-label">Bootloader:</span>
                      {{ deviceIdentity.bootloaderVersion }}
                    </div>
                    <div>
                      <span class="info-label">Boot address:</span>
                      {{ deviceIdentity.bootAddress }}
                    </div>
                    <div>
                      <span class="info-label">MCU - Family:</span>
                      {{ deviceIdentity.mcuId.family }}
                    </div>
                    <div>
                      <span class="info-label">MCU - Core:</span>
                      {{ deviceIdentity.mcuId.core }}
                    </div>
                    <div>
                      <span class="info-label">MCU - Revision:</span>
                      {{ deviceIdentity.mcuId.revision }}
                    </div>
                    <div>
                      <span class="info-label">MCU - Package pin count:</span>
                      {{ deviceIdentity.mcuId.packagePinCount }}
                    </div>
                    <div>
                      <span class="info-label">MCU - RAM size:</span>
                      {{ deviceIdentity.mcuId.ramSize }} bytes
                    </div>
                    <div>
                      <span class="info-label">MCU - Flash size:</span>
                      {{ deviceIdentity.mcuId.flashSize }} bytes
                    </div>
                    <div>
                      <span class="info-label">Modem firmware revision:</span>
                      {{ deviceIdentity.modemFirmwareRevision }}
                    </div>
                    <div>
                      <span class="info-label">Modem IMEI:</span>
                      {{ deviceIdentity.modemImei }}
                    </div>
                    <div>
                      <span class="info-label">SIM ICC:</span>
                      {{ deviceIdentity.simIcc }}
                    </div>
                    <div>
                      <span class="info-label">Has bluetooth:</span>
                      {{ deviceIdentity.hasBluetooth }}
                    </div>
                    <div>
                      <span class="info-label">LCD driver:</span>
                      {{ deviceIdentity.lcdDriverId }}
                    </div>
                    <div>
                      <span class="info-label">Touch controller:</span>
                      {{ deviceIdentity.touchControllerId }}
                    </div>
                    <div>
                      <span class="info-label">Last updated:</span>
                      {{ moment(deviceIdentity.dateModified).format("lll") }}
                    </div>
                  </div>
                </div>
                <div v-if="canViewFwUpdateUrl">
                  <v-divider class="my-3" />
                  <div>
                    <span class="info-label valign-center">Firmware update:</span>
                    <span v-if="!fwUpdateUrl" class="text-decoration-underline cursor-pointer" @click="getFwUpdateUrl()"
                      >Show URL</span
                    >
                    <span v-if="fwUpdateUrl">
                      <a :href="fwUpdateUrl" color="primary" target="_blank">{{ fwUpdateUrl }}</a>
                      <v-btn density="compact" @click="copyToClipboard(fwUpdateUrl)" size="small" icon class="ml-2">
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </span>
                  </div>
                </div>
              </div>
            </PropEditor>
            <PropEditor name="Last known location" valign="top" v-if="canViewLastLocation">
              <DeviceTelemetryView v-model="deviceId" />
            </PropEditor>
            <PropEditor name="Name" desc="">
              <v-text-field
                v-model="value.deviceName"
                density="compact"
                :variant="canEditDevice ? 'outlined' : 'solo'"
                :flat="!canEditDevice"
                :readonly="!canEditDevice"
              ></v-text-field>
            </PropEditor>

            <PropEditor name="Status">
              <v-select
                :list-props="{ density: 'compact' }"
                attach
                v-model="value.status"
                :items="deviceStatus"
                :variant="isAdmin ? 'outlined' : 'plain'"
                :readonly="!isAdmin"
                density="compact"
                item-color=""
              >
                <template v-slot:selection="{  item }">
                  <span >{{ item.title }}</span>
                </template>
                <template v-slot:item="{ props }">
                  <v-list-item v-bind="props">
                  </v-list-item>
                </template>
              </v-select>
            </PropEditor>
            <PropEditor v-if="!canViewCustomers" name="Customer ID" desc="Valid customer ID number, or 0 (zero) for none.">
              <v-text-field
                v-model="value.customerId"
                density="compact"
                :variant="canEditDevice ? 'outlined' : 'solo'"
                :flat="!canEditDevice"
                :readonly="!canEditDevice"
                :rules="customerIdRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor v-if="canViewCustomers" name="Customer">
              <CustomerSelect v-model="value.customerId" :canEdit="canEditDevice" />
            </PropEditor>
            <PropEditor name="Subscription end" desc="">
              <v-row>
                <v-col cols="12" sm="6" md="4" class="d-flex align-center">
                  <v-menu
                    v-model="subsEndDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="33"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-model="subscriptionEndDate"
                        density="compact"
                        :variant="canEditDevice ? 'outlined' : 'solo'"
                        :flat="!canEditDevice"
                        :readonly="!canEditDevice"
                        :error="!subscriptionEndDate || isNaN(Date.parse(subscriptionEndDate))"
                        v-bind="props"
                        :hide-details="true"
                      >
                        <template v-slot:prepend>
                          <v-icon class="mr-n2">mdi-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-if="canEditDevice"
                      :modelValue="
                        moment(subscriptionEndDate, 'YYYY-MM-DD', true).isValid()
                          ? moment(subscriptionEndDate).toDate()
                          : null
                      "
                      @update:modelValue="(v) => (subscriptionEndDate = moment(v).format('YYYY-MM-DD'))"
                      :hide-header="true"
                      no-title
                      scrollable
                      :show-current="moment().format('YYYY-MM-DD')"
                      @update:model-value="subsEndDateMenu = false"
                      show-adjacent-months
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="d-flex align-center">
                  <v-menu
                    ref="subsEndTimeMenuRef"
                    v-model="subsEndTimeMenu"
                    :close-on-content-click="false"
                    :return-value.sync="subscriptionEndTime"
                    :nudge-right="33"
                    transition="scale-transition"
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-model="subscriptionEndTime"
                        readonly
                        density="compact"
                        :variant="canEditDevice ? 'outlined' : 'solo'"
                        :flat="!canEditDevice"
                        v-bind="props"
                        :hide-details="true"
                        color="primary"
                      >
                        <template v-slot:prepend>
                          <v-icon class="mr-n2">mdi-clock-time-four-outline</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-time-picker
                      v-if="subsEndTimeMenu && canEditDevice"
                      v-model="subscriptionEndTime"
                      no-title
                      format="24hr"
                      full-width
                      :hide-header="true"
                      @update:hour="onHour"
                      @update:minute="saveTimeChanges"
                      color="primary"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Blocked" desc="">
              <v-switch v-model="value.isBlocked" :readonly="!canEditDevice"></v-switch>
            </PropEditor>
            <PropEditor name="Debug mode" desc="">
              <v-switch v-model="value.isDebugMode" :readonly="!canEditDevice"></v-switch>
            </PropEditor>
            <PropEditor
              v-if="isProDevice && canAllowUpdates"
              name="Allow updates"
              desc="Device is allowed to recieve firmware updates."
            >
              <v-switch v-model="value.isUpdateAllowed" :readonly="!canEditDevice"></v-switch>
            </PropEditor>
            <PropEditor name="Notes">
              <v-textarea
                v-model="value.notes"
                density="compact"
                :variant="canEditDevice ? 'outlined' : 'solo'"
                :flat="!canEditDevice"
                :readonly="!canEditDevice"
              ></v-textarea>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <!-- COMMENTS -->
        <v-tabs-window-item :reverse-transition="false" value="comments" :transition="false">
          <DeviceComments :deviceId="deviceId" @update="getCommentCount" />
        </v-tabs-window-item>

        <!-- ISSUES -->
        <v-tabs-window-item
          :reverse-transition="false"
          v-if="canViewIssues"
          value="issues"
          :transition="false"
          class="fill-height"
        >
          <AssociatedIssues :deviceId="value.deviceId" @countUpdate="getIssueCount" />
        </v-tabs-window-item>

        <!-- SERVICE -->
        <v-tabs-window-item
          :reverse-transition="false"
          v-if="canViewRepairs || canAddRepair || canViewManufacturerWarranty"
          value="service"
          :transition="false"
          class="fill-height"
        >
          <DeviceService :device="value" />
        </v-tabs-window-item>

        <!-- MOBILE NET PROVIDER -->
        <v-tabs-window-item :reverse-transition="false" v-if="isProDevice" value="mobileNetProvider" :transition="false">
          <DeviceMobileNetProvider :deviceId="mobileNetProviderStatusDeviceId" />
          <MobileNetworkUsage :deviceId="mobileNetProviderStatusDeviceId" class="mt-5" />
        </v-tabs-window-item>

        <!-- HYSTORY -->
        <v-tabs-window-item :reverse-transition="false" value="history" :transition="false">
          <DeviceChangeHistory :deviceId="changeHistoryDeviceId" />
        </v-tabs-window-item>
        <!-- LOGS -->
        <v-tabs-window-item
          :reverse-transition="false"
          v-if="canViewLogs"
          value="logs"
          :transition="false"
          class="fill-height"
        >
          <DeviceLogsView :deviceId="mobileNetProviderStatusDeviceId" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="canDeleteDevice && value.deviceId"
          color="secondary"
          @click="deleteDeviceConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          v-if="canEditDevice"
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue, toNative, Model } from "vue-facing-decorator";
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import CustomerSelect from "@/components/common/CustomerSelect.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { UserPermissionType } from "@/types/UserPermissionType";
import Device from "@/types/Device";
import DeviceHelper from "@/helpers/deviceHelper";
import deviceResource from "@/resources/DeviceResource";
import { DeviceType } from "@/types/DeviceType";
import DeviceTelemetryView from "@/components/devices/DeviceTelemetryView.vue";
import DeviceChangeHistory from "@/components/devices/DeviceChangeHistory.vue";
import DeviceMobileNetProvider from "@/components/devices/DeviceMobileNetProvider.vue";
import DeviceLogsView from "@/components/devices/DeviceLogsView.vue";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import DeviceComments from "@/components/devices/DeviceComments/DeviceComments.vue";
import AssociatedIssues from "@/components/devices/AssociatedIssues.vue";
import DeviceService from "@/components/service/DeviceService.vue";

import DeviceVersion from "@/types/DeviceVersion";
import DeviceIdentityModel from "@/types/DeviceIdentity";
import ChangeManager from "@/services/ChangeManager";
import MobileNetworkUsage from "@/components/devices/MobileNetworkUsageView.vue";
import { VForm } from "vuetify/components";

@Component({
  components: {
    SideSheet,
    PropEditor,
    DeviceTelemetryView,
    DeviceChangeHistory,
    DeviceMobileNetProvider,
    MobileNetworkUsage: MobileNetworkUsage,
    DeviceLogsView,
    DeviceMenu,
    AssociatedIssues,
    CustomerSelect,
    DeviceComments,
    DeviceService
  },
  emits: ["update:modelValue", "updated"],
})
class EditDevice extends Vue {
  moment = moment;
  userProfileService = userProfileService;
  changesControl: ChangeManager | null = null;
  commentCount: number = 0;
  issueCount: number = 0;

  @Model({ default: null })
  readonly value!: Device | null;

  @Prop()
  readonly deviceInitTab!: string | null;

  @Prop({ default: true })
  readonly hasUrlUpdate!: boolean;

  deviceIdentity: DeviceIdentityModel | null = null;
  componentActive = false;

  activated() {
    this.componentActive = true;
  }
  deactivated() {
    this.componentActive = false;
  }

  // begin change management
  @Watch("value")
  setChangeManager(val: Device | null, oldValue: Device | null) {
    this.changesControl = ChangeManager.modalController({
      controller: this.changesControl,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.deviceId !== val.deviceId,
      data: { device: val },
      message: "You have unsaved Device changes.",
      target: `device_${val?.deviceId}`,
      onLeave: () => {
        this.showDialog = false;
      },
      onSave: this.submit,
    });
  }

  @Watch("value", { deep: true })
  checkChangesStatus() {
    this.setChangesStatus();
  }

  @Watch("subscriptionEndDate")
  @Watch("subscriptionEndTime")
  onChangeDT() {
    this.setChangesStatus();
  }

  onHour(newHour: number) {
    if (!this.subscriptionEndTime) return;
    const hour = newHour < 10 ? `0${newHour}` : newHour.toString();
    this.subscriptionEndTime = hour + this.subscriptionEndTime.slice(2);
  }
  setChangesStatus() {
    if (!this.value) {
      return;
    }

    const origDevice = this.changesControl?.data?.origData?.device;
    if (!this.changesControl || !origDevice) return;
    var dt = moment(origDevice.subscriptionEndDate);
    if (dt.format("YYYY-MM-DD") !== this.subscriptionEndDate || dt.format("HH:mm") !== this.subscriptionEndTime) {
      this.changesControl?.activate();
      return;
    }
    if (!ChangeManager.isObjectEqual(origDevice, this.value || {}, { isOrigPartial: true })) {
      this.changesControl?.activate();
      return;
    }

    this.changesControl?.deactivate();
  }
  // end change management

  get disabledSubmitBtn() {
    return (
      !ChangeManager.state().isChanged ||
      !Object.keys(ChangeManager.state().targets).find((target) => target === this.changesControl?.target) ||
      !this.$refs.deviceForm
    );
  }

  @Watch("value")
  onDeviceChange() {
    this.fwUpdateUrl = null;

    // set subscription end date and time
    if (this.value) {
      this.deviceId = this.value.deviceId;

      if (this.value.subscriptionEndDate) {
        var dt = moment(this.value.subscriptionEndDate);
        this.subscriptionEndDate = dt.format("YYYY-MM-DD");
        this.subscriptionEndTime = dt.format("HH:mm");
      } else {
        this.subscriptionEndDate = "";
        this.subscriptionEndTime = "";
      }

      if (this.isProDevice) {
        this.getDeviceFirmware();
      }

      if (this.deviceInitTab) {
        this.tab = this.deviceInitTab || null;
      }

      this.getCommentCount();
      if (this.canViewIssues) {
        this.getIssueCount();
      }
    } else {
      if (this.hasUrlUpdate) {
        this.$setComponentQuery("deviceTab", null);
      }

      this.tab = null;
      this.deviceId = undefined;
      this.changeHistoryDeviceId = null;
      this.mobileNetProviderStatusDeviceId = null;
      this.logsDeviceId = null;
      this.deviceIdentity = null;
    }

    if (this.hasUrlUpdate && this.componentActive) {
      this.$setComponentQuery("deviceId", this.value?.deviceId ? this.value?.deviceId : null);
    }
  }

  deviceId: number | undefined = undefined;

  changeHistoryDeviceId: number | null = null;
  mobileNetProviderStatusDeviceId: number | null = null;
  logsDeviceId: number | null = null;

  tab: string | null = null;

  @Watch("tab")
  onTabChange(val: string | null) {
    if (val === "history") {
      this.changeHistoryDeviceId = this.value!.deviceId;
    } else if (val === "mobileNetProvider") {
      this.mobileNetProviderStatusDeviceId = this.value!.deviceId;
    } else if (val === "logs") {
      this.mobileNetProviderStatusDeviceId = this.value!.deviceId;
    }

    if (this.value?.deviceId) {
      if (this.hasUrlUpdate) {
        this.$setComponentQuery("deviceTab", val);
      }
    }
  }

  get showDialog() {
    return this.value != null;
  }

  set showDialog(value: boolean) {
    this.$emit("update:modelValue", null);
  }

  get dialogHeading() {
    let heading = "";
    if (this.value) {
      heading = this.value?.deviceId ? `Device ID: ${this.value.deviceId}` : "New device";
    }
    return heading;
  }

  valid = true;
  loading = false;

  customerIdRules = [(v: string) => /^\d+$/.test(v) || "Customer ID should be valid number"];

  deviceTypes = [
    {
      text: DeviceHelper.getDeviceTypeDisplayName(DeviceType.GpsRadarPro),
      value: DeviceType.GpsRadarPro,
    },
    {
      text: DeviceHelper.getDeviceTypeDisplayName(DeviceType.GpsRadarProX),
      value: DeviceType.GpsRadarProX,
    },
  ];

  get canViewManufacturerWarranty() {
    return userProfileService.hasPermission(UserPermissionType.ViewManufacturerWarranty);
  }
  get canViewLastLocation() {
    return userProfileService.hasPermission(UserPermissionType.ViewDeviceLocation);
  }
  get canEditDevice() {
    return userProfileService.hasPermission(UserPermissionType.EditDevices);
  }
  get canDeleteDevice() {
    return userProfileService.hasPermission(UserPermissionType.DeleteDevices);
  }
  get canViewLogs() {
    return userProfileService.hasPermission(UserPermissionType.ViewDeviceLogs);
  }
  get isAdmin() {
    return userProfileService.currentUser?.isAdministrator;
  }
  get isProDevice() {
    return this.value && (this.value.type == DeviceType.GpsRadarPro || this.value.type == DeviceType.GpsRadarProX);
  }
  get canAllowUpdates() {
    return this.isAdmin || userProfileService.hasPermission(UserPermissionType.EditAllowUpdateProperty);
  }
  get canViewFwUpdateUrl() {
    return userProfileService.hasPermission(UserPermissionType.ViewFirmwareUpdateUrl);
  }

  get canViewIssues() {
    return userProfileService.hasPermission(UserPermissionType.ViewIssues);
  }

  get canViewCustomers() {
    return userProfileService.hasPermission(UserPermissionType.ViewCustomers);
  }

  get canViewRepairs() {
    return userProfileService.hasPermission(UserPermissionType.ViewRepairs);
  }

  get canAddRepair() {
    return userProfileService.hasPermission(UserPermissionType.AddRepairs);
  }

  subsEndDateMenu = false;
  subscriptionEndDate: string = "";

  subsEndTimeMenu = false;
  subscriptionEndTime: string = "";
  saveTimeChanges() {
    this.subsEndTimeMenu = false;
  }

  deleting = false;

  fwInfo: DeviceVersion | null = null;
  firmwareVer = "";
  bootloaderVer = "";
  fwUpdateUrl: string | null = null;

  deviceStatus = DeviceHelper.getDeviceFilterByStatus().map((v) => ({ ...v, title: v.text }));
  getDeviceTypeName() {
    return this.value ? DeviceHelper.getDeviceTypeDisplayName(this.value?.type) : undefined;
  }

  async submit() {
    if (this.value === null) {
      return;
    }

    // Validate form
    const { valid } = await (this.$refs.deviceForm as InstanceType<typeof VForm>).validate();
    if (valid) {
      if (this.value.deviceId) {
        // set subscription end date
        if (this.subscriptionEndDate) {
          this.value.subscriptionEndDate = moment(
            this.subscriptionEndDate + "T" + this.subscriptionEndTime ?? "00:00"
          ).toDate();
        } else {
          this.value.subscriptionEndDate = undefined;
        }

        // Update
        this.loading = true;
        deviceResource
          .updateDevice(this.value)
          .then((resp) => {
            this.showDialog = false;
            this.$emit("updated", this.value);
          })
          .catch(deviceResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      } else {
        // New
        infoMessageService.show(InfoMessageType.Warning, "Creating new devices is not supported at the moment");
      }
    }
  }

  getDeviceFirmware() {
    this.firmwareVer = "loading...";
    this.bootloaderVer = "loading...";

    deviceResource
      .getLastKnownDeviceVersion(this.value!.deviceId)
      .then((resp) => {
        this.firmwareVer = resp.data.firmwareVersion;
        if (resp.data.bootMemAddress) {
          this.firmwareVer += ` (${resp.data.bootMemAddress})`;
        }
        this.bootloaderVer = resp.data.bootloaderVersion;
        this.fwInfo = resp.data;
      })
      .catch((e) => {
        if (e.response && e.response.status == 404) {
          this.firmwareVer = "unknown";
          this.bootloaderVer = "unknown";
          this.fwInfo = {} as DeviceVersion;
        } else {
          deviceResource.defaultErrorHandler(e);
          this.firmwareVer = "error";
        }
      });
  }

  deleteDeviceConfirm() {
    if (!this.canDeleteDevice || this.value == null) {
      return;
    }

    this.$confirm
      .show(`Delete device '${this.value.deviceName}' with ID ${this.value.deviceId}?`, {
        width: 360,
      })
      .then((confirmed) => {
        if (confirmed) {
          this.deleteDevice();
        }
      });
  }

  deleteDevice() {
    if (!this.canDeleteDevice || this.value == null) {
      return;
    }

    this.deleting = true;
    deviceResource
      .deleteDevice(this.value.deviceId)
      .then((resp) => {
        this.showDialog = false;
        this.$emit("updated");
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        this.deleting = false;
      });
  }

  fetchDeviceIdentity() {
    if (!this.value?.deviceId) {
      return;
    }
    deviceResource
      .getDeviceIdentityById(this.value.deviceId)
      .then((resp) => {
        this.deviceIdentity = resp.data;
      })
      .catch(deviceResource.defaultErrorHandler);
  }

  getFwUpdateUrl() {
    if (!this.value) {
      return;
    }

    deviceResource.getFwUpdateUrl(this.value.deviceId).then((resp) => {
      if (resp.data) {
        this.fwUpdateUrl = resp.data;
      }
    });
  }

  getIssueCount() {
    if (!this.value || !this.canViewIssues) {
      return;
    }
    deviceResource
      .getIssueCount(this.value.deviceId)
      .then((resp) => {
        if (resp.data) {
          this.issueCount = resp.data.total;
        }
      })
      .catch(deviceResource.defaultErrorHandler);
  }

  getCommentCount() {
    if (!this.value) {
      return;
    }
    deviceResource
      .getCommentCount(this.value.deviceId)
      .then((resp) => {
        if (resp.data) {
          this.commentCount = resp.data.total;
        }
      })
      .catch(deviceResource.defaultErrorHandler);
  }

  copyToClipboard(content: string | null) {
    if (content) {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          infoMessageService.show(InfoMessageType.Success, "Copied to clipboard");
        })
        .catch((e) => {
          infoMessageService.show(InfoMessageType.Error, "Failed to copy content");
        });
    }
  }

  close(value: boolean) {
    if (!value && ChangeManager.state().isChanged) {
      ChangeManager.show();
      return;
    }

    this.showDialog = value;
  }
}

export default toNative(EditDevice);
</script>
