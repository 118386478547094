import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      eager: "",
      "onUpdate:modelValue": _ctx.cancel,
      "model-value": _ctx.state.showWarnings,
      scrim: "rgba(33, 33, 33)",
      "max-width": "500",
      transition: false,
      onKeydown: _withKeys(_ctx.cancel, ["esc"]),
      opacity: "0",
      "content-class": "changesWarning-for-test"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { tile: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar, {
              flat: "",
              color: "primary",
              height: 4
            }),
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.target.message || _ctx.state.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, { class: "text-subtitle-1 text-body-1 text-medium-emphasis py-3" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.state.message), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  size: "small",
                  variant: "tonal",
                  onClick: _ctx.cancel,
                  "test-id": "cancel-btn"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Cancel ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_btn, {
                  size: "small",
                  variant: "tonal",
                  onClick: _ctx.leave,
                  "test-id": "leave-btn"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Leave ")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                (_ctx.target.onSave)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      size: "small",
                      variant: "flat",
                      color: "primary",
                      onClick: _ctx.save,
                      "test-id": "save-btn"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Save & Close ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onUpdate:modelValue", "model-value", "onKeydown"])
  ]))
}