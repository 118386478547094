import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, mergeProps as _mergeProps, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-060cfd04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    class: _normalizeClass(["mr-2 btn", _ctx.btnStyles]),
    color: `${_ctx.isThemeDark ? 'secondary' : undefined}`,
    style: _normalizeStyle({ width: _ctx.data.title.length * 7 + 45 + 'px' }),
    size: "small",
    onClick: _ctx.setActive
  }, {
    default: _withCtx(() => [
      (!_ctx.isActive)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_v_icon, {
              class: "mr-1",
              size: "16"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.data.icon), 1)
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.data.title), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isActive)
        ? (_openBlock(), _createBlock(_component_v_combobox, {
            key: 1,
            loading: _ctx.loading,
            modelValue: _ctx.data.disableMultiple ? _ctx.data.selected[0] : _ctx.data.selected,
            "onUpdate:modelValue": _ctx.onInput,
            ref: _ctx.data.filterName,
            items: _ctx.suggestedItems,
            "item-title": "text",
            density: "compact",
            placeholder: _ctx.data.title,
            class: "btn-field",
            "hide-details": "",
            "hide-no-data": _ctx.loading || !_ctx.isLoaded,
            multiple: _ctx.data.disableMultiple ? false : true,
            "auto-select-first": "",
            onKeyup: _cache[0] || (_cache[0] = _withKeys(_withModifiers(() => {}, ["stop","prevent"]), ["space"])),
            onBlur: _ctx.blur,
            "search-input": _ctx.search,
            "menu-props": {
        modelValue: _ctx.isOpenMenu,
        contentClass: _ctx.isDropListFrozen ? 's-custom-disabled-menu-content-filter' : 's-custom-menu-content-filter',
      },
            "onClick:append": _withModifiers(_ctx.blur, ["stop","prevent"]),
            color: "primary",
            variant: "underlined"
          }, {
            "prepend-inner": _withCtx(() => [
              _createVNode(_component_v_icon, {
                color: _ctx.isThemeDark ? 'white' : 'dark darken-1',
                center: "",
                class: "ml-1 labe-icon",
                size: "18"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.icon), 1)
                ]),
                _: 1
              }, 8, ["color"])
            ]),
            "no-data": _withCtx(() => [
              _createVNode(_component_v_list_item, { class: "no-data" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(`No ${_ctx.data.title} available`), 1)
                ]),
                _: 1
              })
            ]),
            selection: _withCtx(() => []),
            item: _withCtx(({ item, props }) => [
              _createVNode(_component_v_list_item, _mergeProps({
                disabled: _ctx.isDropListFrozen,
                density: "compact",
                ripple: false
              }, { ...props, title: undefined }, {
                class: ["list-item", {
            'list-item-mobile': _ctx.breakpoint.xs.value,
            delimiterItem: (_ctx.data.dividerValues || []).includes(item.value) && !_ctx.search,
          }],
                "active-class": "active-item"
              }), {
                prepend: _withCtx(({ isActive }) => [
                  _createVNode(_component_v_list_item_action, { class: "mr-2 my-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_checkbox_btn, {
                        density: "compact",
                        "hide-details": "",
                        color: "primary",
                        "model-value": isActive
                      }, null, 8, ["model-value"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, {
                    density: "compact",
                    class: "text-body-2"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1024),
                  ((_ctx.data.dividerValues || []).includes(item.value) && !_ctx.search)
                    ? (_openBlock(), _createBlock(_component_v_divider, {
                        key: 0,
                        class: "delimiter"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1040, ["disabled", "class"])
            ]),
            "append-item": _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_divider),
                _createVNode(_component_v_list_item, {
                  disabled: _ctx.isDropListFrozen,
                  ripple: false,
                  density: "compact",
                  onClick: _ctx.blur,
                  class: "close-menu-btn"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, { class: "text-body-2" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Close")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ])
            ]),
            _: 1
          }, 8, ["loading", "modelValue", "onUpdate:modelValue", "items", "placeholder", "hide-no-data", "multiple", "onBlur", "search-input", "menu-props", "onClick:append"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "color", "style", "onClick"]))
}