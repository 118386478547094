<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <SidebarNav />
    </v-navigation-drawer>

    <v-app-bar app density="compact" class="pr-4" style="z-index: 6 !important;">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
    
      <WhatIsNewBtn />
      <AppBar />
    </v-app-bar>

    <v-main v-if="inited">
      <InfoMsg />
      <ChangesWarning />

      <router-view v-slot="{ Component }">
        <keep-alive
          include="Dashboard,Customers,DeviceList,DeviceLocationHistory,DeviceLogs,PoiList,PoiMap,Adverts,GeohashMap"
        >
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </v-main>

    <AppUpdateNotification />
    <AuthRefresh />

    <v-overlay :scrim="false" :model-value="!inited" opacity="0.3" z-index="99999" class="align-center justify-center" persistent>
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script lang="ts">

import { Component, Vue, toNative } from "vue-facing-decorator";
import AppBar from "@/components/layout/AppBar.vue";
import SidebarNav from "@/components/layout/SidebarNav.vue";
import InfoMsg from "@/components/layout/InfoMessage.vue";
import ChangesWarning from "@/components/layout/ChangesWarning.vue";
import AppUpdateNotification from "@/components/layout/AppUpdateNotification.vue";
import userProfileService from "@/services/UserProfileService";
import appConfigService from "@/services/AppConfigService";
import appStatusService from "@/services/AppStatusService";
import AuthRefresh from "@/components/common/AuthRefresh.vue";
import WhatIsNewBtn from "@/components/layout/WhatIsNewBtn.vue";

@Component({
  components: {
    AppBar,
    SidebarNav,
    WhatIsNewBtn,
    InfoMsg,
    ChangesWarning,
    AppUpdateNotification,
    AuthRefresh,
  },
})
class AppLayout extends Vue {
  drawer: any = null;

  get inited() {
    return userProfileService.currentUser && appConfigService.inited;
  }

  mounted() {
    userProfileService.init();
    appConfigService.init();
    appStatusService.init();
  }

  unmounted() {
    appStatusService.reset();
  }
}

export default toNative(AppLayout);
</script>
