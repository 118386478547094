import ResourceBase from "@/resources/ResourceBase";
import AdvertListItem from "@/types/AdvertListItem";
import Advert from "@/types/Advert";
import GenericResponse from "@/types/GenericResponse";
import PagedData from "@/types/PagedData";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import AdvertStats from "@/types/AdvertStats";
import AdvertSettings from "@/types/AdvertSettings";

class AdvertResource extends ResourceBase {
    getAdvertsPaged(
        itemsPerPage: number,
        pageNumber: number,
        searchTerm: string | undefined,
        orderBy: string | undefined = undefined,
        orderDesc: boolean = false,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<PagedData<AdvertListItem>>> {
        const config = {
            params: {
                itemsPerPage: itemsPerPage,
                pageNumber: pageNumber,
                search: searchTerm,
                orderBy: orderBy,
                orderDesc: orderDesc
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<Advert>>("/adverts", config);
    }

    getAdvertById(advertId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<Advert>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.get<Advert>(`/adverts/${advertId}`, config);
    }

    addAdvert(advert: Advert, cancelToken: any | undefined = undefined): Promise<AxiosResponse<number>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.post<number>("/adverts", advert, config);
    }

    updateAdvert(advert: Advert, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/adverts/${advert.advertId}`, advert, config);
    }

    deleteAdvert(advertId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/adverts/${advertId}`, config);
    }

    generateImagePreview(base64ImageData: string, cancelToken: any | undefined = undefined): Promise<AxiosResponse<string>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.post<string>("adverts/generate-image-preview", base64ImageData, config);
    }

    getAdvertStats(advertId: number, from: Date | undefined = undefined, to: Date | undefined = undefined, cancelToken: any | undefined = undefined): Promise<AxiosResponse<AdvertStats>> {
        const config = {
            params: {
                from: from,
                to: to
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<AdvertStats>(`adverts/${advertId}/stats`, config);
    }

    getAdvertSettings(cancelToken: any | undefined = undefined): Promise<AxiosResponse<AdvertSettings>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.get<AdvertSettings>(`/adverts/settings`, config);
    }

    updateAdvertSettings(advertSettings: AdvertSettings, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/adverts/settings`, advertSettings, config);
    }
}

const advertResource = new AdvertResource();
export default advertResource;
