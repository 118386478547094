import ResourceBase from "@/resources/ResourceBase";
import { AxiosResponse } from "axios";

class DeviceMessageCodingResource extends ResourceBase {
    getJsonToHex(jsonString: string): Promise<AxiosResponse<string>> {
        return this.apiClient.post<string>("/device-message-coding/json-to-hex", JSON.stringify(jsonString), { transformResponse: res => res });
    }
    getHexToJson(hexString: string): Promise<AxiosResponse<string>> {
        return this.apiClient.post<string>("/device-message-coding/hex-to-json", hexString, { transformResponse: res => res });
    }
}

const messageCodingResource = new DeviceMessageCodingResource();
export default messageCodingResource;
