<template>
  <div>
    <v-overlay :model-value="props.show" @update:model-value="onCancel" :opacity="0">
      <v-dialog
        scrim="rgba(33, 33, 33)"
        eager
        :model-value="props.show"
        @update:model-value="onCancel"
        :max-width="500"
        @keydown.esc="onCancel"
        hide-overlay
      >
        <v-card tile>
          <v-toolbar flat color="primary" :height="4"> </v-toolbar>
          <v-card-text class="text-h6 px-5">Complete Repair</v-card-text>
          <div class="px-5">
            <div>
              <div class="d-flex align-center">
                <div class="title">Result</div>
                <div class="result">
                  <v-menu offset-y>
                    <template v-slot:activator="{ props }">
                      <div>
                        <v-btn v-bind="props" :class="resultColor" size="small">
                          {{ getRepairResultName }}
                          <v-icon class="d-none d-md-block ml-1" size="small" end>mdi-chevron-down</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="() => updateResult(item.value)"
                        v-for="(item, index) in resultList"
                        :key="index"
                        density="compact"
                      >
                        <v-list-item-title
                          class="text-uppercase"
                          :class="repairHelper.getRepairResultColor(item.value, true)"
                          >{{ item.text }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div class="error">{{ errors.result }}</div>
                </div>
              </div>
              <div v-if="isNotRepaired" class="d-flex mt-2 align-start">
                <div class="title mt-3">Irreparable</div>
                <div class="d-md-flex align-center">
                  <v-checkbox
                    :model-value="props.repair?.isIrreparable"
                    @update:model-value="updateIrreparable"
                    color="primary"
                    density="compact"
                    hide-details
                    class="type flex-shrink-0"
                  />
                  <div class="ml-md-2 irreparable-hint">
                    Device is no longer usable. Device SIM-card will be automatically deactivated in the next 7 days.
                  </div>
                </div>
              </div>

              <div class="d-flex mt-2 align-center">
                <div class="title">Location</div>

                <v-select
                  :model-value="props.repair?.location"
                  :items="locations"
                  placeholder="Choose"
                  density="compact"
                  variant="underlined"
                  class="compact-select"
                  max-width="150"
                  hide-details
                  :error="!!errors.location"
                  :error-messages="errors.location"
                >
                  <template v-slot:selection="{ item }">
                    <span class="location">{{ item.title }}</span>
                  </template>
                </v-select>
              </div>
              <div class="d-flex mt-4">
                <div class="title">Notes</div>
                <v-textarea
                  color="primary"
                  :model-value="props.repair?.notes"
                  @update:model-value="updateNotes"
                  rows="2"
                  variant="outlined"
                  auto-grow
                  shaped
                  class="notes"
                ></v-textarea>
              </div>
            </div>
            <div class="d-flex align-center mt-1">
              <div class="mr-3">
                <v-icon size="30"> mdi-information-outline </v-icon>
              </div>
              <div class="info">Modifications won't be possible after the repair is completed.</div>
            </div>
          </div>
          <div class="text-right ma-5 mt-4">
            <v-btn variant="text" @click="cancel"> Cancel </v-btn>
            <v-btn
              color="primary"
              class="ml-4"
              @click="confirm"
              :disabled="!isSelectedResult"
            >
              Confirm
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import locationResource from "@/resources/LocationResource";
import Repair from "@/types/Repair";
import repairHelper from "@/helpers/repairHelper";
import { RepairResult } from "@/types/RepairResult";
import ChangeManager from "@/services/ChangeManager";

const emit = defineEmits(["confirm", "cancel"]);
const props = withDefaults(defineProps<{ repair?: Repair | null; show?: boolean }>(), {
  repair: null,
  show: false,
});

const errors = ref({ location: "", result: "" });
const locations = ref<string[]>([]);
const isSelectedResult = ref(false);

watch(
  () => props.show,
  () => {
    if (!props.show) {
      isSelectedResult.value = false;
    }
  }
);

let changesControl: ChangeManager | null = null;

// begin change management
watch(
  () => props.show,
  (show: boolean, oldShow: boolean) => {
    if (!show) errors.value = { location: "", result: "" };
    changesControl = ChangeManager.modalController({
      controller: changesControl,
      isNewValue: show && !oldShow,
      isDestroy: oldShow && !show,
      isUpdateValue: false,
      data: { finishRepair: props.repair },
      message: "You have unsaved repair changes.",
      target: `finish-repair${props.repair?.repairId}`,
      onLeave: () => cancel(),
      onSave: () => confirm(),
    });
  }
);

const isNotRepaired = computed(() => {
  return isSelectedResult.value && props.repair?.result === RepairResult.NotRepaired;
});
const setChangesStatus = () => {
  if (!props.repair) return;

  const origRepair = changesControl?.data?.origData?.finishRepair;

  if (!changesControl || !origRepair) return;

  if (!ChangeManager.isObjectEqual(origRepair, props.repair || {}, { isOrigPartial: true })) {
    changesControl?.activate();
    return;
  }
  changesControl?.deactivate();
};

watch(() => [props.repair, isSelectedResult], setChangesStatus, { deep: true });
// end change management

const getLocations = async () => {
  locations.value = await locationResource.getLocations();
};

onMounted(() => {
  getLocations();
});

const getRepairResultName = computed(() => {
  if (!isSelectedResult.value) return "Choose";
  return repairHelper.getRepairResultDisplayName(props.repair?.result);
});

const resultColor = computed(() => {
  if (!isSelectedResult.value) return { "bg-secondary": true };
  if (props.repair) {
    return repairHelper.getRepairResultColor(props.repair.result);
  }
});

const resultList = computed(() => {
  return Object.values(RepairResult)
    .filter((v) => !isNaN(Number(v)))
    .map((v) => ({
      text: repairHelper.getRepairResultDisplayName(v as number),
      value: v as number,
    }));
});

const updateResult = (item: RepairResult) => {
  errors.value.result = "";
  if (props.repair) {
    isSelectedResult.value = true;
    props.repair.result = item;
    if (item === RepairResult.Repaired) {
      props.repair.isIrreparable = false;
    }
  }
};

const updateIrreparable = (newValue: boolean | null) => {
  if (!props.repair) return;
  props.repair.isIrreparable = Boolean(newValue);
};

const updateNotes = (notes: string) => {
  if (props.repair) {
    props.repair.notes = notes;
  }
};

const confirm = () => {
  if (!props.repair?.location) errors.value.location = "Location is required";
  if (!isSelectedResult.value) errors.value.result = "Result is required";
  if (errors.value.location || errors.value.result) return;
  emit("confirm");
};

const cancel = () => {
  if (props.repair) {
    props.repair.isIrreparable = false;
  }

  emit("cancel");
};
const onCancel = () => {
  if (ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }
  cancel();
};
</script>

<style scoped>
.title {
  width: 100px;
  min-width: 100px;
  font-size: 13px;
}

.info {
  font-size: 13px;
}

.irreparable-hint {
  font-size: 13px;
  line-height: 1.2;
}

.notes * {
  font-size: 13px;
}

.location {
  font-size: 13px;
  font-weight: 500;
}

.error {
  font-size: 12px;
  color: rgb(var(--v-theme-error));
}
</style>
