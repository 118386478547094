import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-72af488e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-right mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RichText = _resolveComponent("RichText")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_Attachments = _resolveComponent("Attachments")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.loading,
    disabled: _ctx.loading,
    class: "overflow-visible"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "overflow-visible" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_sheet, { class: "add-issue-comment" }, {
            default: _withCtx(() => [
              _createVNode(_component_RichText, {
                modelValue: _ctx.newContent,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newContent) = $event)),
                disabled: _ctx.loading,
                size: "true",
                placeholder: "Write your comment here..."
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_Attachments, {
            ref: "attachmentsRef",
            targetType: _ctx.targetType,
            targetId: _ctx.commentId,
            onUpdateLocalFiles: _ctx.updateLocalFiles,
            isActionByCommand: "true"
          }, null, 8, ["targetType", "targetId", "onUpdateLocalFiles"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_btn, {
              color: "primary",
              onClick: _ctx.addComment,
              loading: _ctx.loading,
              disabled: !_ctx.isReadyForm || _ctx.loading,
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Add comment ")
              ]),
              _: 1
            }, 8, ["onClick", "loading", "disabled"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["loading", "disabled"]))
}