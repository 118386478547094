<template>
  <div>
    <side-sheet
      v-if="value"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow color="primary" slider-color="primary">
          <v-tab value="poi">POI</v-tab>
          <v-tab value="history">History</v-tab>
        </v-tabs>
      </template>

      <v-window class="full-height-tabs-wrap tabs-w-100" v-model="tab" :touch="false">
        <!-- POI -->
        <v-tabs-window-item :reverse-transition="false" value="poi" transition="none">
          <v-form ref="poiForm" v-model="valid" lazy-validation>
            <PropEditor v-if="value.poiId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">POI ID:</span> {{ value.poiId }}</div>
                <div v-if="value.createdDate">
                  <span class="info-label">Created:</span> {{ moment(value.createdDate).format("lll") }}
                </div>
                <div v-if="value.updatedDate">
                  <span class="info-label">Updated:</span> {{ moment(value.updatedDate).format("lll") }}
                </div>
                <div v-if="value.timeToLive">
                  <span class="info-label">TTL:</span> {{ moment(value.timeToLive).format("lll") }}
                </div>
                <div><span class="info-label">Negative reports:</span> {{ value.numberOfNegativeReports }}</div>
                <div v-if="value.nvdbObjectId"><span class="info-label">NVDB ID:</span> {{ value.nvdbObjectId }}</div>
                <div><span class="info-label">Geohash:</span> {{ value.geohash }}</div>
              </div>
            </PropEditor>
            <PropEditor name="Activate POI" desc="">
              <v-switch v-model="value.isActive" density="compact" :readonly="!canEditPoi" class="my-0" />
            </PropEditor>
            <PropEditor v-if="value.isActive && showTtl" name="TTL" desc="Time To Live">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    :max="ttlMax"
                    v-model="ttl"
                    step="1"
                    hide-details
                    :readonly="!canEditPoi"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="ttl"
                    type="number"
                    step="1"
                    min="0"
                    :max="ttlMax"
                    variant="outlined"
                    :readonly="!canEditPoi"
                    density="compact"
                    style="width: 80px"
                    label="Minutes"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Location" valign="top">
              <v-autocomplete
                v-model="geocodeSelectedItem"
                :items="geocodeItems"
                :loading="geocodeLoading"
                v-model:search="geocodeSearchTerm"
                density="compact"
                variant="outlined"
                clearable
                hide-no-data
                no-filter
                label="Address search"
                item-title="formatted_address"
                class="mb-2 map-search-field"
                :loader-height="4"
                return-object
              ></v-autocomplete>
              <div class="map-wrap">
                <l-map
                  v-if="showMap && value"
                  class="map-container"
                  :zoom.sync="mapZoom"
                  :center="mapCenter"
                  @click="setMarker"
                  @contextmenu="() => false"
                  :noBlockingAnimations="true"
                >
                  <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
                  <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                  <!-- ACTIVE MARKER -->
                  <l-marker-rotate
                    v-if="mapIcon"
                    :lat-lng="[value.latitude, value.longitude]"
                    :draggable="canEditPoi"
                    :rotationAngle="mapIcon.roatationOrigin ? value.direction : 0"
                    :rotationOrigin="mapIcon.roatationOrigin ? mapIcon.roatationOrigin : null"
                    @dragend="markerPositionUpdated"
                    @click="
                      () => {
                        return false;
                      }
                    "
                  >
                    <l-icon
                      v-if="!minAllowedDistanceWarning"
                      :icon-url="mapIcon.iconUrl"
                      :icon-size="mapIcon.iconSize"
                      :icon-anchor="mapIcon.iconAnchor"
                      :className="'poi ' + getPoiMarkerClass(value)"
                    ></l-icon>
                    <l-icon
                      v-else
                      :icon-url="mapIconError.iconUrl"
                      :icon-size="mapIconError.iconSize"
                      :icon-anchor="mapIconError.iconAnchor"
                      :className="'poi poi-active'"
                      :tooltipAnchor="[20, -20]"
                    ></l-icon>
                    <l-tooltip v-if="minAllowedDistanceWarning">
                      <div class="poi-tooltip text-red">
                        <h4 v-show="minAllowedDistanceWarning_newPoi">
                          Too close to an existing POI.<br />Consider reactivating nearest POI.
                        </h4>
                        <h4 v-show="minAllowedDistanceWarning_updatePoi">Too close to an existing active POI.</h4>
                      </div>
                    </l-tooltip>
                  </l-marker-rotate>
                  <!-- NEARBY MARKERS -->
                  <l-marker-rotate
                    v-for="(poiItem, ind) in nearbyPoi"
                    :key="ind"
                    :lat-lng="[poiItem[1].poi.latitude, poiItem[1].poi.longitude]"
                    :rotationAngle="poiItem[1].icon.roatationOrigin ? poiItem[1].poi.direction : 0"
                    :rotationOrigin="poiItem[1].icon.roatationOrigin ? poiItem[1].icon.roatationOrigin : null"
                    @click="(e)=> e.originalEvent.stopPropagation()"
                  >
                    <l-icon
                      :icon-url="poiItem[1].icon.iconUrl"
                      :icon-size="poiItem[1].icon.iconSize"
                      :icon-anchor="poiItem[1].icon.iconAnchor"
                      :tooltipAnchor="[20, -20]"
                      :popupAnchor="[0, -40]"
                      :className="'poi-nearby ' + getPoiMarkerClass(poiItem[1].poi)"
                    ></l-icon>
                    <l-tooltip>
                      <div class="poi-tooltip">
                        <h4>{{ getPoiTypeName(poiItem[1].poi.type) }} ID: {{ poiItem[1].poi.poiId }}</h4>
                        <div>Distance: {{ poiItem[1].distance.toFixed(0) }} m</div>
                      </div>
                    </l-tooltip>
                    <l-popup>
                      <div class="poi-popup">
                        <h4>{{ getPoiTypeName(poiItem[1].poi.type) }} ID: {{ poiItem[1].poi.poiId }}</h4>
                        <h4 class="no-wrap font-weight-regular mb-1">{{ poiItem[1].poi.areaInfo }}</h4>
                        <div v-if="isSpeedCameraType(poiItem[1].poi.type)">
                          <div>Speed limit: {{ poiItem[1].poi.speedLimit }}</div>
                        </div>
                        <div v-else-if="isCameraType(poiItem[1].poi.type)"></div>
                        <div v-else>
                          <div>Active: {{ poiItem[1].poi.isActive }}</div>
                          <div>TTL: {{ moment(poiItem[1].poi.timeToLive).format("lll") }}</div>
                        </div>
                        <div class="text-blue my-0 mt-1 cursor-pointer" @click="editAnotherPoi(poiItem[1].poi)">
                          <b>Edit (close current POI)</b>
                        </div>
                      </div>
                    </l-popup>
                  </l-marker-rotate>
                </l-map>
              </div>
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    v-model="latLng"
                    label="POI coordinates (latitude, longitude)"
                    density="compact"
                    variant="outlined"
                    :flat="!canEditPoi"
                    :readonly="!canEditPoi"
                    clearable
                    @update:model-value="applyLatLngToPoi"
                    :rules="latLngRule"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="POI type">
              <v-select
                attach
                v-model="value.type"
                :items="poiTypes"
                density="compact"
                single-line
                :variant="canEditPoi ? 'outlined' : 'solo'"
                :flat="!canEditPoi"
                :readonly="!canEditPoi"
              ></v-select>
            </PropEditor>
            <PropEditor v-if="showDirection" name="Heading">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    max="359"
                    v-model="value.direction"
                    step="1"
                    hide-details
                    :readonly="!canEditPoi"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="value.direction"
                    type="number"
                    variant="outlined"
                    density="compact"
                    :readonly="!canEditPoi"
                    style="width: 80px"
                    label="Degrees"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor v-if="showSpeedLimit" name="Speed limit">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    max="120"
                    v-model="value.speedLimit"
                    step="5"
                    hide-details
                    :readonly="!canEditPoi"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="value.speedLimit"
                    type="number"
                    variant="outlined"
                    density="compact"
                    step="5"
                    min="0"
                    max="120"
                    :readonly="!canEditPoi"
                    style="width: 80px"
                    label="Km/h"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Area info" desc="Text that will be displayed on the device screen">
              <AreaInfoEditor v-model="value.areaInfo" :readonly="!canEditPoi" />
              <v-row class="mt-2 align-center" v-if="canEditPoi">
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="suggestedAreaInfo"
                    density="compact"
                    variant="outlined"
                    disabled
                    readonly
                    label="Suggested area info"
                  />
                </v-col>
                <v-col cols="12" md="4" class="flex-grow-0 pt-0 pt-md-2 text-center">
                  <v-btn size="small" width="100%" color="secondary" @click="applySuggestedAreaInfo()"
                    >Apply suggested</v-btn
                  >
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Search tags" desc="">
              <v-text-field
                v-model="value.searchTags"
                density="compact"
                :variant="canEditPoi ? 'outlined' : 'solo'"
                :flat="!canEditPoi"
                :readonly="!canEditPoi"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Warning radius">
              <div class="d-flex mb-2">
                <v-text-field
                  v-model="warningRange[0]"
                  class="flex-grow-0"
                  style="width: 140px"
                  type="number"
                  step="50"
                  label="Short range, meters"
                  variant="outlined"
                  density="compact"
                  :readonly="!canEditPoi"
                  @change="$set(warningRange, 0, $event)"
                ></v-text-field>
                <v-spacer />
                <v-text-field
                  v-model="warningRange[1]"
                  class="flex-grow-0"
                  style="width: 140px"
                  type="number"
                  step="50"
                  label="Long range, meters"
                  variant="outlined"
                  density="compact"
                  :readonly="!canEditPoi"
                  @change="$set(warningRange, 1, $event)"
                ></v-text-field>
              </div>
              <v-range-slider
                min="300"
                max="5000"
                v-model="warningRange"
                :readonly="!canEditPoi"
                step="50"
                hide-details
              ></v-range-slider>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <!-- HYSTORY -->
        <v-tabs-window-item :reverse-transition="false" value="history" transition="none">
          <PoiChangeHistory :poiId="changeHistoryPoiId" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="canDeletePoi && value.poiId"
          color="secondary"
          @click="deletePoiConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          v-if="canEditPoi"
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue, Emit, toNative, Model } from "vue-facing-decorator";
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { UserPermissionType } from "@/types/UserPermissionType";
import Poi from "@/types/Poi";
import PoiHelper from "@/helpers/poiHelper";
import poiResource from "@/resources/PoiResource";
import { PoiType } from "@/types/PoiType";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControlScale, LIcon, LTooltip, LPopup, LControl } from "@vue-leaflet/vue-leaflet";
//@ts-ignore
import { LMarkerRotate } from "vue-leaflet-rotate-marker";
import MapIcon from "@/types/MapIcon";
import MapHelper from "@/helpers/mapHelper";
import AreaInfoEditor from "@/components/poi/AreaInfoEditor.vue";
import googleMapsResource from "@/resources/GoogleMapsResource";
import appConfigService from "@/services/AppConfigService";
import MapIcons from "@/types/MapIcons";
import PoiChangeHistory from "@/components/poi/PoiChangeHistory.vue";
import ChangeManager from "@/services/ChangeManager";
import { VForm } from "vuetify/components";

interface PoiMarker {
  poi: Poi;
  icon: MapIcon;
  distance: number;
}

@Component({
  components: {
    SideSheet,
    PropEditor,
    L,
    LMap,
    LTileLayer,
    LMarker,
    LMarkerRotate,
    LControlScale,
    LIcon,
    AreaInfoEditor,
    LTooltip,
    LPopup,
    LControl,
    PoiChangeHistory,
  },
  emits: ["update:modelValue", "updated"],
})
class EditPoi extends Vue {
  moment = moment;
  changesControl: ChangeManager | null = null;

  @Model({ default: null })
  readonly value!: Poi | null;

  @Prop()
  readonly poiInitTab!: string | null;

  @Emit()
  editAnotherPoi(poi: Poi) {
    return poi
  }

  // begin change management
  @Watch("value")
  setChangeManager(val: Poi | null, oldValue: Poi | null) {
    this.changesControl = ChangeManager.modalController({
      controller: this.changesControl,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.poiId !== val.poiId,
      data: { poi: val, ttl: this.ttl },
      message: "You have unsaved Poi changes.",
      target: `poi_${val?.poiId}`,
      onLeave: () => {
        this.showDialog = false;
      },
      onSave: this.submit,
    });
  }

  @Watch("value", { deep: true })
  checkChangesStatus() {
    this.setChangesStatus();
  }

  @Watch("ttl")
  @Watch("warningRange")
  onChangeDT() {
    this.setChangesStatus();
  }

  setChangesStatus() {
    if (!this.value) {
      return;
    }

    const origPoi = this.changesControl?.data?.origData?.poi;
    const origTtl = this.changesControl?.data?.origData?.ttl;
    if (!this.changesControl || !origPoi) return;
    if (Math.round(origTtl) !== Math.round(this.ttl)) {
      this.changesControl?.activate();
      return;
    }

    const origWarningRange = [origPoi.shortRangeWarningRadius, origPoi.longRangeWarningRadius];
    if (JSON.stringify([...this.warningRange]) !== JSON.stringify(origWarningRange)) {
      this.changesControl?.activate();
      return;
    }

    if (!ChangeManager.isObjectEqual(origPoi, this.value || {}, { isOrigPartial: true })) {
      this.changesControl?.activate();
      return;
    }

    this.changesControl?.deactivate();
  }
  // end change management

  get disabledSubmitBtn() {
    return  this.value?.poiId && !ChangeManager.state().isChanged;
  }

  @Watch("value")
  onPoiChange() {
    if (this.value) {
      this.ttl = PoiHelper.getTtlMinutes(this.value.timeToLive);
      this.changesControl?.addOrigData({ ttl: this.ttl });

      this.warningRange = [this.value.shortRangeWarningRadius, this.value.longRangeWarningRadius];
      this.mapCenter = [this.value.latitude, this.value.longitude];
      this.mapZoom = 15;
      this.initialIsActive = this.value.isActive;
      this.setLatLngFromPoi();
      this.getNearbyPoi();

      // fetch area info suggestion if new poi
      if (!this.value.poiId) {
        this.getAreaInfoSuggestion();
      }

      // delay map init
      setTimeout(() => {
        this.showMap = true;
      }, 100);

      if (this.poiInitTab) {
        this.tab = this.poiInitTab || null;
      }
    } else {
      this.$setComponentQuery("poiTab", null);
      this.tab = null;
      this.showMap = false;
      this.mapCenter = [0, 0];
      this.geocodeSelectedItem = null;
      this.geocodeItems = [];
      this.suggestedAreaInfo = " ";
      this.changeHistoryPoiId = null;
      this.resetMinAllowedDistanceWarning();
      this.resetNearbyPoi();
    }

    this.$setComponentQuery("poiId", this.value?.poiId ? this.value.poiId : null);
  }

  changeHistoryPoiId: number | null = null;

  tab: string | null = null;
  @Watch("tab")
  onTabChange(val: string | null) {
    if (val === "history") {
      this.changeHistoryPoiId = this.value!.poiId;
    }
    if (this.value?.poiId) {
      this.$setComponentQuery("poiTab", val);
    }
  }

  get showDialog() {
    return this.value != null;
  }
  set showDialog(value: boolean) {
    this.$emit("update:modelValue", null);
  }

  get dialogHeading() {
    let heading = "";
    if (this.value) {
      heading = this.value?.poiId ? `POI ID: ${this.value.poiId}` : "New POI";
    }
    return heading;
  }

  valid = true;
  loading = false;

  poiTypes = [
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.Checkpoint), value: PoiType.Checkpoint },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.SpeedControl), value: PoiType.SpeedControl },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.SeatBeltControl), value: PoiType.SeatBeltControl },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.TechnicalControl), value: PoiType.TechnicalControl },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.CustomsControl), value: PoiType.CustomsControl },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.RoadCondition), value: PoiType.RoadCondition },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.Animal), value: PoiType.Animal },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.SpeedCamera), value: PoiType.SpeedCamera },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.AverageCamera), value: PoiType.AverageCamera },
    { title: PoiHelper.getPoiTypeDisplayName(PoiType.AverageCameraEnd), value: PoiType.AverageCameraEnd },
  ];

  ttl = 0;
  ttlMax = 120;
  initialIsActive = false;

  @Watch("value.isActive")
  onPoiIsActiveChange(isActive: boolean) {
    // Set TTL to max when reactivating old POI
    if (isActive && this.initialIsActive === false && !this.ttl) {
      this.ttl = this.ttlMax;
    }
  }

  warningRange = [0, 0];

  get showSpeedLimit() {
    return this.value && (this.value.type === PoiType.SpeedCamera || this.value.type === PoiType.AverageCamera);
  }
  get showDirection() {
    return (
      this.value &&
      (this.value.type === PoiType.SpeedCamera ||
        this.value.type === PoiType.AverageCamera ||
        this.value.type === PoiType.AverageCameraEnd)
    );
  }
  get showTtl() {
    return (
      this.value &&
      (this.value.type === PoiType.Checkpoint ||
        this.value.type === PoiType.RoadCondition ||
        this.value.type === PoiType.SpeedControl ||
        this.value.type === PoiType.SeatBeltControl ||
        this.value.type === PoiType.TechnicalControl ||
        this.value.type === PoiType.CustomsControl ||
        this.value.type === PoiType.Animal)
    );
  }

  showMap = false;
  mapCenter = [0, 0];
  mapZoom = 15;
  leaflet = {
    url: MapHelper.defaultMapTilesUrl,
    attribution: MapHelper.defaultMapAttr,
  };

  @Watch("value.type")
  onPoiTypeChange(type: PoiType) {
    if (this.value) {
      this.mapIcon = PoiHelper.getMapIconByType(type);
      this.updateMinAllowedDistanceWarning();
    }
  }
  mapIcon?: MapIcon;
  mapIconError = MapIcons.error;
  minAllowedDistanceWarning_newPoi = false;
  minAllowedDistanceWarning_updatePoi = false;
  get minAllowedDistanceWarning() {
    return this.minAllowedDistanceWarning_newPoi || this.minAllowedDistanceWarning_updatePoi;
  }

  setMarker(e: any) {
    if (this.canEditPoi && this.value) {
      this.latLng = `${e.latlng.lat.toFixed(6)}, ${e.latlng.lng.toFixed(6)}`;
      this.applyLatLngToPoi(false);
    }
  }

  markerPositionUpdated(e: any) {
    if (this.canEditPoi && this.value) {
      var latlng = e.target.getLatLng();
      this.latLng = `${latlng.lat.toFixed(6)}, ${latlng.lng.toFixed(6)}`;
      this.applyLatLngToPoi(false);
    }
  }

  latLng: string | undefined = "";
  latLngRule = [
    (v: string) => !!v || "Field is required",
    (v: string) =>
      /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(v) ||
      "Correct format is 'lat, lng', ex. 63.123456, 10.123456",
  ];

  setLatLngFromPoi() {
    this.latLng = `${this.value!.latitude.toFixed(6)}, ${this.value!.longitude.toFixed(6)}`;
  }

  async applyLatLngToPoi(recenterMap: boolean = true) {
    const { valid } = await (this.$refs.poiForm as InstanceType<typeof VForm>).validate();

    if (!this.latLng || this.latLng === undefined || !valid) {
      return;
    }

    infoMessageService.clear();

    var latLngArray = this.latLng.split(",").map((elm) => parseFloat(elm.trim()));
    if (latLngArray.length === 2 && !latLngArray.some((elm) => isNaN(elm))) {
      this.value!.latitude = parseFloat(latLngArray[0].toFixed(6));
      this.value!.longitude = parseFloat(latLngArray[1].toFixed(6));

      if (recenterMap) {
        this.mapCenter = latLngArray;
      }
      this.getNearbyPoi();
      this.getAreaInfoSuggestion();
    } else {
      infoMessageService.show(InfoMessageType.Error, "Cannot parse latitude, longitude value.");
    }
  }

  nearbyPoiLastLocation: { lat: number; lng: number } = { lat: 0, lng: 0 };
  nearbyPoi: Map<number, PoiMarker> = new Map<number, PoiMarker>();

  get canAddPoi() {
    return userProfileService.hasPermission(UserPermissionType.AddPoi);
  }
  get canEditPoi() {
    return (
      userProfileService.hasPermission(UserPermissionType.EditPoi) ||
      (this.value && this.value.poiId === 0 && this.canAddPoi)
    );
  }
  get canDeletePoi() {
    return userProfileService.hasPermission(UserPermissionType.DeletePoi);
  }

  deleting = false;

  getPoiTypeName(type: PoiType) {
    return PoiHelper.getPoiTypeDisplayName(type);
  }

  isCameraType(type: PoiType) {
    return type === PoiType.SpeedCamera || type === PoiType.AverageCamera || type === PoiType.AverageCameraEnd;
  }
  isSpeedCameraType(type: PoiType) {
    return type === PoiType.SpeedCamera;
  }

  getNearbyPoi() {
    if (!this.value) {
      return;
    }

    // Check if location has significantly changed
    if (
      this.measureDistanceToCurrentPoi(this.nearbyPoiLastLocation.lat, this.nearbyPoiLastLocation.lng) >=
      PoiHelper.DefaultSearchRadius / 2
    ) {
      this.nearbyPoiLastLocation.lat = this.value.latitude;
      this.nearbyPoiLastLocation.lng = this.value.longitude;

      // POIs to fetch bounding box
      const offMeters = PoiHelper.DefaultSearchRadius;
      const ne = MapHelper.offsetMeters(this.value.latitude, this.value.longitude, offMeters, offMeters);
      const sw = MapHelper.offsetMeters(this.value.latitude, this.value.longitude, -offMeters, -offMeters);

      // Fetch
      poiResource
        .getPoiInBounds(ne.lat, ne.lon, sw.lat, sw.lon, undefined, undefined, undefined, true)
        .then((resp) => {
          var newNearbyPoi = new Map(this.nearbyPoi);
          resp.data.forEach((item) => {
            // check not exists or not current poi
            if (!this.nearbyPoi.has(item.poiId) && this.value && this.value.poiId !== item.poiId) {
              newNearbyPoi.set(item.poiId, {
                poi: item as Poi,
                icon: PoiHelper.getMapIconByType(item.type),
                distance: this.measureDistanceToCurrentPoi(item.latitude, item.longitude),
              });
            }
          });

          // apply new Map
          this.nearbyPoi = newNearbyPoi;

          // check if any poi in min allowed proximity distance
          this.updateMinAllowedDistanceWarning();
        })
        .catch(poiResource.defaultErrorHandler);
    }

    // set distance to current poi
    this.setDistanceToPoi(this.nearbyPoi);

    // check if any poi in min allowed proximity distance
    this.updateMinAllowedDistanceWarning();
  }

  resetNearbyPoi() {
    this.nearbyPoiLastLocation = { lat: 0, lng: 0 };
    this.nearbyPoi.clear();
  }

  getPoiMarkerClass(poi: Poi) {
    return PoiHelper.getPoiIconClassName(poi);
  }

  setDistanceToPoi(poiMarkersMap: Map<number, PoiMarker>) {
    poiMarkersMap.forEach((poiMarker) => {
      poiMarker.distance = this.measureDistanceToCurrentPoi(poiMarker.poi.latitude, poiMarker.poi.longitude);
    });
  }

  measureDistanceToCurrentPoi(lat: number, lng: number) {
    return PoiHelper.measureDistance(this.value!.latitude, this.value!.longitude, lat, lng);
  }

  updateMinAllowedDistanceWarning() {
    this.minAllowedDistanceWarning_newPoi = false;
    this.minAllowedDistanceWarning_updatePoi = false;

    // Check if any poi in min allowed proximity distance (for new and dinamyc poi only)
    if (this.value!.poiId === 0 && !PoiHelper.isPermanentPoiType(this.value!.type)) {
      this.nearbyPoi.forEach((poiMarker) => {
        const isPermPoi = PoiHelper.isPermanentPoiType(poiMarker.poi.type);
        if (
          (isPermPoi && poiMarker.distance < appConfigService.newPoiDynamicToStaticPoIMinAllowedProximityDistance) ||
          (!isPermPoi && poiMarker.distance < appConfigService.newPoiDynamicPoiMinAllowedProximityDistance)
        ) {
          this.minAllowedDistanceWarning_newPoi = true;
        }
      });
      // Check if any active dynamic poi in min allowed proximity distance (when updating existing and dinamyc poi only)
    } else if (this.value!.poiId !== 0 && !PoiHelper.isPermanentPoiType(this.value!.type)) {
      this.nearbyPoi.forEach((poiMarker) => {
        const isPermPoi = PoiHelper.isPermanentPoiType(poiMarker.poi.type);
        if (
          !isPermPoi &&
          poiMarker.poi.isActive &&
          poiMarker.distance < appConfigService.updatePoiDynamicPoiMinAllowedProximityDistance
        ) {
          this.minAllowedDistanceWarning_updatePoi = true;
        }
      });
    }
  }

  resetMinAllowedDistanceWarning() {
    this.minAllowedDistanceWarning_newPoi = false;
    this.minAllowedDistanceWarning_updatePoi = false;
  }

  geocodeSearchTerm = "";
  geocodeLoading = false;
  geocodeSelectedItem: google.maps.GeocoderResult | null = null;
  geocodeItems: google.maps.GeocoderResult[] = [];
  geocodeTimeout = 0;

  @Watch("geocodeSearchTerm")
  onGeocodeSearch(term: string) {
    // Search threshold
    if (this.geocodeTimeout) {
      clearTimeout(this.geocodeTimeout);
      this.geocodeTimeout = 0;
    }

    if (!term) {
      this.geocodeSelectedItem = null;
      this.geocodeItems = [];
      this.geocodeLoading = false;
      return;
    }

    if (this.geocodeSelectedItem != null) return;

    this.geocodeItems = [];
    this.geocodeLoading = true;
    this.geocodeTimeout = setTimeout(() => {
      this.geocode();
    }, 2000);
  }

  @Watch("geocodeSelectedItem")
  onGeocodeItemSelected() {
    if (this.geocodeSelectedItem) {
      this.mapZoom = 15;
      this.mapCenter = [this.geocodeSelectedItem.geometry.location.lat(), this.geocodeSelectedItem.geometry.location.lng()];
    }
  }

  geocode() {
    this.geocodeLoading = true;
    googleMapsResource
      .geocodeAddress(this.geocodeSearchTerm)
      .then((resp) => {
        if (resp.results) {
          this.geocodeItems = resp.results;
        }
      })
      .catch(googleMapsResource.defaultErrorHandler)
      .finally(() => {
        this.geocodeLoading = false;
      });
  }

  suggestedAreaInfo = " ";
  getAreaInfoSuggestion() {
    googleMapsResource.reverseGeocodeLocation(this.value!.latitude, this.value!.longitude).then((resp) => {
      if (resp.results && resp.results.length) {
        this.suggestedAreaInfo = PoiHelper.getLocationAreaInfo(resp.results);
      }
    });
  }
  applySuggestedAreaInfo() {
    if (this.suggestedAreaInfo.trim()) {
      this.value!.areaInfo = this.suggestedAreaInfo.replace(": ", "\n");
    }
  }

  async submit() {
    if (this.value === null) {
      return;
    }
    // Validate form
    const { valid } = await (this.$refs.poiForm as InstanceType<typeof VForm>).validate();

    if (valid) {
      // Get value
      this.value.shortRangeWarningRadius = this.warningRange[0];
      this.value.longRangeWarningRadius = this.warningRange[1];
      if (this.value.isActive) {
        this.value.timeToLive = moment().add(this.ttl, "m").toDate();
      } else {
        this.value.timeToLive = undefined;
      }

      if (this.value.poiId) {
        // Update
        this.loading = true;
        poiResource
          .updatePoi(this.value)
          .then((resp) => {
            this.showDialog = false;
            this.$emit("updated", this.value);
          })
          .catch((e) => {
            // reload nearby POI on error
            this.resetNearbyPoi();
            this.getNearbyPoi();

            poiResource.defaultErrorHandler(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // New
        this.loading = true;
        poiResource
          .addPoi(this.value)
          .then((resp) => {
            infoMessageService.show(InfoMessageType.Success, `New POI with ID ${resp.data} created`);
            this.showDialog = false;
            this.$emit("updated", this.value);
          })
          .catch(poiResource.defaultErrorHandler)
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }

  deletePoiConfirm() {
    if (!this.canDeletePoi || this.value == null) {
      return;
    }

    this.$confirm.show(`Delete POI ID '${this.value.poiId}'?`).then((confirmed) => {
      if (confirmed) {
        this.deletePoi();
      }
    });
  }

  deletePoi() {
    if (!this.canDeletePoi || this.value == null) {
      return;
    }

    this.deleting = true;
    poiResource
      .deletePoi(this.value.poiId)
      .then((resp) => {
        this.showDialog = false;
        this.$emit("updated");
      })
      .catch(poiResource.defaultErrorHandler)
      .finally(() => {
        this.deleting = false;
      });
  }

  close(value: boolean) {
    if (!value && ChangeManager.state().isChanged) {
      ChangeManager.show();
      return;
    }

    this.showDialog = value;
  }
}

export default toNative(EditPoi);
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 360px;
}
</style>
