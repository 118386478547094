<template>
  <v-combobox
    v-model="selectedIssue"
    :filter="filter"
    :items="isLoading || (isTyping && noFilter) ? [] : suggestion"
    :hide-no-data="!search || isLoading"
    :loading="loading || isLoading"
    :disabled="isLoading"
    v-model:search="search"
    clearable
    @click:clear="onClear"
    item-value="issueId"
    item-title="name"
    ref="issue"
    :label="label"
    persistent-hint
    :hint="hint"
    @focus="onFocus"
    @click:append="() => ($refs.issue.blur ? $refs.issue.blur() : undefined)"
    @blur="onBlur"
    :menu-props="menuProps"
    :no-filter="noFilter"
    variant="underlined"
  >
    <template v-slot:no-data>
      <v-list-item v-if="loading" class="justify-center text-center">Searching...</v-list-item>
      <v-list-item v-else-if="!canAddIssue" class="text-disabled subheading text-center">No data available</v-list-item>
      <v-list-item v-else class="d-none" />
    </template>
    <template v-slot:selection="{ attrs, item, selected }">
      <span small v-bind="attrs" :input-value="selected" class="text-no-wrap text-truncate selection">
        {{ item.raw.name || item }}
      </span>
    </template>
    <template v-slot:item="{ item, props }">
      <v-list-item v-bind="{ ...props, title: '' }">
        <div class="d-flex flex-grow-1 text-left">
          <div class="flex-srink-1">{{ item.raw.issueId }}</div>
          <div class="flex-grow-1 mx-4">{{ item.raw.name }}</div>
          <div :class="IssueHelper.getIssueStatusColor(item.raw.status, true)" class="flex-srink-1">
            {{ IssueHelper.getIssueStatusDisplayName(item.raw.status) }}
          </div>
        </div>
      </v-list-item>
    </template>
    <template v-slot:append-item>
      <v-list-item
        v-if="search && !loading && !isLoading && canAddIssue"
        class="d-flex justify-center flex-wrap"
        @click="onCreate"
      >
        <span class="subheading mr-2">Create Issue: </span>
        {{ search }}
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-facing-decorator";
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";

@Component({ components: {}, emits: ["change", "update:title", "create"] })
export default class IssueSelect extends Vue {
  @Prop({ default: true })
  readonly hasInitLoad!: boolean;

  @Prop({ default: false })
  readonly isLoading!: boolean;

  @Prop({ default: "" })
  readonly hint!: string;

  @Prop({ default: "" })
  readonly label!: string;

  @Prop({ default: {} })
  readonly menuProps!: Object;

  @Prop({ default: false })
  readonly noFilter!: boolean;

  IssueHelper = IssueHelper;
  suggestion: Issue[] = [];
  loading = false;
  //The props are needed to disable clearing by v-combobox when it loses focus.
  newIssueName: string = "";
  search: string | null = null;
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  isTyping = false;
  selectedIssue: Issue | null = null;

  get canAddIssue() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }

  get canViewIssues() {
    return userProfileService.hasPermission(UserPermissionType.ViewIssues);
  }

  @Watch("selectedIssue")
  onSelectedIssue() {
    if (typeof this.selectedIssue === "string") {
      this.selectedIssue = null;
      // @ts-ignore
      this.$refs.issue.reset();
      // @ts-ignore
      this.$refs.issue.search = this.newIssueName;

      this.$emit("change", null);
      return;
    }
    this.$emit("change", this.selectedIssue);
  }

  @Watch("search")
  onSearchChanged() {
    if (this.search !== null) {
      this.newIssueName = this.search;
    }
    this.$emit("update:title", this.search || "");

    if (!this.hasInitLoad && !this.search) {
      this.clearSearchTimeout();
      return (this.suggestion = []);
    }

    if (this.isLoading || !this.search) return;

    this.selectedIssue = null;
    this.suggestion = this.suggestion.filter((v) => v.issueId);

    this.clearSearchTimeout();
    this.isTyping = true;
    this.searchThrottleTimer = setTimeout(() => {
      this.isTyping = false;
      if (this.suggestion.length && !this.search) return;
      if (this.noFilter) {
        this.suggestion = [];
      }
      this.getData();
    }, 800);
  }

  onClear() {
    this.searchThrottleTimer = setTimeout(() => {
      if (this.suggestion.length && !this.search) return;
      if (this.noFilter) {
        this.suggestion = [];
      }
      this.getData();
    }, 800);
  }
  clearSearchTimeout() {
    if (this.searchThrottleTimer) {
      this.isTyping = false;
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }
  }
  onBlur() {
    if (this.search && !this.selectedIssue) {
      this.isTyping = false;
      this.newIssueName = this.search;
    }
  }
  onFocus() {
    // Initial tags load on focus
    if (this.isLoading) return;
    if (this.suggestion?.length === 0 && this.hasInitLoad) {
      this.getData();
    }
  }

  getData() {
    if (!this.canViewIssues) return;
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      issueResource
        .getIssuesPaged(
          10,
          1,
          this.search || "",
          undefined,
          true,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this.cancelToken
        )
        .then((resp) => {
          this.suggestion = resp.data.items;
        })
        .catch(issueResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
  filter(item: Issue, queryText: string) {
    const query = queryText || "";
    return `${item.issueId} ${item.name.toLowerCase()} 
    ${item.tags?.map(({ name }) => name?.toLowerCase())?.join(" ")}`.includes(query.toString().toLowerCase());
  }

  onCreate() {
    this.$emit("create", this.search);
  }
}
</script>

<style scoped></style>
