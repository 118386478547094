<template>
  <v-card @click="open" class="mr-4 mt-4 preview-card" max-width="102">
    <v-img :cover="true" v-if="FileHelper.isImage(mime) && !isFailedLoad" class="img" height="100" :src="imgUrl" @error="showErrIcon" />
    <div v-else-if="FileHelper.isImage(mime) && isFailedLoad" class="icon-container">
      <v-icon color="#8e8f90" size="27">mdi-download-off </v-icon>
    </div>

    <div v-else-if="isIcon" class="icon-container">
      <v-icon v-if="FileHelper.isVideo(mime) || FileHelper.isAudio(mime)" color="primary" size="50">mdi-play-circle</v-icon>
    </div>
    <div v-else class="icon-container">
      <div class="text-icon mt-1">{{ textIcon }}</div>
    </div>

    <v-btn @click.stop.prevent="removeFile" class="close-btn" icon v-if="!disabled">
      <v-icon size="17"> mdi-close-circle </v-icon>
    </v-btn>

    <div class="tools">
      <v-tooltip location="bottom" color="black" transition="fade" z-index="999999">
        <template v-slot:activator="{ props }">
          <div v-bind="props" class="text-caption text-no-wrap text-truncate text-right px-1">
            {{ name }}
          </div>
        </template>
        <div>Filename: {{ name }}</div>
        <div v-if="!localFileUrl">Created: {{ moment(file.createdAt).format("lll") }} by {{ file.createdBy }}</div>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, toNative, Emit } from "vue-facing-decorator";
import AttachedFile from "@/types/AttachedFile";
import commonHelper from "@/helpers/commonHelper";
import FileHelper from "@/helpers/fileHelper";
 emits: ['MyEvent']
@Component({emits: ["remove"]})
class FileBox extends Vue {
  FileHelper = FileHelper;
  localFileUrl = "";
  isFailedLoad = false;
  unmounted() {
    if (this.localFileUrl) {
      URL.revokeObjectURL(this.localFileUrl);
    }
  }

  @Emit("open")
  openEmit(file: AttachedFile | File) {
    return file;
  }

  @Prop()
  readonly file!: AttachedFile | File;

  @Prop()
  readonly index!: number;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Watch("file", { immediate: true })
  onChangeFile() {
    if (this.localFileUrl) {
      URL.revokeObjectURL(this.localFileUrl);
      this.localFileUrl = "";
    }
    if (this.file && this.file instanceof File) {
      this.localFileUrl = URL.createObjectURL(this.file);
    }
    this.isFailedLoad = false;
  }

  get name() {
    return this.file instanceof File ? this.file.name : this.file?.filename;
  }

  get mime() {
    return this.file instanceof File ? this.file.type : this.file?.mime;
  }

  get isIcon() {
    return FileHelper.isVideo(this.mime) || FileHelper.isAudio(this.mime);
  }

  get textIcon() {
    return this.name.substring(this.name.lastIndexOf(".") + 1).toUpperCase();
  }

  get open(): () => any {
    if (this.isFailedLoad) return () => {};
    return FileHelper.isAvailableForViewer(this.mime) ? () => this.openEmit(this.file) : this.download;
  }
  get imgUrl() {
    return this.file instanceof File
      ? this.localFileUrl
      : `${commonHelper.apiHost}${this.file.url}?width=200&height=200&quality=90`;
  }

  showErrIcon() {
    this.isFailedLoad = true;
  }

  removeFile() {
    this.$emit("remove", this.index);
  }

  download() {
    const a = document.createElement("a");
    if (this.file instanceof File) {
      a.href = this.localFileUrl;
      a.download = this.name;
    } else {
      a.href = `${commonHelper.apiHost}${this.file.downloadUrl}`;
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
export default toNative(FileBox)
</script>

<style scoped>
.icon-container {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 18px;
  box-sizing: border-box;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 15px;
  max-width: 15px;
  margin: 3px;
}

:root[theme="dark"] .close-btn {
  background: rgba(0, 0, 0, 0.6);
}

:root[theme="light"] .close-btn {
  background: rgba(255, 255, 255, 0.6);
}

.img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.preview-card {
  width: 102px;
  height: 102px;
  cursor: pointer;
  position: relative;
}

.preview-card:hover {
  border: 1px solid #fedd10 !important;
}
.preview-card {
  border: 1px solid rgb(var(--v-theme-dark-lighten3));
}

.text-icon {
  font-size: 25px;
  font-weight: 500;
  color: #8e8f90;
}

.tools {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 0 3px;
}

:root[theme="dark"] .tools {
  background: rgba(0, 0, 0, 0.6);
}

:root[theme="light"] .tools {
  background: rgba(255, 255, 255, 0.6);
}
</style>
