import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "d-flex flex-wrap" }
const _hoisted_3 = {
  key: 0,
  class: "mt-3 font-weight-light text-caption text--disabled"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "text-center text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileBox = _resolveComponent("FileBox")!
  const _component_FileInput = _resolveComponent("FileInput")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_FileViewer = _resolveComponent("FileViewer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file, index) => {
          return (_openBlock(), _createBlock(_component_FileBox, {
            key: file.attachmentId,
            file: file,
            index: index,
            disabled: _ctx.disabled,
            onRemove: _ctx.onRemoveFile,
            onOpen: _ctx.openFile
          }, null, 8, ["file", "index", "disabled", "onRemove", "onOpen"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFiles, (file, index) => {
          return (_openBlock(), _createBlock(_component_FileBox, {
            key: `${index}-local`,
            file: file,
            index: index,
            disabled: _ctx.disabled,
            onRemove: _ctx.removeLocalFile,
            onOpen: _ctx.openFile
          }, null, 8, ["file", "index", "disabled", "onRemove", "onOpen"]))
        }), 128)),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(_component_FileInput, {
              key: 0,
              onUpload: _ctx.selectImages,
              disabled: _ctx.isUploading || _ctx.isRemoving,
              hint: _ctx.maxSize,
              small: !_ctx.localFiles.length && !_ctx.files.length
            }, null, 8, ["onUpload", "disabled", "hint", "small"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Max file size is 100 MB"))
      : _createCommentVNode("", true),
    (_ctx.isUploading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_v_progress_linear, {
            class: "my-1",
            height: "2",
            "model-value": _ctx.progress
          }, null, 8, ["model-value"]),
          _createElementVNode("div", _hoisted_5, "Uploading file " + _toDisplayString(_ctx.uploadMessage) + " ...", 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_FileViewer, {
      openedFile: _ctx.openedFile,
      onClose: _ctx.closeViewer
    }, null, 8, ["openedFile", "onClose"])
  ]))
}