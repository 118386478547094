export enum PoiType {
    SpeedCamera = 0,
    AverageCamera = 1,
    Checkpoint = 2,
    SpeedControl = 3,
    SeatBeltControl = 4,
    TechnicalControl = 5,
    CustomsControl = 6,
    AverageCameraEnd = 7,
    RelayPoint = 8,
    Animal = 9,
    RoadCondition = 10
}
