<template>
  <v-card class="my-2" :loading="loading" :disabled="loading">
    <v-card-text>
      <div class="issue-comment-container">
        <div class="d-flex align-center text-truncate mb-2">
          <div class="author">
            <div class="d-flex align-center text-truncate">
              <span class="text-medium-emphasis">{{ data.createdBy }}</span>
              <div class="created-date ml-1">
                commented on
                {{ moment(data.createdAt).format("lll") }}
              </div>
            </div>
            <div v-if="data.lastModifiedBy" class="d-flex align-center text-truncate">
              <div class="secondary-font mr-1">edited by</div>
              <span class="text-medium-emphasis">{{ data.lastModifiedBy }}</span>
              <div class="created-date ml-1">on {{ moment(data.lastModifiedAt).format("lll") }}</div>
            </div>
          </div>
          <v-btn
            density="compact"
            size="small"
            v-if="canEditComment"
            @click="editMode = true"
            :disabled="loading || editMode"
            icon
            title="Edit"
            variant="text"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            v-if="canEditComment"
            @click="deleteCommentConfirm"
            :disabled="loading || editMode"
            icon
            size="small"
            density="compact"
            title="Delete"
            class="ml-2"
            variant="text"
          >
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
        </div>

        <v-sheet>
          <RichText
            v-if="editMode"
            v-model="data.content"
            @update:modelValue="() => changesControl.activate()"
            :alwaysFocus="true"
            :small="true"
          />
          <div v-else class="rte-content-view" v-html="data.content"></div>
        </v-sheet>

        <div v-if="data.attachments || editMode">
          <Attachments
            ref="attachmentsRef"
            :files="data.attachments"
            :targetType="targetType"
            :targetId="data.commentId"
            v-on:update="updateFiles"
            v-on:updateLocalFiles="updateLocalFiles"
            :disabled="!editMode || !canEditComment"
            isActionByCommand="true"
          />
        </div>

        <div v-if="editMode" class="text-right mt-4">
          <v-btn variant="text" @click="cancelContent" size="small"> Cancel </v-btn>
          <v-btn
            color="primary"
            class="ml-4"
            @click="updateComment"
            :loading="loading"
            :disabled="loading || !isReadyForm"
            size="small"
          >
            Save
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, Ref, toNative } from "vue-facing-decorator";
import Comment from "@/types/Comment";
import AttachedFile from "@/types/AttachedFile";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import issueResource from "@/resources/IssueResource";
import ChangeManager from "@/services/ChangeManager";

@Component({
  components: { RichText, Attachments },
  emits: ["delete", "update"],
})
class IssueComment extends Vue {
  @Ref() readonly attachmentsRef!: Attachments;

  @Prop()
  readonly comment!: Comment;

  moment = moment;
  targetType = AttachmentTargetType.IssueComment;
  loading = false;
  data: Comment | null = null;
  localFiles: File[] = [];
  editMode = false;
  changesControl = new ChangeManager();

  created() {
    this.data = { ...this.comment };
  }
  mounted() {
    this.changesControl.init({
      target: `comment_${this.comment.commentId}`,
      onSave: this.updateComment,
      message: `You have unsaved comment changes.`,
      isChanged: false,
    });
  }

  unmounted() {
    this.changesControl.clear();
  }

  @Watch("comment")
  onChangeComment() {
    this.data = { ...this.comment };
  }

  get canEditComment() {
    return (
      userProfileService.currentUser?.isAdministrator || this.comment?.createdById === userProfileService.currentUser?.userId
    );
  }

  get isReadyForm() {
    return Boolean(this.data?.content.trim().length);
  }

  updateFiles(files: AttachedFile[]) {
    this.changesControl.activate();
    if (!this.data) return;
    this.data.attachments = files;
  }

  updateLocalFiles(files: File[]) {
    this.changesControl.activate();
    this.localFiles = files;
  }

  deleteCommentConfirm() {
    if (!this.canEditComment || this.comment == null) {
      return;
    }
    this.$confirm
      .show(
        `Delete ${this.comment.createdBy}'s comment dated ${moment(
          this.comment.lastModifiedAt || this.comment.createdAt
        ).format("lll")}?`
      )
      .then((confirmed) => {
        if (confirmed) {
          this.deleteComment();
        }
      });
  }

  deleteComment() {
    if (!this.canEditComment || this.comment == null) {
      return;
    }

    this.loading = true;
    issueResource
      .deleteComment(Number(this.$route.params.id), this.comment.commentId)
      .then(() => {
        this.$emit("delete", this.comment.commentId);
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  cancelContent() {
    this.data = { ...this.comment };
    this.localFiles = [];
    this.editMode = false;
    this.changesControl.deactivate();
  }

  updateComment() {
    if (!this.isReadyForm || !this.data) {
      return;
    }
    this.loading = true;
    issueResource
      .updateIssueComment(Number(this.$route.params.id), this.data)
      .then(() => (this.$refs.attachmentsRef as Attachments)?.uploadLocalFiles())
      .then(() => (this.$refs.attachmentsRef as Attachments)?.removeFiles())
      .then(() => {
        if (this.data) {
          this.data.lastModifiedAt = new Date().toISOString();
          this.data.lastModifiedBy = userProfileService.currentUser?.username;
          this.data.lastModifiedById = userProfileService.currentUser?.userId;
        }

        this.$emit("update", { ...this.data });
        this.changesControl.deactivate();
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
        this.editMode = false;
      });
  }
}

export default toNative(IssueComment);
</script>

<style scoped>
.issue-comment-container {
  position: relative;
}
.created-date {
  font-weight: 400;
  color: grey;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
}
.secondary-font {
  font-weight: 400;
  color: grey;
}
.author {
  max-width: calc(100% - 55px);
  flex: 1;
  font-size: 12px;
  line-height: 1.2;
}
</style>
