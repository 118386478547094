<template>
  <div>
    <div v-if="canAddComment">
      <div class="text-subtitle-1 mb-2">Add comment</div>
      <AddIssueComment v-on:created="addComment" />
    </div>

    <div v-if="comments && comments.length">
      <div class="text-subtitle-1 mt-6 mb-2">Comments</div>

      <div v-for="comment in comments" v-bind:key="comment.commentId">
        <IssueComment
          :comment="comment"
          v-on:delete="deleteComment"
          v-on:update="updateComment"
        />
      </div>

      <div class="d-flex justify-center">
        <v-btn
          v-if="!loading && moreAvailable"
          class="my-2"
          text
          size="small"
          @click="getComments"
        >
          <v-icon start>mdi-arrow-down-bold</v-icon>
          Load more
        </v-btn>

        <v-progress-circular v-if="loading" indeterminate color="primary" size="30" class="my-2" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue , toNative } from "vue-facing-decorator";
import Comment from "@/types/Comment";
import { UserPermissionType } from "@/types/UserPermissionType";
import AddIssueComment from "@/components/issues/AddIssueComment.vue";
import IssueComment from "@/components/issues/IssueComment.vue";
import userProfileService from "@/services/UserProfileService";
import issueResource from "@/resources/IssueResource";

@Component({
  components: { IssueComment, AddIssueComment },
})
export default class IssueComments extends Vue {
  comments: Comment[] = [];
  loading = false;
  moreAvailable = false;


  get canAddComment() {
    return userProfileService.hasPermission(UserPermissionType.ViewIssues);
  }

  mounted() {
    this.getComments();
  }

  getComments() {
    if (this.comments.length && !this.moreAvailable) {
      return;
    }
    this.loading = true;
    let createdBefore;

    if (this.comments.length) {
      const minDateAsMilliseconds = Math.min(
        ...this.comments.map(({ createdAt }) => new Date(createdAt).getTime())
      );
      createdBefore = new Date(minDateAsMilliseconds);
    }

    issueResource
      .getIssueComments(Number(this.$route.params.id), undefined, createdBefore)
      .then((resp) => {
        this.comments = [...this.comments, ...resp.data.comments];
        this.moreAvailable = resp.data.moreAvailable;
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  addComment(newComment: Comment) {
    this.comments.unshift(newComment);
  }

  updateComment(updComment: Comment) {
    this.comments = this.comments.map((comment) =>
      comment.commentId === updComment.commentId ? updComment : comment
    );
  }

  deleteComment(deleteCommentId: number) {
    this.comments = this.comments.filter(
      (comment) => comment.commentId !== deleteCommentId
    );
  }
}
</script>

<style scoped></style>
