<template>
  <v-overlay scrim="rgba(33, 33, 33)" v-model="show" opacity=".33" z-index="1000">
    <v-dialog
      data-side-sheet=""
      ref="sideSheet"
      v-bind:value="value"
      v-bind:heading="heading"
      v-model="show"
      :no-click-animation="noClickAnimation"
      @click:outside="clickOutside"
      origin="top center 0"
      transition="slide-x-reverse-transition"
      class="side-sheet-dialog"
      :content-class="'side-sheet ' + contentClass"
      :opacity="0"
      @keydown.esc="show = false"
    >
      <v-card flat rounded="0">
        <div class="d-flex flex-column" style="height: 100%">
          <v-toolbar density="compact" flat color="primary text-dark" class="flex-grow-0">
            <v-toolbar-title>{{ heading }}</v-toolbar-title>
            
            <v-toolbar-items>
              <slot name="heading-menu" />
              <v-btn icon @click="show = false">
                <v-icon color="dark">mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <div class="flex-grow-0">
            <slot name="tabs"></slot>
          </div>
          <div class="pa-4 flex-grow-1 overflow-auto" style="overflow-x: hidden !important">
            <slot />
          </div>
          <div class="side-sheet-actions pa-4 flex-grow-0 text-right d-flex">
            <slot name="actions" />
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script lang="ts">
import { Component, Prop, toNative, Vue, Model } from "vue-facing-decorator";

@Component({ name: "SideSheet", emits: ["update:modelValue"] })
class SideSheet extends Vue {
  @Model()
  readonly value!: boolean;

  @Prop()
  readonly heading!: string;

  @Prop()
  readonly contentClass!: string;

  @Prop({ default: false })
  readonly noClickAnimation!: boolean;

  isMounted = false;

  clickOutside(e: PointerEvent) {
    const target = e.target as HTMLDivElement;
    if (target?.classList.contains("v-overlay__scrim")) {
      this.$emit("update:modelValue", false);
    }
  }

  activated() {
    if (!this.isMounted) return (this.isMounted = true);
    if (this.value) this.show = true;
  }

  deactivated() {
    this.show = false;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit("update:modelValue", value);
  }
}

export default toNative(SideSheet);
</script>
