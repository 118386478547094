<template>
  <div>
    <v-row>
      <v-col class="flex-grow-0 pr-0">
        <input
          type="text"
          id="ai-line-1"
          class="area-info-textfield"
          v-model="line1Text"
          :maxlength="totalCharsPerLine"
          :disabled="readonly"
          v-on:keydown="line1KeyEvent"
        />
        <input
          type="text"
          id="ai-line-2"
          class="area-info-textfield"
          v-model="line2Text"
          :maxlength="totalCharsPerLine"
          :disabled="readonly"
          v-on:keydown="line2KeyEvent"
        />
      </v-col>
      <v-col class="flex-grow-0 no-wrap">
        <div class="text-subtitle-2 font-weight-regular mt-1">
          <span class="d-none d-sm-inline">Line 1 remaining </span>{{ totalCharsPerLine - line1Text.length }}
        </div>
        <div class="text-subtitle-2 font-weight-regular mt-1">
          <span class="d-none d-sm-inline">Line 2 remaining </span>{{ totalCharsPerLine - line2Text.length }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue , toNative , Model  } from "vue-facing-decorator";

@Component({
  components: {},
  emits: ["update:modelValue"]
})
export default class AreaInfoEditor extends Vue {
  @Model()
  readonly value!: string | undefined;

  @Prop({ default: false })
  readonly readonly!: boolean;

  totalCharsPerLine = 27;

  get line1Text() {
    return this.getLineText(1);
  }
  set line1Text(val: string) {
    this.setAreaInfoText(1, val);
  }

  get line2Text() {
    return this.getLineText(2);
  }
  set line2Text(val: string) {
    this.setAreaInfoText(2, val);
  }

  line1KeyEvent(e: KeyboardEvent) {
    if (e.key === "ArrowDown" || e.key === "Enter") {
      e.preventDefault();
      var elm = document.getElementById("ai-line-2") as HTMLInputElement;
      if (elm) {
        elm.focus();
      }
    }
  }

  line2KeyEvent(e: KeyboardEvent) {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      var elm = document.getElementById("ai-line-1") as HTMLInputElement;
      if (elm) {
        elm.focus();
      }
    }
  }

  getLineText(lineNumber: number) {
    if (!this.value) {
      return "";
    }
    var lineContents = this.value.split("\n");
    if (lineNumber === 1 && lineContents.length > 0) {
      return lineContents[0];
    } else if (lineNumber === 2 && lineContents.length > 1) {
      return lineContents[1];
    }

    return "";
  }

  setAreaInfoText(lineNumber: number, content: string) {
    var areaInfo = "";

    var line1, line2;
    if (lineNumber === 1) {
      line1 = content;
      line2 = this.line2Text;
    } else {
      line1 = this.line1Text;
      line2 = content;
    }

    if (line1) {
      areaInfo = line1;
    }
    if (line2) {
      areaInfo += "\n" + line2;
    }

    this.$emit("update:modelValue", areaInfo ?? undefined);
  }
}
</script>

<style scoped>
.area-info-textfield {
  display: block;
  width: 256px;
  height: 26px;
  background: #000;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  font-weight: 700;
  font-size: 15px;
  padding: 4px;
  margin: 0;
  outline: 0;
  border: 1px solid rgba(255, 255, 255, 0.25);
  text-align: center;
}
.area-info-textfield:first-child {
  padding-bottom: 0;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}
.area-info-textfield:last-child {
  padding-top: 0;
  border-top: none;
  border-radius: 0 0 5px 5px;
}
</style>