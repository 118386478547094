import ResourceBase from "@/resources/ResourceBase";
import GenericResponse from "@/types/GenericResponse";
import PagedData from "@/types/PagedData";
import User from "@/types/User";
import { AxiosResponse, AxiosRequestConfig } from "axios";

class UserResource extends ResourceBase {
    getUsersPaged(
        itemsPerPage: number,
        pageNumber: number,
        searchTerm: string | undefined,
        orderBy: string | undefined = undefined,
        orderDesc: boolean = false,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<PagedData<User>>> {
        const config = {
            params: {
                itemsPerPage: itemsPerPage,
                pageNumber: pageNumber,
                search: searchTerm,
                orderBy: orderBy,
                orderDesc: orderDesc
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<User>>("/users", config);
    }

    getUserById(userId: number, cancelToken?: any): Promise<AxiosResponse<User>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.get<User>(`/users/${userId}`, config);
    }

    addUser(user: User, notifyByEmail: boolean, cancelToken: any | undefined = undefined): Promise<AxiosResponse<any>> {
        const config = {
            params: {
                notifyByEmail: notifyByEmail
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.post<any>("/users", user, config);
    }

    updateUser(user: User, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/users/${user.userId}`, user, config);
    }

    deleteUser(userId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/users/${userId}`, config);
    }

    generatePassword(
        length: number,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<string>> {
        const config = {
            params: {
                length: length,
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<string>("/users/generate-password", config);
    }

    signOutEverywhere(userId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.post<GenericResponse>(`/users/${userId}/sign-out-everywhere`, config);
    }

    getApiUserActivity(userId: number, days?: number, cancelToken?: any): Promise<AxiosResponse<{[key:string]: number}>> {
        const config = { params: { days }, cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<{[key:string]: number}>(`/users/${userId}/activity/daily`, config)
    }
    
}

const userResource = new UserResource();
export default userResource;
