<template>
  <div>
    <side-sheet
      v-if="value"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow color="primary" slider-color="primary">
          <v-tab value="customer">Customer</v-tab>
          <v-tab value="devices">Devices</v-tab>
          <v-tab value="comments" v-if="value.customerId">
            <span
              >Comments
              <v-badge
                density="compact"
                :color="tab === 'comments' ? 'primary' : 'badge'"
                :content="commentCount"
                inline
                class="ml-1"
                v-if="commentCount"
              />
            </span>
          </v-tab>
          <v-tab v-if="value?.customerId" value="history">History</v-tab>
        </v-tabs>
      </template>

      <v-window v-model="tab" :touch="false" class="full-height-tabs-wrap tabs-w-100">
        <!-- CUSTOMER -->
        <v-tabs-window-item :eager="true" :reverse-transition="false" value="customer" transition="none">
          <v-form ref="customerForm" v-model="customerFormValid" lazy-validation>
            <PropEditor v-if="value.customerId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">Customer ID:</span> {{ value.customerId }}</div>
                <div v-if="value.createdDate">
                  <span class="info-label">Created:</span> {{ moment(value.createdDate).format("lll") }}
                </div>
                <div v-if="value.updatedDate">
                  <span class="info-label">Updated:</span> {{ moment(value.updatedDate).format("lll") }}
                </div>
              </div>
            </PropEditor>
            <PropEditor name="First name" desc="">
              <v-text-field
                v-model="value.firstName"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :rules="nameRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Last name" desc="">
              <v-text-field
                v-model="value.lastName"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :rules="nameRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Email">
              <v-text-field
                v-model="value.email"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :rules="emailRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor
              name="Phone number"
              desc="Digits only, ex. 4745012345, 46123456789. Numbers should include country code."
            >
              <v-text-field
                v-model="value.phoneNumber"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :rules="phoneRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Customer type">
              <v-select
                v-model="value.type"
                :items="customerTypes"
                density="compact"
                single-line
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                attach
              ></v-select>
            </PropEditor>
            <PropEditor name="Company name">
              <v-text-field
                v-model="value.companyName"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Country">
              <v-autocomplete
                v-model="value.countryIso2"
                :items="countryCodes"
                :search-input.sync="countrySearch"
                density="compact"
                single-line
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :filter="countryFilter"
                attach
                :menu-props="{ maxHeight: '170' }"
              >
                <template v-slot:selection="{ item }">
                  <span> {{ item ? `${countryByCode(item.title)} (${item.title})` : "" }} </span>
                </template>
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props" :title="undefined" :class="{ delimiterItem: hasDelimiter(item) }" class="ml-2">
                    {{ `${countryByCode(item.title)} (${item.title})` }}

                    <v-divider v-if="hasDelimiter(item)" class="delimiter" />
                  </v-list-item>
                </template>
              </v-autocomplete>
            </PropEditor>
            <PropEditor name="Notes">
              <v-textarea
                v-model="value.notes"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
              ></v-textarea>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <!-- DEVICES -->
        <v-tabs-window-item :reverse-transition="false" value="devices" transition="none">
          <div class="text-subtitle-1 mb-1">Customer devices</div>
          <p class="text-caption mb-4">List of devices associated with this customer.</p>
          <div v-if="customerDevices && customerDevices.length">
            <v-divider />
            <v-table density="comfortable" hover>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col" class="text-left">Device ID</th>
                    <th scope="col">Type</th>
                    <th scope="col">Blocked</th>
                    <th scope="col">Subscription end</th>
                    <th scope="col">Last seen</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="device in customerDevices" :key="device.deviceId">
                    <td class="text-left">
                      <div class="no-wrap">
                        {{ device.deviceId }}
                        <DeviceMenu v-model="device.deviceId" size="small" />
                      </div>
                    </td>
                    <td>{{ getDeviceTypeName(device.type) }}</td>
                    <td>
                      <v-icon size="small" color="green" v-if="device.isBlocked">mdi-check</v-icon>
                    </td>
                    <td class="no-wrap">{{ device.subscriptionEndDate ? moment(device.subscriptionEndDate).format("lll") : "" }}</td>
                    <td class="no-wrap">{{ device.lastSeenDate ? moment(device.lastSeenDate).format("lll") : "" }}</td>
                    <td class="text-right no-wrap">
                      <v-btn
                        v-if="canViewDevices"
                        @click="openDevice(device.deviceId)"
                        variant="text"
                        icon
                        density="compact"
                        title="Open"
                      >
                        <v-icon size="x-small">mdi-open-in-new</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="canEditCustomer"
                        @click="removeCustomerDevice(device.deviceId)"
                        icon
                        title="Remove from list"
                        variant="text"
                        density="compact"
                      >
                        <v-icon size="x-small">mdi-close-thick</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
            <v-divider />
          </div>
          <v-progress-linear v-if="loadingCustomerDevices" indeterminate></v-progress-linear>
          <v-row v-if="canEditCustomer" class="mt-2">
            <v-col>
              <v-text-field
                v-model="deviceIdToAdd"
                variant="outlined"
                density="compact"
                clearable
                :rules="[numberRule]"
                :error-messages="deviceToAddValidationErrors"
                @update:model-value="deviceToAddValidationErrors = []"
                @keypress.enter="addCustomerDevice()"
                placeholder="Device ID"
              />
            </v-col>
            <v-col>
              <v-btn variant="text" :loading="loadingAddCustomerDevice" @click="addCustomerDevice()">
                <v-icon start> mdi-plus </v-icon>
                Add device
              </v-btn>
            </v-col>
          </v-row>
        </v-tabs-window-item>

        <!-- COMMENTS -->
        <v-tabs-window-item :reverse-transition="false" value="comments" transition="none" v-if="value.customerId">
          <CustomerComments :customerId="value.customerId" @update="getCommentCount" />
        </v-tabs-window-item>

        <!-- HISTORY -->
        <v-tabs-window-item :reverse-transition="false" value="history" transition="none">
          <CustomerChangeHistory :customerId="changeHistoryCustomerId" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="canDeleteCustomer && value.customerId"
          color="secondary"
          @click="deleteCustomerConfirm()"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          v-if="canEditCustomer"
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
    <EditDevice
      :modelValue="value && tab === 'devices' ? deviceToEdit : null"
      @update:modelValue="(value) => (deviceToEdit = value)"
      v-on:updated="deviceUpdated"
      :deviceInitTab="deviceInitTab"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative, Watch, Model } from "vue-facing-decorator";
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import Customer from "@/types/Customer";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { UserPermissionType } from "@/types/UserPermissionType";
import { CustomerType } from "@/types/CustomerType";
import CustomerHelper from "@/helpers/customerHelper";
import customerResource from "@/resources/CustomerResource";
import deviceResource from "@/resources/DeviceResource";
import Device from "@/types/Device";
import { DeviceType } from "@/types/DeviceType";
import DeviceHelper from "@/helpers/deviceHelper";
import EditDevice from "@/components/devices/EditDevice.vue";
import ResourceBase from "@/resources/ResourceBase";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import CustomerChangeHistory from "@/components/customers/CustomerChangeHistory.vue";
import ChangeManager from "@/services/ChangeManager";
import CustomerComments from "@/components/customers/CustomerComments/CustomerComments.vue";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { VForm } from "vuetify/components";

@Component({
  components: {
    SideSheet,
    PropEditor,
    EditDevice,
    DeviceMenu,
    CustomerChangeHistory,
    CustomerComments,
  },
  emits: ["update:modelValue", "updated"],
})
class EditCustomer extends Vue {
  moment = moment;
  userProfileService = userProfileService;
  changesControl: ChangeManager | null = null;
  commentCount: number = 0;
  countrySearch: string = "";

  @Model({ default: null })
  readonly value!: Customer | null;

  @Prop()
  readonly customerInitTab!: string | null;

  @Prop({ default: null })
  initData!: { customerId: number; deviceId: number; deviceTab: string } | null;

  // begin change management
  @Watch("value")
  setChangeManager(val: Customer | null, oldValue: Customer | null) {
    this.changesControl = ChangeManager.modalController({
      controller: this.changesControl,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.customerId !== val.customerId,
      data: { customer: val, customerDevices: this.customerDevices },
      message: "You have unsaved Customer changes.",
      target: `customer_${val?.customerId}`,
      onLeave: () => {
        this.showDialog = false;
      },
      onSave: this.submit,
    });
  }

  @Watch("value", { deep: true })
  checkChangesStatus() {
    this.setChangesStatus();
  }

  @Watch("customerDevices", { deep: true })
  setDeviceChangeManager() {
    this.setChangesStatus();
  }

  setChangesStatus() {
    if (!this.value) {
      return;
    }

    const origCustomer = this.changesControl?.data?.origData?.customer;
    if (origCustomer && !ChangeManager.isObjectEqual(origCustomer, this.value || {}, { isOrigPartial: true })) {
      this.changesControl?.activate();
      return;
    }

    const origCustomerDevice = this.changesControl?.data?.origData?.customerDevices;

    if (
      this.customerDevices &&
      origCustomerDevice &&
      !ChangeManager.isObjectEqual(this.customerDevices, origCustomerDevice)
    ) {
      this.changesControl?.activate();
      return;
    }

    this.changesControl?.deactivate();
  }
  // end change management

  get disabledSubmitBtn() {
    return !ChangeManager.state().isChanged;
  }

  get countryCodes() {
    return Object.entries(countries.getNames("en", { select: "official" }))
      .sort((a, b) => {
        const mainCountries = ["DK", "SE", "NO"];
        const isSortByAlphabet = !mainCountries.includes(a[0]) && !mainCountries.includes(b[0]);
        if (isSortByAlphabet) return a[1] > b[1] ? 1 : -1;
        return mainCountries.indexOf(a[0]) > mainCountries.indexOf(b[0]) ? -1 : 1;
      })
      .map(([k]) => k);
  }

  countryByCode(code: string) {
    return countries.getName(code, "en", { select: "official" });
  }

  @Watch("value")
  onValueChange(val: Customer | null) {
    this.deviceToAddValidationErrors = [];
    this.deviceIdToAdd = null;
    this.customerDevices = null;

    if (val != null) {
      this.tab = this.customerInitTab || null;
      this.getCommentCount();
    } else {
      this.tab = null;
      this.$setComponentQuery("customerTab", null);
      this.changeHistoryCustomerId = null;
    }

    this.$setComponentQuery("customerId", this.value?.customerId ? this.value?.customerId : null);
  }

  changeHistoryCustomerId: number | null = null;

  get showDialog() {
    return this.value != null;
  }
  set showDialog(value: boolean) {
    if (value) {
      this.$emit("update:modelValue", this.value);
    } else {
      this.$emit("update:modelValue", null);
    }
  }

  get dialogHeading() {
    let heading = "";
    if (this.value) {
      heading = this.value?.customerId
        ? `${this.value.firstName} ${this.value.lastName} (ID: ${this.value.customerId})`
        : "New customer";
    }
    return heading;
  }

  tab: string | null = null;

  activated() {
    if (this.initData?.deviceId && this.initData?.customerId) {
      this.openDevice(this.initData?.deviceId, true);
    }
  }

  @Watch("tab")
  onTabChange(val: string | null) {
    if (val === "devices") {
      if (this.customerDevices === null) {
        this.getCustomerDevices();
      }
    } else if (val === "history") {
      this.changeHistoryCustomerId = this.value!.customerId;
    }

    if (this.value?.customerId) {
      this.$setComponentQuery("customerTab", val);
    }
  }

  @Watch("deviceToEdit")
  onChangeCustomerToEdit() {
    if (!this.deviceToEdit) {
      this.deviceInitTab = null;
    }
  }

  customerFormValid = true;
  loading = false;
  deleting = false;

  nameRules = [(v: string) => !!v || "Field is required"];
  emailRules = [(v: string) => !v || /^\S+@\S+\.\S+$/.test(v) || "E-mail must be valid"];
  phoneRules = [
    (v: string) => !!v || "Phone number is required",
    (v: string) => /^\d+$/.test(v) || "Phone number should contain digits only",
  ];
  numberRule = (v: string | null) => !v || /^\d+$/.test(v) || "Field should contain a valid number";

  customerTypes = [
    { title: CustomerHelper.getCustomerTypeDisplayName(CustomerType.Unknown), value: CustomerType.Unknown },
    { title: CustomerHelper.getCustomerTypeDisplayName(CustomerType.Private), value: CustomerType.Private },
    { title: CustomerHelper.getCustomerTypeDisplayName(CustomerType.Professional), value: CustomerType.Professional },
    { title: CustomerHelper.getCustomerTypeDisplayName(CustomerType.Reseller), value: CustomerType.Reseller },
  ];

  get isAdmin() {
    return userProfileService.currentUser?.isAdministrator;
  }
  get canAddCustomer() {
    return userProfileService.hasPermission(UserPermissionType.EditCustomers);
  }
  get canEditCustomer() {
    return (
      userProfileService.hasPermission(UserPermissionType.EditCustomers) ||
      (this.value && this.value.customerId === 0 && this.canAddCustomer)
    );
  }
  get canDeleteCustomer() {
    return userProfileService.hasPermission(UserPermissionType.DeleteCustomers);
  }
  get canViewDevices() {
    return userProfileService.hasPermission(UserPermissionType.ViewDevices);
  }

  customerDevices: Device[] | null = null;
  loadingCustomerDevices = false;
  loadingAddCustomerDevice = false;
  deviceToEdit: Device | null = null;
  deviceIdToAdd: number | null = null;
  deviceToAddValidationErrors: string[] = [];
  deviceInitTab: string | null = null;

  async submit() {
    if (this.value === null) {
      return;
    }

    // Validate form
    const { valid } = await (this.$refs.customerForm as InstanceType<typeof VForm>).validate();

    // Validate form
    if (!valid) {
      return;
    }
    // Submit in 2 stages:
    //   1) Submit customer data
    //   2) Submit customer device data
    try {
      this.loading = true;

      // Set email address to null if empty
      if (!this.value.email) {
        this.value!.email = null;
      }

      if (this.value.customerId) {
        // Update
        if (this.$refs.customerForm) {
          await customerResource.updateCustomer(this.value);
        }
        this.submitCustomerDevices();
      } else {
        // New
        if (!this.$refs.customerForm) return;
        const resp = await customerResource.addCustomer(this.value);

        if (this.value != null) {
          this.value.customerId = resp.data;
          this.submitCustomerDevices(true);
        }
      }
    } catch (e) {
      customerResource.defaultErrorHandler(e);
    }

    this.loading = false;
  }

  submitCustomerDevices(isNewCustomer: boolean = false) {
    // update customer devices list if devices list was loaded or changed
    if (this.value && this.customerDevices) {
      this.loading = true;

      const deviceIds = this.customerDevices.map((item) => item.deviceId);

      customerResource
        .setCustomerDevices(this.value.customerId, deviceIds)
        .then((resp) => {
          if (isNewCustomer) {
            infoMessageService.show(InfoMessageType.Success, "New customer created");
          } else {
            infoMessageService.show(InfoMessageType.Success, "Customer information updated");
          }

          this.showDialog = false;
          this.$emit("updated");
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
        });
    } else {
      if (isNewCustomer) {
        infoMessageService.show(InfoMessageType.Success, "New customer created");
      } else {
        infoMessageService.show(InfoMessageType.Success, "Customer information updated");
      }
      this.showDialog = false;
      this.$emit("updated");
    }
  }

  deleteCustomerConfirm() {
    if (!this.canDeleteCustomer || this.value == null) {
      return;
    }
    this.$confirm.show(`Delete customer '${this.value.firstName} ${this.value.lastName}'?`).then((result) => {
      if (result) {
        this.deleteCustomer();
      }
    });
  }

  deleteCustomer() {
    if (!this.canDeleteCustomer || this.value == null) {
      return;
    }

    this.deleting = true;
    customerResource
      .deleteCustomer(this.value.customerId)
      .then((resp) => {
        this.showDialog = false;
        this.$emit("updated");
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        this.deleting = false;
      });
  }

  getCustomerDevices() {
    if (this.value === null) {
      return;
    }
    if (this.value.customerId === 0) {
      this.customerDevices = [];
    }

    this.loadingCustomerDevices = true;
    customerResource
      .getDevicesByCustomerId(this.value.customerId)
      .then((resp) => {
        this.customerDevices = resp.data;
        this.changesControl?.addOrigData({ customerDevices: resp.data });
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        this.loadingCustomerDevices = false;
      });
  }

  getDeviceTypeName(type: DeviceType) {
    return DeviceHelper.getDeviceTypeDisplayName(type);
  }

  openDevice(deviceId: number, isInitData = false) {
    deviceResource
      .getDeviceById(deviceId)
      .then((resp) => {
        this.deviceToEdit = resp.data;
        if (isInitData) {
          this.deviceInitTab = this.initData?.deviceTab || null;
        }
      })
      .catch(deviceResource.defaultErrorHandler);
  }

  deviceUpdated(device: Device) {
    if (!this.value || !this.customerDevices) {
      return;
    }

    // filter out device if it has been just removed
    var filteredDeviceList = this.customerDevices.filter((item) => {
      if (device.deviceId === item.deviceId) {
        // if, after update, device no longer belongs to the customer, we remove it from the list
        if (item.customerId > 0 && Number(device.customerId) !== this.value!.customerId) {
          return false;
        }
      }

      return true;
    });

    // update device object
    var updatedDeviceList: Device[] = filteredDeviceList.map((item) => {
      if (device.deviceId === item.deviceId) {
        return device;
      } else {
        return item;
      }
    });

    this.customerDevices = updatedDeviceList;
    this.changesControl?.addOrigData({ customerDevices: updatedDeviceList });
  }

  addCustomerDevice() {
    const deviceId = Number(this.deviceIdToAdd);

    // check if not in the list already
    if (this.customerDevices?.some((item) => item.deviceId === deviceId)) {
      this.deviceToAddValidationErrors.push(`Device ID ${this.deviceIdToAdd} is already associated with this customer`);
      return;
    }

    if (this.value && deviceId > 0) {
      this.loadingAddCustomerDevice = true;
      deviceResource
        .getDeviceById(deviceId)
        .then((resp) => {
          if (resp.data.customerId != 0 && resp.data.customerId != this.value?.customerId) {
            this.$confirm
              .show(
                `<p class="text-red">Device ID ${this.deviceIdToAdd} is already associated with Customer ID ${resp.data.customerId}.</p>Do you still want to associated this device with the current customer?`,
                { width: 400 }
              )
              .then((result) => {
                if (result) {
                  // add device
                  this.deviceIdToAdd = null;
                  this.customerDevices?.push(resp.data);
                }
              });
          } else {
            // add device
            this.deviceIdToAdd = null;
            this.customerDevices?.push(resp.data);
          }
        })
        .catch((error) => {
          var errors = ResourceBase.readErrors(error);
          errors.forEach((msg) => {
            this.deviceToAddValidationErrors.push(msg);
          });
        })
        .finally(() => {
          this.loadingAddCustomerDevice = false;
        });
    }
  }

  removeCustomerDevice(deviceId: number) {
    this.$confirm
      .show(`Remove Device ID ${deviceId} from customer ${this.value?.firstName} ${this.value?.lastName}?`)
      .then((confirmed) => {
        if (confirmed) {
          if (!this.customerDevices) {
            return;
          }
          var filteredDeviceList = this.customerDevices.filter((item) => {
            return item.deviceId !== deviceId;
          });

          this.customerDevices = filteredDeviceList;
        }
      });
  }

  getCommentCount() {
    if (!this.value?.customerId) {
      return;
    }
    customerResource
      .getCommentCount(this.value.customerId)
      .then((resp) => {
        if (resp.data) {
          this.commentCount = resp.data.total;
        }
      })
      .catch(customerResource.defaultErrorHandler);
  }

  close(value: boolean) {
    if (!value && ChangeManager.state().isChanged) {
      ChangeManager.show();
      return;
    }

    this.showDialog = value;
  }

  countryFilter(item: string, queryText: string) {
    const searchText = queryText.toLowerCase();

    return (
      item.toLowerCase().startsWith(searchText) || (this.countryByCode(item) || "").toLowerCase().startsWith(searchText)
    );
  }

  hasDelimiter(item: string) {
    return item === "DK" && !this.countrySearch;
  }
}
export default toNative(EditCustomer);
</script>

<style scoped>
.delimiter {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: -5px;
}
.delimiterItem {
  position: relative;
  margin-bottom: 10px;
}
</style>
