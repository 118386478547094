<script setup lang="ts">
import Device from "@/types/Device";
import ManufacturerWarranty from "@/types/ManufacturerWarranty";
import deviceResource from "@/resources/DeviceResource";
import repairResource from "@/resources/RepairResource";
import { ref, onUnmounted, onMounted, computed } from "vue";
import axios, { CancelTokenSource } from "axios";
import RepairItem from "@/types/RepairItem";
import moment from "moment";
import NewRepair from "@/components/service/NewRepair.vue";
import ManufacturerWarrantyHistory from "@/components/service/ManufacturerWarrantyHistory.vue";
import EditRepair from "@/components/service/EditRepair.vue";
import { UserPermissionType } from "@/types/UserPermissionType";
import { RepairStatus } from "@/types/RepairStatus";
import { RepairResult } from "@/types/RepairResult";
import Repair from "@/types/Repair";
import userProfileService from "@/services/UserProfileService";
import RepairHelper from "@/helpers/repairHelper";
import authService from "@/services/AuthService";
import type { VDataTable } from "vuetify/components";

type ReadonlyHeaders = VDataTable["$props"]["headers"];

const props = withDefaults(defineProps<{ device?: Device | null }>(), {
  device: null,
});

const isNewDialog = ref(false);

const warrantyLoading = ref(false);
const repairLoading = ref(false);
const repairs = ref<RepairItem[]>([]);
let cancelWarrantyToken: CancelTokenSource | undefined = undefined;
const latestWarranty = ref<ManufacturerWarranty | null>(null);
let cancelRepairToken: CancelTokenSource | undefined = undefined;
const isActiveWarranty = computed(() => moment().isBefore(moment(latestWarranty.value?.warrantyEndDate)));
const editRepair = ref<RepairItem | null>(null);

const canViewManufacturerWarranty = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.ViewManufacturerWarranty);
});

const canAddRepair = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.AddRepairs);
});

const canViewRepairs = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.ViewRepairs);
});
const canEditRepair = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.EditRepairs);
});

onMounted(() => {
  getWarranty();
  getRepairs();
});

onUnmounted(() => {
  // Cancel existing request
  if (cancelWarrantyToken) {
    cancelWarrantyToken.cancel?.();
  }
});

const getStatusName = (status: RepairStatus) => {
  return RepairHelper.getRepairStatusDisplayName(status) || "";
};

const statusColor = (repair: RepairItem) => {
  return RepairHelper.getRepairStatusColor(repair.status, true, repair.result);
};

const isCreatingAvailable = computed(() => {
  return !repairs.value.some(({ status }) => RepairStatus.Completed !== status) && !repairLoading.value;
});

const getWarranty = () => {
  // Cancel existing request
  if (cancelWarrantyToken) {
    cancelWarrantyToken.cancel();
  }

  if (props.device === null || !canViewManufacturerWarranty.value) {
    return;
  }
  cancelWarrantyToken = axios.CancelToken.source();

  warrantyLoading.value = true;
  deviceResource
    .getLatestManufacturerWarranties(props.device.deviceId, cancelWarrantyToken)
    .then((resp) => {
      latestWarranty.value = resp.data;
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      warrantyLoading.value = false;
      cancelWarrantyToken = undefined;
    });
};

const getRepairs = () => {
  // Cancel existing request
  if (cancelRepairToken) {
    cancelRepairToken.cancel();
  }

  if (props.device === null || !canViewRepairs.value) {
    return;
  }
  repairLoading.value = true;
  repairResource
    .getRepairs(props.device.deviceId)
    .then((resp) => {
      repairs.value = resp.data.items.sort((a, b) => (a.repairId < b.repairId ? 1 : -1));
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      repairLoading.value = false;
      cancelRepairToken = undefined;
    });
};

const onChangeRepair = (updatedRepair: Repair) => {
  if (updatedRepair?.result === RepairResult.Repaired && updatedRepair?.status === RepairStatus.Completed) {
    getWarranty();
  }
  getRepairs();
};

const addRepair = () => {
  if (!props.device?.deviceId) return;

  if (canEditRepair.value) {
    editRepair.value = {
      repairId: 0,
      deviceId: props.device?.deviceId,
      createdAt: new Date(),
      createdBy: authService.authInfo.username || "",
      status: RepairStatus.Pending,
      result: null,
    };
    return;
  }

  if (canAddRepair.value) {
    isNewDialog.value = true;
    return;
  }
};

const headers: ReadonlyHeaders = [
  { title: "ID", key: "repairId" },
  { title: "Date", key: "createdAt" },
  { title: "User", align: "start", key: "createdBy" },
  { title: "Status", key: "status", sortable: false },
];

const rowRepairClick = (repair: Repair) => {
  editRepair.value = repair;
};

const daysLeft = computed(() =>
  latestWarranty.value ? moment(latestWarranty.value?.warrantyEndDate).diff(moment(), "days") : -1
);
</script>

<template>
  <div>
    <div v-if="canViewManufacturerWarranty" class="mb-10">
      <div class="mb-3 text-subtitle-1">Manufacturer warranty</div>
      <div v-if="warrantyLoading" class="text-body-2 text-disabled">Loading ...</div>
      <div v-else-if="latestWarranty" class="text-body-2">
        <div class="d-table w-auto">
          <div class="d-table-row">
            <span class="d-table-cell pr-4">Status:</span>
            <span class="d-table-cell">
              <span v-if="isActiveWarranty" class="status-value text-green">Active</span>
              <span v-else class="status-value text-red">Expired</span>
            </span>
          </div>
          <div class="d-table-row">
            <span class="d-table-cell pr-4">Period:</span>
            <span class="d-table-cell">
              <span class="status-value">
                {{
                  `${moment(latestWarranty?.warrantyStartDate).format("YYYY-MM-DD")} - ${moment(
                    latestWarranty?.warrantyEndDate
                  ).format("YYYY-MM-DD")}`
                }}
              </span>
              <span v-if="daysLeft >= 0" class="ml-1">
                {{ `(${daysLeft} days left)` }}
              </span>
            </span>
          </div>
        </div>
        <div class="mt-4">
          <ManufacturerWarrantyHistory :device="props.device" />
        </div>
      </div>
      <div v-else>
        <div class="text-disabled text-body-2">No information available</div>
      </div>
    </div>

    <div v-if="canViewRepairs">
      <div class="mb-3 text-subtitle-1 d-flex justify-space-between">
        <div>Repairs</div>
        <v-tooltip
          v-if="canAddRepair"
          :text="`You can't add a new repair while there is at least one ongoing or pending repair.`"
          location="bottom"
        >
          <template v-slot:activator="{ props }">
            <span v-bind="isCreatingAvailable ? undefined : props">
              <v-btn color="primary" class="ml-0 ml-md-4 mt-2 mt-md-0" @click="addRepair" :disabled="!isCreatingAvailable">
                New
              </v-btn>
            </span>
          </template>
        </v-tooltip>
      </div>

      <v-data-table
        density="compact"
        :headers="headers"
        :items="repairs"
        :loading="repairLoading"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
        @click:row="(event: any, { item }: any) => rowRepairClick(item)"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <div>{{ moment(item.createdAt).format("lll") }}</div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div :class="statusColor(item)">{{ getStatusName(item.status) }}</div>
        </template>
        <template #bottom></template>
      </v-data-table>
    </div>
    <NewRepair
      v-if="canAddRepair"
      :show="isNewDialog"
      :key="isNewDialog.toString()"
      @update:show="(v) => (isNewDialog = v)"
      :device="props.device"
      @created="getRepairs"
    />

    <EditRepair
      :editRepair="editRepair"
      :device="props.device"
      @close:editRepair="editRepair = null"
      @delete="getRepairs"
      @create="onChangeRepair"
      @update="onChangeRepair"
    />
  </div>
</template>
<style scoped>
.status-value {
  text-transform: uppercase;
}
</style>
