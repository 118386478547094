import ResourceBase from "@/resources/ResourceBase";
import { AxiosResponse } from "axios";
import Info from "@/types/Info";
import AppStatus from "@/types/AppStatus";
import ApiStatus from "@/types/ApiStatus";
import UserProfile from "@/types/UserProfile";
import AppConfig from "@/types/AppConfig";

class AppResource extends ResourceBase {
    getInfo(): Promise<AxiosResponse<Info>> {
        return this.apiClient.get<Info>("/app/info");
    }
    getApiStatus(): Promise<AxiosResponse<ApiStatus>> {
        return this.apiClient.get<ApiStatus>("/app/status");
    }
    getAppStatus(): Promise<AxiosResponse<AppStatus>> {
        return this.httpClient.get<AppStatus>("/app-ver.json");
    }
    getUserProfile(): Promise<AxiosResponse<UserProfile>> {
        return this.apiClient.get<UserProfile>("/app/userprofile");
    }
    getConfig(): Promise<AxiosResponse<AppConfig>> {
        return this.apiClient.get<AppConfig>("/app/configuration");
    }
}

const appResource = new AppResource();
export default appResource;
