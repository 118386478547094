import ResourceBase from "@/resources/ResourceBase";
import UserGroup from "@/types/UserGroup";
import { AxiosResponse, AxiosRequestConfig } from "axios";

class UserGroupResource extends ResourceBase {
    getAllUserGroups(
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<UserGroup[]>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<UserGroup[]>("/user-groups/all", config);
    }

    getUserGroupById(userGroupId: number, cancelToken?: any): Promise<AxiosResponse<UserGroup>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        
        return this.apiClient.get<UserGroup>(`/user-groups/${userGroupId}`, config);
    }
    updateUserGroup(
        userGroup: UserGroup,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<UserGroup[]>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.put<UserGroup[]>(`/user-groups/${userGroup.userGroupId}`, userGroup, config);
    }

    addUserGroup(
        userGroup: UserGroup,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<UserGroup[]>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.post<UserGroup[]>("/user-groups", userGroup, config);
    }

    deleteUserGroup(
        userGroupId: number,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<UserGroup[]>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.delete<UserGroup[]>(`/user-groups/${userGroupId}`, config);
    }
}

const userGroupResource = new UserGroupResource();
export default userGroupResource;
