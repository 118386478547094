<template>
  <div>
    <v-overlay v-model="props.show" :opacity="0">
      <v-dialog
        scrim="rgba(33, 33, 33)"
        eager
        v-model="props.show"
        :max-width="700"
        @keydown.esc="emit('close')"
        hide-overlay
      >
        <v-card tile>
          <v-toolbar flat color="primary" :height="4"> </v-toolbar>
          <v-card-text class="text-h6 pa-4 pa-md-5 pb-3">{{ `Status History - Repair ID ${repair?.repairId}` }}</v-card-text>
          <div class="mx-3 mx-md-5 flex-grow-1 overflow-auto">
            <v-data-table
              density="compact"
              :loading="historyLoading"
              :headers="headers"
              :items="repairHistory"
              :disable-pagination="true"
              :hide-default-footer="true"
              items-per-page="-1"
              :mobile-breakpoint="0"
              item-value="repairId: number"
            >
              <template v-slot:[`item.status`]="{ item }">
                {{ repairHelper.getRepairStatusDisplayName(item.status) }}
              </template>

              <template v-slot:[`item.result`]="{ item }">
                <span class="status-value">
                  {{ getRepairResultName(item) }}
                </span>
              </template>

              <template v-slot:[`item.createdAt`]="{ item }">
                <span class="status-value">
                  {{ `${moment(item?.createdAt).format("YYYY-MM-DD HH:mm")} (${item.createdBy})` }}
                </span>
              </template>

              <template #bottom></template>
            </v-data-table>
          </div>

          <div class="text-right ma-3 ma-md-5 mt-3">
            <v-btn variant="text" @click="emit('close')" size="small"> Close </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import axios, { CancelTokenSource } from "axios";
import deviceResource from "@/resources/DeviceResource";
import Repair from "@/types/Repair";
import RepairHistory from "@/types/RepairHistory";
import { RepairStatus } from "@/types/RepairStatus";
import repairResource from "@/resources/RepairResource";
import moment from "moment";
import repairHelper from "@/helpers/repairHelper";

const emit = defineEmits(["close"]);
const props = withDefaults(defineProps<{ repair?: Repair | null; show?: boolean }>(), {
  repair: null,
  show: false,
});

const repairHistory = ref<RepairHistory[]>([]);
const historyLoading = ref(false);
let cancelHistoryLoadToken: CancelTokenSource | undefined = undefined;
const headers = [
  { title: "Status", key: "status", sortable: false },
  { title: "Result", key: "result", sortable: false },
  { title: "Location", key: "location", sortable: false },
  { title: "Updated", key: "createdAt", sortable: false },
];

const getRepairResultName = (item: RepairHistory) => {
  if (item.status !== RepairStatus.Completed) return "";
  return repairHelper.getRepairResultDisplayName(item?.result);
};

const getRepairHistory = () => {
  // Cancel existing request
  if (cancelHistoryLoadToken) {
    cancelHistoryLoadToken.cancel();
  }

  if (props.repair === null || !props.show) {
    return;
  }
  historyLoading.value = true;
  cancelHistoryLoadToken = axios.CancelToken.source();

  repairResource
    .getRepairHistory(props.repair.repairId, cancelHistoryLoadToken)
    .then((resp) => {
      repairHistory.value = resp.data;
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      historyLoading.value = false;
      cancelHistoryLoadToken = undefined;
    });
};
watch(() => [props.show, props.repair], getRepairHistory, { immediate: true });
</script>

<style scoped>
.title {
  width: 100px;
  font-size: 13px;
}
</style>
