<template>
  <form @submit.prevent="signIn" class="form-auth">
    <h3 class="form-auth-heading">
      <img src="/favicon.png" alt="Safedrive" />Safedrive
    </h3>
    <div class="input-field">
      <v-text-field
        v-model="username"
        type="text"
        label="Username"
        required
        autocomplete="off"
        test-id="username"
      />
    </div>
    <div class="input-field">
      <v-text-field
        v-model="password"
        type="password"
        label="Password"
        required
        autocomplete="off"
        test-id="password"
      />
    </div>
    <v-btn
      class="submit-button"
      type="submit"
      :loading="loading"
      :disabled="loading"
    >
      Sign in
    </v-btn>
  </form>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import authService from "@/services/AuthService";
import authResource from "@/resources/AuthResource";
import router from "@/router/router";

@Component({
  name: "Login",
  components: {},
})
class Login extends Vue {
  username = "";
  password = "";
  loading = false;

  get isFormReady() {
    return Boolean(this.username && this.password);
  }

  signIn() {
    if (!this.isFormReady) return;

    this.loading = true;
    authResource
      .signIn(this.username, this.password)
      .then((resp) => {
        authService.initAuth(this.username, resp.data.requirePasswordUpdate);

        if (resp.data.token) {
          // Sign in
          authService.signIn(resp.data.token, resp.data.expires!);
        } else {
          // MFA
          router.push({
            path: "/verification",
            query: { type: resp.data.mfaType?.toString() },
          });
        }
      })
      .catch(authResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
        this.password = "";
      });
  }
}

export default toNative(Login)
</script>
