import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-246198a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "text-caption" }
const _hoisted_3 = { class: "mr-3" }
const _hoisted_4 = { class: "mr-3 icon-label" }
const _hoisted_5 = {
  key: 0,
  class: "icon-label"
}
const _hoisted_6 = { class: "text-caption ml-3 mt-1" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "mr-3" }
const _hoisted_9 = { class: "mr-3 icon-label" }
const _hoisted_10 = {
  key: 0,
  class: "icon-label"
}
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_v_autocomplete, {
        class: "customer-select",
        attach: "",
        modelValue: _ctx.selectedItem,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItem) = $event)),
          _ctx.customerChanged
        ],
        onBlur: _ctx.onBlur,
        items: _ctx.customers,
        loading: _ctx.loading,
        search: _ctx.searchTerm,
        "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTerm) = $event)),
        clearable: _ctx.canEdit,
        "onClick:clear": _ctx.onClear,
        "no-filter": "",
        "return-object": "",
        "item-value": "customerId",
        placeholder: "Start typing to search...",
        density: "compact",
        variant: _ctx.canEdit ? 'outlined' : 'solo',
        "append-icon": !_ctx.canEdit ? null : undefined,
        flat: !_ctx.canEdit,
        readonly: !_ctx.canEdit,
        disabled: _ctx.disabled,
        "hide-details": ""
      }, {
        selection: _withCtx(({ props, item }) => [
          (!_ctx.searchTerm)
            ? (_openBlock(), _createElementBlock("span", _normalizeProps(_mergeProps({ key: 0 }, props)), [
                _createElementVNode("span", null, _toDisplayString(`${item.raw.firstName} ${item.raw.lastName}`), 1)
              ], 16))
            : _createCommentVNode("", true)
        ]),
        item: _withCtx(({ item, props }) => [
          _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps({ ...props, title: '' })), {
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(`${item.raw.firstName} ${item.raw.lastName}`), 1),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, "ID " + _toDisplayString(item.raw.customerId), 1),
                _createElementVNode("span", _hoisted_4, [
                  _createVNode(_component_v_icon, {
                    class: "mr-1",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-phone-outline")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", null, _toDisplayString(item.raw.phoneNumber), 1)
                ]),
                (item.raw.companyName)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      _createVNode(_component_v_icon, {
                        class: "mr-1",
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-briefcase-outline")
                        ]),
                        _: 1
                      }),
                      _createElementVNode("span", null, _toDisplayString(item.raw.companyName), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1040)
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue", "onBlur", "items", "loading", "search", "clearable", "onClick:clear", "variant", "append-icon", "flat", "readonly", "disabled"]),
      (_ctx.canEdit)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            icon: "",
            variant: "text",
            density: "compact",
            class: "ml-2",
            disabled: _ctx.initCustomerId === _ctx.value,
            onClick: _ctx.undo,
            title: "Undo"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-arrow-u-left-top")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.selectedItem)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, "ID " + _toDisplayString(_ctx.selectedItem.customerId), 1),
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_v_icon, {
                class: "mr-1",
                size: "small"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-phone-outline")
                ]),
                _: 1
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.selectedItem.phoneNumber), 1)
            ]),
            (_ctx.selectedItem.companyName)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                  _createVNode(_component_v_icon, {
                    class: "mr-1",
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-briefcase-outline")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.selectedItem.companyName), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, "No customer linked to device"))
    ])
  ]))
}