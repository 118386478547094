<template>
  <v-overlay :model-value="true" :opacity="overlayOpacity">
    <v-dialog
      scrim="rgba(33, 33, 33)"
      eager
      @update:model-value="change"
      :model-value="true"
      :max-width="width"
      :persistent="persistent"
      @keydown.esc="choose(false)"
      hide-overlay
      
    >
      <v-card tile>
        <v-toolbar flat :color="color" :height="4"> </v-toolbar>
        <v-card-text :class="messageStyle" v-html="message"></v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="Boolean(buttonCancelText)" :color="buttonFalseColor" :variant="buttonFalseFlat ? 'text' : undefined" @click="choose(null)">
            {{ buttonCancelText }}
          </v-btn>
          <v-btn v-if="Boolean(buttonFalseText)" :color="buttonFalseColor" :variant="buttonFalseFlat ? 'text' : undefined" @click="choose(false)">
            {{ buttonFalseText }}
          </v-btn>

          <v-btn v-if="Boolean(buttonTrueText)" :color="buttonTrueColor" :variant="buttonTrueFlat ? 'text' : undefined" @click="choose(true)">
            {{ buttonTrueText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { useTheme } from 'vuetify'

export default {
  components: {},
  props: {
    buttonTrueText: {
      type: String,
      default: "Confirm",
    },
    buttonFalseText: {
      type: String,
      default: "Cancel",
    },
    buttonCancelText: {
      type: String,
      default: null,
    },
    buttonTrueColor: {
      type: String,
      default: "primary",
    },
    buttonFalseColor: {
      type: String,
      default: "grey",
    },
    buttonFalseFlat: {
      type: Boolean,
      default: true,
    },
    buttonTrueFlat: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    message: {
      type: String,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 300,
    },
    overlayOpacity: {
      type: Number,
      default: 0,
    },
  },
  setup () {
    const theme = useTheme()
    return { theme }
  },

  data() {
    return {
      value: false,
      messageStyle: {
        "text-subtitle-1 text-body-1 py-3": true,
        "text-white": this.theme?.global?.current?.value?.dark,
        "text-black": !this.theme?.global?.current?.value?.dark,
      },
    };
  },
  emits: ["result"],
  methods: {
    onEnterPressed(e) {
      if (e.keyCode === 13) {
        e.stopPropagation();
        this.choose(true);
      }
    },
    choose(value) {
      this.$emit("result", value);
      this.value = value;
    },
    change(res) {},
  },
};
</script>
