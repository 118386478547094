import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100 d-flex justify-center my-5"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_progress_linear, {
          color: "primary",
          class: "w-25 my-5",
          indeterminate: ""
        })
      ]))
    : (!_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "html-source-container",
          innerHTML: _ctx.html
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error), 1))
}