<template>
  <div class="result">
    <v-menu offset-y :disabled="props.readonly">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" :class="resultColor" size="small">
          {{ getRepairResultName }}
          <v-icon v-if="!readonly" class="d-none d-md-block ml-1" size="small" end>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="() => emit('update')" v-for="(item, index) in resultList" :key="index" density="compact">
          <v-list-item-title class="text-uppercase" :class="repairHelper.getRepairResultColor(item.value, true)">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Repair from "@/types/Repair";
import repairHelper from "@/helpers/repairHelper";
import { RepairResult } from "@/types/RepairResult";

const emit = defineEmits(["update"]);
const props = withDefaults(defineProps<{ repair?: Repair | null; readonly: boolean }>(), {
  repair: null,
  readonly: false,
});

const getRepairResultName = computed(() => {
  return repairHelper.getRepairResultDisplayName(props.repair?.result);
});

const resultColor = computed(() => {
  if (props.readonly && props.repair) {
    return repairHelper.getRepairResultColor(props.repair.result);
  }
  if (props.repair) {
    return repairHelper.getRepairResultColor(props.repair.result);
  }
});

const resultList = computed(() => {
  return Object.values(RepairResult)
    .filter((v) => !isNaN(Number(v)))
    .map((v) => ({
      text: repairHelper.getRepairResultDisplayName(v as number),
      value: v as number,
    }));
});
</script>
