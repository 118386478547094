<template>
  <div>
    <div class="d-md-block d-flex">
      <v-card-text class="flex-grow-1 pt-1 pb-10">
        <div class="d-flex align-center">
          <div class="title">Status</div>
          <div class="status">
            <v-menu offset-y :disabled="props.readonly">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" flat :class="statusColor" size="small">
                  {{ getRepairStatusName }}
                  <v-icon v-if="!readonly" class="d-none d-md-block ml-1" size="small" end>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="() => updateStatus(item.value)"
                  v-for="(item, index) in statusList"
                  :key="index"
                  density="compact"
                >
                  <v-list-item-title class="text-uppercase" :class="RepairHelper.getRepairStatusColor(item.value, true)">{{
                    item.text
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div v-if="props.originRepair?.status === RepairStatus.Completed" class="d-flex align-center mt-2">
          <div class="title">Result</div>
          <RepairResultSelect :repair="props.repair" :readonly="true" />
        </div>
        <div class="d-flex mt-2 align-center">
          <div class="title">Location</div>
          <v-select
            :model-value="props.repair?.location"
            @update:model-value="updateLocation"
            :items="locations"
            density="compact"
            max-width="100%"
            placeholder="Choose"
            class="compact-select"
            :error="!!errors.location"
            :variant="props.readonly ? 'plain' : 'underlined'"
            :readonly="props.readonly"
            :menu-icon="props.readonly ? '' : 'mdi-menu-down'"
            hide-details
          >
          </v-select>
        </div>
        <div class="d-flex d-md-block mt-3">
          <div class="title mb-1">Notes</div>
          <div v-if="props.readonly" class="title">{{ props.repair?.notes }}</div>
          <v-textarea
            v-if="!props.readonly"
            color="primary"
            :model-value="props.repair?.notes"
            @update:model-value="updateNotes"
            rows="2"
            auto-grow
            shaped
            class="notes"
            :readonly="props.readonly"
            :variant="props.readonly ? 'plain' : 'outlined'"
            hide-details
          ></v-textarea>
        </div>
      </v-card-text>

      <!-- OTHER (will be moved to popup on mobile) -->
      <div
        :class="{ 'meta-data-wrap mx-0': isMobileAndDown, 'mx-4': !isMobileAndDown }"
        v-click-outside="() => (showOther ? (showOther = false) : undefined)"
      >
        <v-btn
          icon
          variant="text"
          color="primary"
          class="d-block d-md-none"
          @click="showOther = !showOther"
          density="compact"
        >
          <v-icon size="28">mdi-dots-vertical</v-icon>
        </v-btn>
        <div class="d-none d-md-block" :class="{ 'show-other v-card v-sheet v-theme--dark pa-4': showOther }">
          <div>
            <div class="d-flex meta-data">
              <span class="pr-2 text-disabled meta-data-title">Device ID:</span>
              <span>{{ device?.deviceId }}</span>
            </div>
            <div class="d-flex meta-data">
              <span class="pr-2 text-disabled meta-data-title">Created:</span>
              <span>{{ `${moment(repair?.createdAt).format("YYYY-MM-DD HH:mm")} (${repair?.createdBy})` }}</span>
            </div>

            <div v-if="repair?.lastModifiedBy" @click="showHistoryModal" class="d-flex meta-data cursor-pointer">
              <span class="pr-2 text-disabled meta-data-title">Updated:</span>
              <span class="text-decoration-underline">{{
                `${moment(repair?.lastModifiedAt).format("YYYY-MM-DD HH:mm")} (${repair?.lastModifiedBy})`
              }}</span>
            </div>
          </div>
          <div class="text-right">
            <v-btn
              v-if="canDeleteRepair && isDeleteAvailable"
              @click="deleteRepairConfirm"
              :loading="deleting"
              :disabled="deleting"
              variant="text"
              size="small"
              color="red"
              class="mt-4"
            >
              Delete repair
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <RepairHistory :show="showHistory" :repair="props.repair" @close="showHistory = false" />
    <v-overlay
      contained
      :model-value="deleting"
      opacity="0.46"
      z-index="109999"
      persistent
      class="d-flex align-center justify-center"
    >
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
  </div>
</template>

<script setup lang="ts">
import RepairHelper from "@/helpers/repairHelper";
import { RepairStatus } from "@/types/RepairStatus";
import { UserPermissionType } from "@/types/UserPermissionType";
import userProfileService from "@/services/UserProfileService";
import { computed, ref, onMounted } from "vue";
import Device from "@/types/Device";
import locationResource from "@/resources/LocationResource";
import RepairHistory from "@/components/service/RepairHistory.vue";
import RepairResultSelect from "@/components/service/RepairResultSelect.vue";
import Repair from "@/types/Repair";
import moment from "moment";
import { useConfirm } from "@/services/ConfirmService";
import repairResource from "@/resources/RepairResource";
import RepairItem from "@/types/RepairItem";
import { useDisplay } from "vuetify";

const confirm = useConfirm();

const emit = defineEmits(["delete"]);

const props = withDefaults(
  defineProps<{
    repair?: Repair | null;
    originRepair?: RepairItem | null;
    device?: Device | null;
    show?: boolean;
    errors?: { location: string };
    readonly: boolean;
  }>(),
  {
    device: null,
    repair: null,
    originRepair: null,
    show: false,
    errors: () => ({ location: "" }),
    readonly: false,
  }
);

const isMobileAndDown = useDisplay().smAndDown;

const showHistory = ref(false);
const locations = ref<string[]>([]);
const deleting = ref(false);
const showOther = ref(false);

const getRepairStatusName = computed(() => {
  return RepairHelper.getRepairStatusDisplayName(props.repair?.status);
});

const statusColorAsText = computed(() => {
  return RepairHelper.getRepairStatusColor(props.repair?.status, true);
});

const statusColor = computed(() => {
  return RepairHelper.getRepairStatusColor(props.repair?.status);
});

const statusList = computed(() => {
  return Object.values(RepairStatus)
    .filter((v) => !isNaN(Number(v)))
    .map((v) => ({
      text: RepairHelper.getRepairStatusDisplayName(v as number),
      value: v as number,
    }))
    .filter(({ value }) => value !== props.repair?.status);
});

const canDeleteRepair = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.DeleteRepairs) && props.repair?.repairId;
});
const isDeleteAvailable = computed(() => {
  return props.repair?.status !== RepairStatus.Completed;
});

const getLocations = async () => {
  locations.value = await locationResource.getLocations();
};

onMounted(() => {
  getLocations();
});

const updateStatus = (item: RepairStatus) => {
  if (props.repair) {
    props.repair.status = item;
  }
};

const updateLocation = (location: string) => {
  if (props.repair) {
    props.repair.location = location;
  }
  props.errors.location = "";
};

const updateNotes = (notes: string) => {
  if (props.repair) {
    props.repair.notes = notes;
  }
};

const deleteRepairConfirm = () => {
  if (!canDeleteRepair.value || props.repair == null) {
    return;
  }

  confirm.show(`Delete repair ID ${props.repair.repairId}?`).then((confirmed) => {
    if (confirmed) {
      deleteRepair();
    }
  });
};

const deleteRepair = () => {
  if (!canDeleteRepair.value || !props.repair?.repairId) {
    return;
  }

  deleting.value = true;

  repairResource
    .deleteRepair(props.repair.repairId)
    .then(() => {
      emit("delete");
    })
    .catch(repairResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const showHistoryModal = () => {
  showOther.value = false;
  showHistory.value = true;
};
</script>

<style scoped>
.title {
  width: 100px;
  font-size: 13px;
}

.status {
  width: 125px;
}

.notes * {
  font-size: 13px;
}

.meta-data {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.meta-data-wrap {
  margin-top: -45px;
  position: relative;
}

.meta-data-title {
  width: 70px;
  margin-bottom: 5px;
}

.show-other {
  position: absolute;
  right: 0.75rem;
  display: block !important;
  z-index: 1;
  background-color: rgb(var(--v-theme-darkgrey-darken1));
}
</style>
