<template>
  <v-container class="card-container">
    <v-card class="card-content" variant="text">
      <v-card-text class="text-center">
        <v-icon size="160" class="">mdi-file-download-outline</v-icon>
        <div class="mt-6">Click the button below to download the file.</div>
        <div class="mt-4">
          <v-btn color="primary" @click="download">Download</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import commonHelper from "@/helpers/commonHelper";
import { useRoute } from "vue-router";

const attachmentId = Number(useRoute().params.id);

const download = () => {
  if (!attachmentId) return;

  const a = document.createElement("a");
  a.href = `${commonHelper.apiHost}/download/attachment/${attachmentId}`;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
</script>

<style scoped>
.card-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
