import ResourceBase from "@/resources/ResourceBase";
import Poi from "@/types/Poi";
import SimplePoi from "@/types/SimplePoi";
import GenericResponse from "@/types/GenericResponse";
import PagedData from "@/types/PagedData";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { PoiType } from "@/types/PoiType";
import EntityChangeRecord from "@/types/EntityChangeRecord";

class PoiResource extends ResourceBase {
    getPoiPaged(
        itemsPerPage: number,
        pageNumber: number,
        isActive: boolean | undefined,
        typeOf: PoiType[] | undefined,
        searchTerm: string | undefined,
        orderBy: string | undefined = undefined,
        orderDesc: boolean = false,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<PagedData<Poi>>> {
        const config = {
            params: {
                itemsPerPage: itemsPerPage,
                pageNumber: pageNumber,
                isActive: isActive,
                typeOf: typeOf,
                search: searchTerm,
                orderBy: orderBy,
                orderDesc: orderDesc
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<Poi>>("/poi", config);
    }

    getPoi(
        isActive: boolean | undefined,
        typeOf: PoiType[] | undefined,
        searchTerm: string | undefined,
        orderBy: string | undefined = undefined,
        orderDesc: boolean = false,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<Poi[]>> {
        const config = {
            params: {
                isActive: isActive,
                typeOf: typeOf,
                search: searchTerm,
                orderBy: orderBy,
                orderDesc: orderDesc
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        return this.apiClient.get<Poi[]>("/poi/all", config);
    }

    addPoi(poi: Poi, cancelToken: any | undefined = undefined): Promise<AxiosResponse<number>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.post<number>("/poi", poi, config);
    }

    updatePoi(poi: Poi, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/poi/${poi.poiId}`, poi, config);
    }

    deletePoi(poiId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<GenericResponse>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/poi/${poiId}`, config);
    }

    getPoiById(poiId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<Poi>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.get<Poi>(`/poi/${poiId}`, config);
    }

    getPoiChangeHistory(poiId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<EntityChangeRecord[]>> {
        const config = {
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.get<EntityChangeRecord[]>(`/poi/${poiId}/change-history`, config);
    }

    getPoiByLocation(
        latitude: number,
        longitude: number,
        searchRadius: number,
        isActive: boolean | undefined,
        typeOf: PoiType[] | undefined,
        cancelToken: any | undefined = undefined): Promise<AxiosResponse<Poi[]>> {
        
        const config = {
            params: {
                latitude: latitude,
                longitude: longitude,
                searchRadius: searchRadius,
                isActive: isActive,
                typeOf: typeOf,
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.get<Poi[]>("/poi/nearest", config);
    }

    getPoiInBounds(
        neLatitude: number,
        neLongitude: number,
        swLatitude: number,
        swLongitude: number,
        isActive?: boolean ,
        typeOf?: PoiType[],
        limit: number = 1000,
        detailed?: boolean,
        cancelToken?: any): Promise<AxiosResponse<Poi[] | SimplePoi[]>> {
        
        const config = {
            params: {
                neLat: neLatitude,
                neLng: neLongitude,
                swLat: swLatitude,
                swLng: swLongitude,
                isActive,
                typeOf,
                limit,
                detailed
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;
        
        return this.apiClient.get<Poi[]>("/poi/in-bounds", config);
    }
}

const poiResource = new PoiResource();
export default poiResource;
