import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5192ee09"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_overlay, {
      contained: "",
      "model-value": _ctx.loading,
      class: "progress",
      "z-index": "101",
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_progress_linear, {
          indeterminate: "",
          position: "absolute"
        })
      ]),
      _: 1
    }, 8, ["model-value"]),
    _createVNode(_component_v_chart, {
      option: _ctx.option,
      autoresize: "",
      class: _normalizeClass({ 'opacity-50': _ctx.loading })
    }, null, 8, ["option", "class"])
  ]))
}