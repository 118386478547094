<template>
  <v-btn
    class="mr-2 btn"
    :class="btnStyles"
    :color="`${isThemeDark ? 'secondary' : undefined}`"
    :style="{ width: data.title.length * 7 + 45 + 'px' }"
    size="small"
    @click="setActive"
  >
    <span v-if="!isActive">
      <v-icon class="mr-1" size="16">{{ data.icon }}</v-icon>
      <span> {{ data.title }} </span>
    </span>

    <v-combobox
      :loading="loading"
      :modelValue="data.disableMultiple ? data.selected[0] : data.selected"
      @update:modelValue="onInput"
      v-if="isActive"
      :ref="data.filterName"
      :items="suggestedItems"
      item-title="text"
      density="compact"
      :placeholder="data.title"
      class="btn-field"
      hide-details
      :hide-no-data="loading || !isLoaded"
      :multiple="data.disableMultiple ? false : true"
      auto-select-first
      @keyup.space.stop.prevent
      @blur="blur"
      :search-input.sync="search"
      :menu-props="{
        modelValue: isOpenMenu,
        contentClass: isDropListFrozen ? 's-custom-disabled-menu-content-filter' : 's-custom-menu-content-filter',
      }"
      @click:append.stop.prevent="blur"
      color="primary"
      variant="underlined"
    >
      <template v-slot:prepend-inner>
        <v-icon :color="isThemeDark ? 'white' : 'dark darken-1'" center class="ml-1 labe-icon" size="18">
          {{ data.icon }}
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-list-item class="no-data"> {{ `No ${data.title} available` }} </v-list-item>
      </template>
      <template v-slot:selection=""> </template>
      <template v-slot:item="{ item, props }">
        <v-list-item
          :disabled="isDropListFrozen"
          density="compact"
          :ripple="false"
          v-bind="{ ...props, title: undefined }"
          class="list-item"
          :class="{
            'list-item-mobile': breakpoint.xs.value,
            delimiterItem: (data.dividerValues || []).includes(item.value) && !search,
          }"
          active-class="active-item"
        >
          <template v-slot:prepend="{ isActive }">
            <v-list-item-action class="mr-2 my-0">
              <v-checkbox-btn density="compact" hide-details color="primary" :model-value="isActive"></v-checkbox-btn>
            </v-list-item-action>
          </template>
          <v-list-item-title density="compact" class="text-body-2">{{ item.title }}</v-list-item-title>

          <v-divider v-if="(data.dividerValues || []).includes(item.value) && !search" class="delimiter" />
        </v-list-item>
      </template>
      <template v-slot:append-item="">
        <div class="footer">
          <v-divider></v-divider>
          <v-list-item :disabled="isDropListFrozen" :ripple="false" density="compact" @click="blur" class="close-menu-btn">
            <v-list-item-title class="text-body-2">Close</v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-combobox>
  </v-btn>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, toNative, Setup } from "vue-facing-decorator";
import axios, { CancelTokenSource } from "axios";
import TableFilter from "@/types/TableFilter";
import { useDisplay, useTheme } from "vuetify";

@Component({})
class FilterSelect extends Vue {
  search: string | null = null;
  isActive: boolean = false;
  suggestedItems: TableFilter["selected"][] = [];
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  isAnimationCompleted = false;
  isLoaded = false;
  openMenuTimeout = 0;
  componentActive = true;

  activated() {
    this.componentActive = true;
  }
  deactivated() {
    this.componentActive = false;
  }

  @Prop({ default: [] })
  data!: TableFilter;

  @Prop({ default: false })
  isDropListFrozen!: boolean;

  @Setup(() => useDisplay())
  breakpoint = useDisplay();

  @Setup(() => useTheme())
  theme = useTheme();

  get isThemeDark() {
    return this.theme?.global?.current?.value?.dark;
  }
  get btnStyles() {
    return {
      "btn-active": this.isActive,
      "btn-active-mobile": this.breakpoint.xs.value,
      "btn-transition": !this.breakpoint.xs.value,
    };
  }

  get isOpenMenu() {
    return this.componentActive && this.isAnimationCompleted && !this.loading && this.isLoaded;
  }

  @Watch("search")
  onSearchChanged() {
    if (!this.search?.trim() && this.search) {
      (this.$refs[this.data.filterName] as any).internalSearch = "";
      return;
    }

    if (!this.data.searchable) {
      return this.getFastData();
    }

    this.clearSearchTimeout();
    this.loading = true;

    this.searchThrottleTimer = setTimeout(() => this.getData(), 1000);
  }

  async onInput(value: { text: string; value: any }[] | { text: string; value: any }) {
    if (Array.isArray(value)) {
      this.data.selected = value;
    } else {
      if (this.data.selected.find((item) => item.value === value.value)) {
        this.data.selected = [];
        return;
      }

      this.data.selected = value ? [value] : [];
    }
  }
  clearSearchTimeout() {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }
  }

  async getFastData() {
    const searchName = this.search === null ? undefined : this.search;
    const data = await this.data.itemsCallback(searchName, this.cancelToken);

    if (data) {
      this.suggestedItems = data;
      if (!this.isLoaded) this.isLoaded = true;
    }
    if (!data) {
      this.suggestedItems = [];
    }
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(async () => {
      // Timeout is workaround for finally() being executed after request was canceled and new request already began
      this.cancelToken = axios.CancelToken.source();
      const searchName = this.search === null ? undefined : this.search;

      const data = await this.data.itemsCallback(searchName, this.cancelToken);

      if (data) {
        this.suggestedItems = data;
        if (!this.isLoaded) this.isLoaded = true;
      }
      if (!data) {
        this.suggestedItems = [];
      }
      this.loading = false;
      this.cancelToken = undefined;
    }, 10);
  }

  blur() {
    if (this.isDropListFrozen) {
      //@ts-ignore
      if (this.$refs[this.data.filterName]?.focus) {
        //@ts-ignore
        this.$refs[this.data.filterName].focus();
      }
      return;
    }
    if (this.openMenuTimeout) {
      clearTimeout(this.openMenuTimeout);
    }
    this.isAnimationCompleted = false;
    setTimeout(() => (this.isActive = false), 100);
  }

  async setActive() {
    if (this.isActive) return;
    this.isActive = true;
    if (!this.suggestedItems.length) {
      this.getData();
    }

    setTimeout(() => {
      (this.$refs[this.data.filterName] as HTMLElement).click();
      //@ts-ignore
      this.$refs[this.data.filterName].menu = true;
    }, 300);
    this.openMenuTimeout = setTimeout(() => (this.isAnimationCompleted = true), 400);
  }
}

export default toNative(FilterSelect);
</script>

<style scoped>
.btn {
  margin: 5px;
  text-transform: none;
  max-width: 100%;
  overflow: hidden;
  display: flex;
}

.btn:focus:before {
  opacity: 0 !important;
}

.btn-transition {
  transition: all 0.3s ease;
}

.btn-active {
  width: 250px !important;
  max-width: 100%;
  padding: 0 !important;
}

.btn-active :deep(.v-btn__content) {
  width: 250px !important;
  max-width: 100%;
  padding: 0 !important;
  flex: 1;
}

.btn-active-mobile {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.btn-field {
  width: 100% !important;
}

.btn-field :deep(input) {
  width: 100%;
  font-size: 13px !important;
  padding: 0 !important;
  padding-bottom: 4px !important;
}

.btn-field :deep(.v-field) {
  padding-left: 0;
  padding-right: 0;
}

.btn-field:deep(.v-field__append-inner) {
  display: none;
}

.labe-icon {
  margin-bottom: 3px;
}

.no-data {
  font-size: 13px;
  min-height: 20px;
}

.list-item {
  font-size: 14px;
  font-weight: 500;
  max-width: 239px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-item-mobile {
  max-width: 100% !important;
}

.close-menu-btn {
  text-align: right;
  font-size: 13px;
  margin-top: 5px;
  min-height: 40px !important;
  min-width: 150px;
  color: rgb(var(--v-theme-primary)) !important;
  cursor: pointer;
}

:root[theme="light"] .close-menu-btn {
  color: #ffaa03 !important;
  font-weight: 700 !important;
}

.active-item.v-list-item--active:before,
.v-list-item--highlighted:before {
  opacity: 0.07 !important;
}

.footer {
  position: sticky;
  bottom: -8px;
  background: #2d2d2d;
  width: 100%;
}

:root[theme="dark"] .v-menu > .v-overlay__content > .v-card,
.v-menu > .v-overlay__content > .v-sheet,
.v-menu > .v-overlay__content > .v-theme--dark.v-list {
  background: #2d2d2d !important;
}

:root[theme="light"] .footer {
  background: #fff;
}
</style>

<style>
.s-custom-menu-content-filter,
.s-custom-menu-content-filter .v-theme--dark.v-list {
  background: #2d2d2d;
}

:root[theme="light"] .s-custom-menu-content-filter .v-list {
  background: #fff;
}

.delimiter {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: -5px;
}

.delimiterItem {
  position: relative;
  margin-bottom: 10px;
  overflow: visible !important;
}
</style>
