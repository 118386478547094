import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
    class: _normalizeClass(["rich-text-container", _ctx.styles])
  }, [
    (_openBlock(), _createBlock(_component_QuillEditor, {
      key: `${_ctx.showToolbar.toString()}-${_ctx.disabled.toString()}`,
      options: _ctx.editorOption,
      content: _ctx.inputValue,
      "onUpdate:content": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      contentType: "html",
      readOnly: _ctx.disabled,
      class: _normalizeClass(_ctx.styles),
      ref: "quillEditor"
    }, null, 8, ["options", "content", "readOnly", "class"]))
  ], 2)), [
    [_directive_click_outside, _ctx.onBlur]
  ])
}