import ResourceBase from "@/resources/ResourceBase";

class LocationResource extends ResourceBase {
    getLocations(): Promise<string[]> {
        return Promise.resolve(["Namsos", "Trondheim"]);
    }
}

const locationResource = new LocationResource();
export default locationResource;
