<template>
  <v-dialog scrim="rgba(33, 33, 33)" v-if="canAddIssue" v-model="showNewIssueDialog" width="500">
    <template v-slot:activator="{ props }">
      <v-btn size="small" color="primary" class="align-self-end" v-bind="props"> New issue </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary" :height="4"> </v-toolbar>
      <v-card-title> New Issue </v-card-title>
      <v-card-text>
        <IssueSelect
          @create="createIssue"
          @change="onSelectIssue"
          :isLoading="loading"
          hint="Ensure that a similar issue doesn't already exist before you create a new one."
          label="Find existing issue or create a new one"
          :menuProps="{ maxWidth: '455' }"
          @update:title="(v) => (title = v)"
          :noFilter="true"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel"> Cancel </v-btn>
        <v-btn color="primary" :disabled="!title" :loading="loading" @click="createIssue(null)"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Setup } from "vue-facing-decorator";
import { UserPermissionType } from "@/types/UserPermissionType";
import axios, { CancelTokenSource } from "axios";
import issueResource from "@/resources/IssueResource";
import userProfileService from "@/services/UserProfileService";
import Issue from "@/types/Issue";
import IssueSelect from "@/components/common/IssueSelect.vue";
import { useRouter } from "vue-router";

@Component({
  components: { IssueSelect },
})
export default class CreateIssue extends Vue {
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  showNewIssueDialog = false;
  title = "";

  get canAddIssue() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }

  @Setup(useRouter)
  router = useRouter();

  cancel() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    this.showNewIssueDialog = false;
  }

  createIssue(issueName: string | null) {
    const name = issueName || this.title;
    if (!this.canAddIssue || !name) {
      return;
    }
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
    this.loading = true;
    this.cancelToken = axios.CancelToken.source();
    issueResource
      .addIssue(name, undefined, this.cancelToken)
      .then((resp) => {
        const newIssueId = resp.data.issueId;
        this.title = ''
        this.router.push(`/support/issues/${newIssueId}`);
        this.cancel();
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
        this.cancelToken = undefined;
      });
  }

  onSelectIssue(issue: Issue | null) {
    if (!issue) return;
    this.router.push(`/support/issues/${issue.issueId}`);
  }
}
</script>

<style scoped></style>
