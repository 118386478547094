import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-171e1cb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-right"
}
const _hoisted_2 = { class: "d-flex align-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_IssueCurrentDevices = _resolveComponent("IssueCurrentDevices")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_side_sheet = _resolveComponent("side-sheet")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.value)
      ? (_openBlock(), _createBlock(_component_side_sheet, {
          key: 0,
          modelValue: _ctx.showDialog,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
          heading: "Affected devices"
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              disabled: _ctx.loading,
              variant: "text",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDialog = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Close")
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          default: _withCtx(() => [
            ((_ctx.canAddIssues || _ctx.canEditIssues || _ctx.canManageIssueDevices) && _ctx.canViewDevices)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (!_ctx.isMultipleMode)
                    ? (_openBlock(), _createBlock(_component_v_autocomplete, {
                        key: 0,
                        modelValue: _ctx.newSelectedItem,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newSelectedItem) = $event)),
                        items: _ctx.newDeviceItems,
                        loading: _ctx.loading,
                        search: _ctx.newDeviceSearchTerm,
                        "onUpdate:search": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newDeviceSearchTerm) = $event)),
                        clearable: "",
                        "hide-no-data": "",
                        "no-filter": "",
                        label: "Add new affected device",
                        "return-object": "",
                        "item-value": "deviceId",
                        "persistent-hint": "",
                        hint: "Search by device ID, IMEI or name"
                      }, {
                        selection: _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(`${item.raw.deviceId} - ${item.raw.deviceName}`), 1)
                        ]),
                        item: _withCtx(({ props, item }) => [
                          _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps({ ...props, title: '' })), {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(`${item.raw.deviceId} - ${item.raw.deviceName}`), 1)
                            ]),
                            _: 2
                          }, 1040)
                        ]),
                        _: 1
                      }, 8, ["modelValue", "items", "loading", "search"]))
                    : _createCommentVNode("", true),
                  (_ctx.isMultipleMode)
                    ? (_openBlock(), _createBlock(_component_v_textarea, {
                        key: 1,
                        label: "Enter multiple device IDs",
                        hint: "Comma, space or new-line separated device IDs",
                        "persistent-hint": "",
                        "auto-grow": "",
                        rows: "1",
                        modelValue: _ctx.newIDsTherm,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newIDsTherm) = $event)),
                        variant: "underlined"
                      }, null, 8, ["modelValue"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_v_switch, {
                      class: "mr-6 mt-0 new-device-mode-switch",
                      density: "compact",
                      "hide-details": "",
                      label: "Add multiple",
                      modelValue: _ctx.isMultipleMode,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isMultipleMode) = $event))
                    }, null, 8, ["modelValue"]),
                    (_ctx.isMultipleMode)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 0,
                          color: "primary",
                          size: "small",
                          onClick: _ctx.addDevices,
                          disabled: !_ctx.newIDsTherm
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Add ")
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"]))
                      : _createCommentVNode("", true),
                    (!_ctx.isMultipleMode)
                      ? (_openBlock(), _createBlock(_component_v_btn, {
                          key: 1,
                          color: "primary",
                          size: "small",
                          onClick: _ctx.addDevice,
                          disabled: !_ctx.newSelectedItem
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Add ")
                          ]),
                          _: 1
                        }, 8, ["onClick", "disabled"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_IssueCurrentDevices, {
              modelValue: _ctx.issue,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.issue) = $event)),
              forceReload: _ctx.reloadCurrentDevices,
              onRemove: _ctx.removeDevice,
              onChangeStatus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('changeStatus'))),
              statusSummary: _ctx.statusSummary
            }, null, 8, ["modelValue", "forceReload", "onRemove", "statusSummary"])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ]))
}