<template>
  <div>
    <div class="text-subtitle-1 mb-1">Usage (current billing cycle)</div>

    <v-progress-linear v-if="!status && loading" indeterminate></v-progress-linear>

    <table v-if="status" class="text-subtitle-2 mt-4 info-table">
      <tr>
        <td class="text-grey">Data usage:</td>
        <td>{{ (status.ctdDataUsage / 1024).toFixed(2) }} KB</td>
      </tr>
      <tr>
        <td class="text-grey">SMS usage:</td>
        <td>{{ status.ctdSmsUsage }}</td>
      </tr>
      <tr>
        <td class="text-grey">Voice usage:</td>
        <td>{{ status.ctdVoiceUsage }}</td>
      </tr>
      <tr>
        <td class="text-grey">Session count:</td>
        <td>{{ status.ctdSessionCount || "N/A" }}</td>
      </tr>
      <tr>
        <td class="text-grey">Data limit reached rate plan:</td>
        <td>{{ status.overageLimitReached }}</td>
      </tr>
    </table>

    <div class="mt-4">
      <v-btn :disabled="loading" color="secondary" class="mr-4" @click="refresh"
        >Refresh</v-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch , toNative } from "vue-facing-decorator";
import moment from "moment";
import deviceResource from "@/resources/DeviceResource";
import MobileNetworkUsageType from "@/types/MobileNetworkUsageType";

@Component({
  components: {},
})
class MobileNetworkUsage extends Vue {
  moment = moment;

  @Prop()
  readonly deviceId!: number | null;

  loading = false;
  status: MobileNetworkUsageType | null = null;

  @Watch("deviceId", { immediate: true })
  onValueChanged() {
    this.getData();
  }

  getData() {
    if (!this.deviceId) {
      return;
    }

    this.loading = true;

    deviceResource
      .getMobileNetworkUsage(this.deviceId)
      .then((resp) => {
        this.status = resp.data;
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  refresh() {
    this.status = null;
    this.getData();
  }
}

export default toNative(MobileNetworkUsage)
</script>

<style scoped>
.info-table td:first-child {
  padding-right: 2rem;
}
</style>
