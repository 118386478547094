<template></template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import authResource from "@/resources/AuthResource";
import authService from "@/services/AuthService";

@Component({
  components: {},
})
class AuthRefresh extends Vue {
  interval: number = 0;

  mounted() {
    this.interval = setInterval(this.refreshToken.bind(this), 1000 * 60 * 5); // every 5 minutes
  }

  unmounted() {
    clearInterval(this.interval);
    this.interval = 0;
  }

  refreshToken() {
    if (!authService.authInfo.refreshAfter) {
      return;
    }

    // Auth token refresh
    var now = Date.now();
    if (authService.authInfo.refreshAfter < now) {
      authResource
        .refreshToken()
        .then((resp) => {
          authService.setToken(resp.data.token, resp.data.expires);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
}

export default toNative(AuthRefresh)
</script>

<style scoped></style>
