import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", null, [
      _createElementVNode("input", {
        class: "d-none",
        type: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChanged && _ctx.onFileChanged(...args))),
        multiple: ""
      }, null, 32),
      (_ctx.small)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onDrop: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onDrop($event)), ["prevent"])),
            onDragover: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.dragover = true), ["prevent"])),
            onDragenter: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.dragover = true), ["prevent"])),
            onDragleave: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.dragover = false), ["prevent"])),
            class: "small-upload mt-3"
          }, [
            _createVNode(_component_v_icon, {
              color: "#f9be0b",
              elevation: "25",
              class: "mr-1 mb-1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-attachment ")
              ]),
              _: 1
            }),
            _createTextVNode(" Attach file ")
          ], 32))
        : (_openBlock(), _createBlock(_component_v_card, {
            key: 1,
            onDrop: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.onDrop($event)), ["prevent"])),
            onDragover: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.dragover = true), ["prevent"])),
            onDragenter: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.dragover = true), ["prevent"])),
            onDragleave: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.dragover = false), ["prevent"])),
            class: _normalizeClass({
          'mr-4 mt-4 preview-card active-card': true,
          drag: _ctx.dragover,
        }),
            "max-width": "100"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                color: _ctx.dragover ? '#fedd10' : '#8e8f90',
                size: "40"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-file-plus")
                ]),
                _: 1
              }, 8, ["color"])
            ]),
            _: 1
          }, 8, ["class"]))
    ])
  ]))
}