<template>
  <form @submit.prevent="updatePassword" class="form-auth" method="post">
    <h3 class="form-auth-heading">
      <img src="/favicon.png" alt="Safedrive" />Safedrive
    </h3>
    <p>Please update your password.</p>
    <p class="mb-1">New password must be:</p>
    <ul class="mt-1 mb-4">
      <li>at least 8 characters long</li>
      <li>at least one upper case character</li>
      <li>at least one lower case character</li>
      <li>at least one digit</li>
      <li>
        at least one special character, or total password length more that 12
        characters
      </li>
    </ul>
    <div class="input-field">
      <v-text-field
        v-model="currentPassword"
        type="password"
        label="Current password"
        required
        autocomplete="off"
      />
    </div>
    <div class="input-field">
      <v-text-field
        v-model="newPassword"
        type="password"
        label="New password"
        required
        autocomplete="off"
      />
    </div>
    <div class="input-field">
      <v-text-field
        v-model="passwordRepeat"
        type="password"
        label="Repeat new password"
        required
        autocomplete="off"
      />
    </div>

    <v-btn
      class="submit-button"
      type="submit"
      :loading="loading"
      :disabled="isSubmitDisable"
    >
      Continue
    </v-btn>
  </form>
</template>

<script lang="ts">
import { Component, Vue, toNative } from "vue-facing-decorator";
import authService from "@/services/AuthService";
import authResource from "@/resources/AuthResource";

@Component({
  name: "PasswordUpdate",
  components: {},
})
class PasswordUpdate extends Vue {
  loading = false;
  currentPassword = "";
  newPassword = "";
  passwordRepeat = "";

  get isSubmitDisable() {
    return (
      this.loading ||
      !this.passwordRepeat ||
      !this.newPassword ||
      !this.currentPassword
    );
  }

  updatePassword() {
    this.loading = true;

    authResource
      .updatePassword(
        this.currentPassword,
        this.newPassword,
        this.passwordRepeat
      )
      .then(() => {
        authService.setRequirePasswordUpdate(false);
        authService.redirect();
      })
      .catch(authResource.defaultErrorHandler)
      .finally(() => (this.loading = false));
  }
}

export default toNative(PasswordUpdate)
</script>

<style scoped>
.link-btn {
  color: #bebebe;
  text-decoration: underline;
}
ul li {
  list-style: disc !important;
  list-style-position: outside !important;
  margin-left: 1rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
</style>
