import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_PropEditor = _resolveComponent("PropEditor")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-block d-sm-flex" }, {
            default: _withCtx(() => [
              _createTextVNode(" Global advert settings ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(" The settings below are global and affect all ads and all devices. ")
            ]),
            _: 1
          }),
          (_ctx.settings)
            ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_sheet, { "max-width": "500" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PropEditor, {
                        name: "Message of the day",
                        desc: "ID of the advert that will be used as the message of the day.",
                        titleSize: "large"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.settings.messageOfTheDayId,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settings.messageOfTheDayId) = $event)),
                            density: "compact",
                            variant: "outlined",
                            width: 150
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_PropEditor, {
                        name: "Maximum adverts per day",
                        desc: "Sets the maximum number of adverts each device can display per day.",
                        titleSize: "large"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.settings.maxAdvertsPerDay,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings.maxAdvertsPerDay) = $event)),
                            density: "compact",
                            variant: "outlined",
                            width: 150
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_PropEditor, {
                        name: "Minimum time between adverts",
                        desc: "Sets the minimum number of minutes between adverts.",
                        titleSize: "large"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.settings.minTimeBetweenAdverts,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.settings.minTimeBetweenAdverts) = $event)),
                            density: "compact",
                            variant: "outlined",
                            width: 150
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        class: "mt-8",
                        onClick: _ctx.saveSettings,
                        loading: _ctx.loading,
                        disabled: _ctx.loading
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Save settings ")
                        ]),
                        _: 1
                      }, 8, ["onClick", "loading", "disabled"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}