import { MultiFactorAuthType } from "@/types/MultiFactorAuthType";

export default class UserHelper {
    static getMfaTypeDisplayName(type: MultiFactorAuthType): string {
        switch (type) {
            case MultiFactorAuthType.None: return "Disabled";
            case MultiFactorAuthType.Email: return "Email";
            case MultiFactorAuthType.Sms: return "SMS";
            default: return type;
        }
    }
}