import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-149d88a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-messages-container" }
const _hoisted_2 = { class: "text-caption px-1 py-0 info-messages-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoMsg.state.messages, (alert, index) => {
        return (_openBlock(), _createBlock(_component_v_alert, {
          key: index,
          closable: "",
          border: "start",
          type: alert.type
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(alert.message), 1)
          ]),
          _: 2
        }, 1032, ["type"]))
      }), 128))
    ])
  ]))
}