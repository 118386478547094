import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_IssueSelect = _resolveComponent("IssueSelect")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_ctx.canAddIssue)
    ? (_openBlock(), _createBlock(_component_v_dialog, {
        key: 0,
        scrim: "rgba(33, 33, 33)",
        modelValue: _ctx.showNewIssueDialog,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showNewIssueDialog) = $event)),
        width: "500"
      }, {
        activator: _withCtx(({ props }) => [
          _createVNode(_component_v_btn, _mergeProps({
            size: "small",
            color: "primary",
            class: "align-self-end"
          }, props), {
            default: _withCtx(() => [
              _createTextVNode(" New issue ")
            ]),
            _: 2
          }, 1040)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar, {
                flat: "",
                color: "primary",
                height: 4
              }),
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(" New Issue ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_IssueSelect, {
                    onCreate: _ctx.createIssue,
                    onChange: _ctx.onSelectIssue,
                    isLoading: _ctx.loading,
                    hint: "Ensure that a similar issue doesn't already exist before you create a new one.",
                    label: "Find existing issue or create a new one",
                    menuProps: { maxWidth: '455' },
                    "onUpdate:title": _cache[0] || (_cache[0] = (v) => (_ctx.title = v)),
                    noFilter: true
                  }, null, 8, ["onCreate", "onChange", "isLoading"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    text: "",
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Cancel ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    color: "primary",
                    disabled: !_ctx.title,
                    loading: _ctx.loading,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createIssue(null)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Create ")
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}