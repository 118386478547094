import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_ctx.canViewLogs)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        fluid: "",
        class: "fill-height"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { style: {"height":"100%","margin":"0"} }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "12",
                md: "12",
                style: {"flex":"1 1 auto","padding":"0"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("iframe", {
                    src: `${_ctx.host}/kibana/app/dashboards#/view/${_ctx.dashboardId}?embed=true&isDark=false&show-query-input=true&show-time-filter=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))&_a=(expandedPanelId:'${_ctx.panelId}',filters:!(),query:(language:kuery,query:'${_ctx.queryParams}'))`,
                    style: {"width":"100%","min-height":"100%"},
                    frameborder: "0"
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}