import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-right mt-2 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IssueSelect = _resolveComponent("IssueSelect")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.canAddIssue || _ctx.canManageIssueDevices)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_component_IssueSelect, {
          key: _ctx.issueSelectKey,
          onCreate: _ctx.addNewIssue,
          onChange: _cache[0] || (_cache[0] = v => _ctx.selectedIssue = v),
          isLoading: _ctx.creating || _ctx.issueUpdating,
          hint: "Select an existing issue or create a new issue and automatically connect this device to the issue.",
          label: "Search existing or create new issue",
          menuProps: { maxWidth: '770' },
          "onUpdate:title": _cache[1] || (_cache[1] = (v) => (_ctx.title = v)),
          noFilter: true
        }, null, 8, ["onCreate", "isLoading"])),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.canAddIssue || _ctx.canManageIssueDevices)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                size: "small",
                color: "primary",
                disabled: !_ctx.selectedIssue,
                loading: _ctx.issueUpdating,
                class: "align-self-end",
                onClick: _ctx.addDeviceToIssue
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Confirm ")
                ]),
                _: 1
              }, 8, ["disabled", "loading", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}