<template>
  <div v-if="canAddIssue || canManageIssueDevices">
    <IssueSelect
      :key="issueSelectKey"
      @create="addNewIssue"
      @change="v => selectedIssue = v"
      :isLoading="creating || issueUpdating"
      hint="Select an existing issue or create a new issue and automatically connect this device to the issue."
      label="Search existing or create new issue"
      :menuProps="{ maxWidth: '770' }"
       @update:title="(v) => (title = v)"
      :noFilter="true"
    />

    <div class="text-right mt-2 mb-2">
      <v-btn
        v-if="canAddIssue || canManageIssueDevices"
        size="small"
        color="primary"
        :disabled="!selectedIssue"
        :loading="issueUpdating"
        class="align-self-end"
        @click="addDeviceToIssue"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop , toNative } from "vue-facing-decorator";
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import IssueSelect from "@/components/common/IssueSelect.vue";

@Component({ components: { IssueSelect }, emits: ["update"] })
class AddDeviceIssue extends Vue {
  @Prop()
  readonly deviceId!: number | null;

  issueSelectKey = new Date().getTime()
  title = "";
  selectedIssue: Issue | null = null;
  loading = false;
  creating = false;
  issueUpdating = false;

  get canAddIssue() {
    return userProfileService.hasPermission(UserPermissionType.AddIssues);
  }

  get canManageIssueDevices() {
    return userProfileService.hasPermission(UserPermissionType.EditIssues) || userProfileService.hasPermission(UserPermissionType.ManageIssueDevices);
  }

  addDeviceToIssue() {
    if (!(this.canAddIssue || this.canManageIssueDevices) || !this.selectedIssue) {
      return;
    }
    this.issueUpdating = true;
    if (!this.deviceId) return;
    issueResource
      .addDevice(this.selectedIssue.issueId, this.deviceId)
      .then(() => {
        this.issueSelectKey = new Date().getTime()
        this.$emit('update')
        this.selectedIssue = null
         this.title = ""
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.issueUpdating = false;
      });
  }

  addNewIssue() {
    if (!this.canAddIssue || !this.title) {
      return;
    }
    this.creating = true;
    if (!this.deviceId) return;
    issueResource
      .addIssue(this.title, this.deviceId ? [this.deviceId] : undefined)
      .then(() => {
        infoMessageService.show(InfoMessageType.Success, `New Issue with name ${this.title} created`);
        this.$emit("update");
        this.issueSelectKey = new Date().getTime()
        this.selectedIssue = null
         this.title = ""
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        this.creating = false;
      });
  }
}

export default toNative(AddDeviceIssue)
</script>

<style scoped></style>
