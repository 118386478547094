import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import { registerGlobalProperties } from "@/globalProperties";
import { Loader } from "@googlemaps/js-api-loader";
import Confirm from "@/services/ConfirmService";
import "./assets/css/shared.css";
import "./assets/css/theme.css";
// Leaflet is not installed by the package.json to fix
//  the error described in this link:https://github.com/vue-leaflet/Vue2Leaflet/issues/314
import "leaflet/dist/leaflet.css";

const app = createApp(App);

// Google Maps
const loader = new Loader({
    apiKey: process.env?.VUE_APP_GOOGLE_API_KEY,
    version: "weekly",
    language: "no", // NB! AreaInfo suggestions depend on language set to "no"
});
loader.load();

app.use(router);
app.use(Confirm);
app.use(vuetify);

registerGlobalProperties(app, router);
app.mount("#app");
