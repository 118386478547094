import { nextTick } from "vue";
import { createWebHistory, createRouter, RouteLocationNormalized } from "vue-router";
import AppLayout from "@/layout/AppLayout.vue";
import AuthLayout from "@/layout/AuthLayout.vue";
import Dashboard from "@/views/Dashboard.vue";

import Customers from "@/views/Customers.vue";
import DeviceList from "@/views/DeviceList.vue";
import DeviceLocationHistory from "@/views/DeviceLocationHistory.vue";
import DeviceLogs from "@/views/DeviceLogs.vue";
import PoiList from "@/views/PoiList.vue";
import PoiMap from "@/views/PoiMap.vue";
import Issues from "@/views/Issues.vue";
import Issue from "@/views/Issue.vue";
import Adverts from "@/views/Adverts.vue";
import AdvertSettingsView from "@/views/AdvertSettingsView.vue";
import Users from "@/views/Users.vue";
import UserGroups from "@/views/UserGroups.vue";
import ApiKeys from "@/views/ApiKeys.vue";
import GeohashMap from "@/views/GeohashMap.vue";
import Login from "@/views/Login.vue";
import Verification from "@/views/Verification.vue";
import PasswordUpdate from "@/views/PasswordUpdate.vue";
import authService from "@/services/AuthService";
import DeviceMessageCoding from "@/views/DeviceMessageCoding.vue";
import WhatIsNew from "@/views/WhatIsNew.vue";
import DownloadView from "@/views/DownloadView.vue";
import ChangeManager from "@/services/ChangeManager";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: "/auth",
            component: AuthLayout,
            redirect: { name: "Login" },
            children: [
                {
                    name: "Login",
                    path: "/login",
                    component: Login,
                    beforeEnter: (to, from, next) => (!authService.isSignedIn ? next() : next("/")),
                },
                {
                    name: "Verification",
                    path: "/verification",
                    component: Verification,
                    beforeEnter: (to, from, next) => (!authService.isSignedIn ? next() : next("/")),
                },
                {
                    name: "Update password",
                    path: "/password-update",
                    component: PasswordUpdate,
                },
            ],
        },
        {
            path: "/",
            component: AppLayout,
            beforeEnter: (to, from, next) => {
                if (!authService.isSignedIn) {
                    authService.setRedirectUrl(to.path);
                    return next({ name: "Login" });
                } else {
                    return next();
                }
            },
            redirect: { name: "Dashboard" },
            children: [
                {
                    name: "Dashboard",
                    path: "/dashboard",
                    component: Dashboard,
                },
                {
                    name: "Customers",
                    path: "/customers",
                    component: Customers,
                    props: (route) => ({
                        initFilter: getQueryAsObject(route, "filter") || {},
                        initData: {
                            customerId: getQueryAsObject(route, "customerId"),
                            customerTab: getQueryAsObject(route, "customerTab"),
                            deviceId: getQueryAsObject(route, "deviceId"),
                            deviceTab: getQueryAsObject(route, "deviceTab"),
                        },
                    }),
                },
                {
                    path: "/devices",
                    component: AppLayout,
                    redirect: { path: "/devices/list" },
                    children: [],
                },
                {
                    name: "Device List",
                    path: "/devices/list",
                    component: DeviceList,
                    props: (route) => ({
                        initFilter: getQueryAsObject(route, "filter") || {},
                        initData: {
                            customerId: getQueryAsObject(route, "customerId"),
                            customerTab: getQueryAsObject(route, "customerTab"),
                            deviceId: getQueryAsObject(route, "deviceId"),
                            deviceTab: getQueryAsObject(route, "deviceTab"),
                        },
                    }),
                },
                {
                    name: "Device Location History",
                    path: "/tools/location-history",
                    component: DeviceLocationHistory,
                },
                {
                    name: "Device Logs",
                    path: "/devices/logs",
                    component: DeviceLogs,
                },
                {
                    name: "POI List",
                    path: "/poi/list",
                    component: PoiList,
                    props: (route) => ({
                        initData: {
                            poiTab: getQueryAsObject(route, "poiTab"),
                            poiId: getQueryAsObject(route, "poiId"),
                        },
                    }),
                },
                {
                    name: "POI Map",
                    path: "/poi/map",
                    component: PoiMap,
                    props: (route) => ({
                        initData: {
                            poiTab: getQueryAsObject(route, "poiTab"),
                            poiId: getQueryAsObject(route, "poiId"),
                        },
                    }),
                },

                {
                    name: "Issues",
                    path: "/support/issues",
                    component: Issues,
                    props: (route) => ({
                        initFilter: getQueryAsObject(route, "filter") || {},
                    }),
                },
                {
                    name: "Issue",
                    path: "/support/issues/:id",
                    component: Issue,
                },
                {
                    name: "Adverts",
                    path: "/advertisement/adverts",
                    component: Adverts,
                    props: (route) => ({
                        initData: {
                            advertId: getQueryAsObject(route, "advertId"),
                            advertTab: getQueryAsObject(route, "advertTab"),
                        },
                    }),
                },
                {
                    name: "Advert Settings",
                    path: "/advertisement/settings",
                    component: AdvertSettingsView,
                },
                {
                    name: "Users",
                    path: "/admin/users",
                    component: Users,
                    props: (route) => ({
                        initData: {
                            userId: getQueryAsObject(route, "userId"),
                            userGroupId: getQueryAsObject(route, "userGroupId"),
                            userTab: getQueryAsObject(route, "userTab"),
                        },
                    }),
                },
                {
                    name: "User Groups",
                    path: "/admin/user-groups",
                    component: UserGroups,
                    props: (route) => ({
                        initData: {
                            userGroupId: getQueryAsObject(route, "userGroupId"),
                        },
                    }),
                },
                {
                    name: "API Keys",
                    path: "/admin/api-keys",
                    component: ApiKeys,
                    props: (route) => ({
                        initData: {
                            apiKeyId: getQueryAsObject(route, "apiKeyId"),
                            apiKeyTab: getQueryAsObject(route, "apiKeyTab"),
                        },
                    }),
                },
                {
                    name: "Geohash Map",
                    path: "/tools/geohash-map",
                    component: GeohashMap,
                },
                {
                    name: "Device message decoder/encoder",
                    path: "/tools/device-message-coding",
                    component: DeviceMessageCoding,
                },
                {
                    name: "What's new",
                    path: "/whats-new",
                    component: WhatIsNew,
                },
                {
                    name: "Download",
                    path: "/download/attachment/:id",
                    component: DownloadView,
                },
            ],
        },
    ],
});

const DEFAULT_TITLE = "Safedrive Studio";
router.afterEach((to) => {
    nextTick(() => {
        document.title = to.name?.toString() + " | " + DEFAULT_TITLE;
    });
});

router.beforeEach((to, from, next) => {
    if (to.path !== from.path) {
        if (ChangeManager.isBlockedTransition) {
            ChangeManager.show(to);
            next(false);
        } else {
            ChangeManager.clear();
            next();
        }
        return;
    }
    next();
});

function getQueryAsObject(route: RouteLocationNormalized, key: string) {
    return typeof route.query[key] === "string" ? JSON.parse(decodeURIComponent(route.query[key] as string)) : null;
}

export const routerPush = (location: RouteLocationNormalized) => router.push(location);

export default router;
