<template>
  <v-dialog scrim="rgba(33, 33, 33)" v-model="showDialog" width="1700" @keydown.esc="showDialog = false" scrollable>
    <template v-slot:activator="{ props }">
      <v-btn variant="text" class="px-0 pr-0" size="small" v-bind="props">
        <v-icon>mdi-chart-box-outline</v-icon>
        <span class="stats-label">Device stats</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary" :height="4"> </v-toolbar>
      <v-card-title class="d-flex mt-2">
        Device statistics
        <v-spacer></v-spacer>
        <v-btn icon size="small" class="ma-2"  variant="text" density="compact" @click="showDialog = false">
          <v-icon size="large"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="dialog-content">
          <v-row v-if="!loading && !isError && totalDevicesAnalysed">
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="pa-md-6 pa-3"
              v-for="([name, value], ind) in Object.entries(data)"
              :key="ind"
            >
              <div class="text-subtitle-1 mb-2">{{ name }}</div>
              <div v-for="([title, quantity], ind) in Object.entries(value)" :key="ind" class="d-flex mt-2 string">
                <div class="flex-grow-1 text-caption text-truncate">{{ title }}</div>
                <div class="text-caption mx-1 quantity">{{ quantity }}</div>
                <div class="text-caption mx-1 quantity">{{ getPercent(quantity) }}%</div>
                <div class="percent-bar" :style="{ width: `${getPercent(quantity)}%` }" />
              </div>
              <div v-if="getUnknownValue(value)" class="d-flex mt-2 string">
                <div class="flex-grow-1 text-caption text-truncate">Unknown</div>
                <div class="text-caption mx-1 quantity">{{ getUnknownValue(value) }}</div>
                <div class="text-caption mx-1 quantity">{{ getPercent(getUnknownValue(value)) }}%</div>
                <div class="percent-bar" :style="{ width: `${getPercent(getUnknownValue(value))}%` }" />
              </div>
            </v-col>
          </v-row>

          <div v-if="!loading && !isError && !totalDevicesAnalysed" class="d-flex w-100 justify-center text-h6">
            No data available
          </div>

          <div class="progress" v-if="loading">
            <v-progress-linear class="w-50" indeterminate color="primary" />
          </div>
          <div v-if="isError" class="text-red d-flex w-100 justify-center text-h6">An error has occurred.</div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch , toNative } from "vue-facing-decorator";
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";

@Component({ components: {} })
export default class DeviceStats extends Vue {
  showDialog = false;
  cancelToken: CancelTokenSource | undefined = undefined;
  loading: boolean = false;
  data = {};
  totalDevicesAffected: number = 0;
  totalDevicesAnalysed: number = 0;
  isError = false;

  @Prop({ default: null })
  readonly issue!: Issue;

  @Watch("showDialog")
  onChangeShowDialog() {
    if (this.showDialog) {
      return this.getData();
    }

    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
  }

  getUnknownValue(value: { [key: string]: number }) {
    return this.totalDevicesAffected - Object.values(value).reduce((acc, item) => (acc += item), 0);
  }

  getPercent(quantity: number) {
    if (!this.totalDevicesAffected) {
      return 0;
    }

    return Math.round((quantity / this.totalDevicesAffected) * 100 * 10) / 10;
  }

  getData() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    this.loading = true;
    this.isError = false;
    setTimeout(async () => {
      // Timeout is workaround for finally() being executed after request was canceled and new request already began
      this.cancelToken = axios.CancelToken.source();

      if (!this.issue.issueId) return;
      issueResource
        .getDeviceStats(this.issue.issueId, this.cancelToken)
        .then((resp) => {
          this.data = resp.data.aggr;
          this.totalDevicesAffected = resp.data.totalDevicesAffected;
          this.totalDevicesAnalysed = resp.data.totalDevicesAnalysed;
        })
        .catch((e) => {
          issueResource.defaultErrorHandler(e);
          this.isError = true;
        })
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
}
</script>

<style scoped>
.quantity {
  min-width: 50px;
  text-align: right;
}
.string {
  border-bottom: 1px solid rgba(175,175,175,.15);
  position: relative;
}
.percent-bar {
  position: absolute;
  bottom: -1px;
  left: 0;
  background: rgb(var(--v-theme-secondary));
  height: 1px;
}
.v-theme--dark .percent-bar {
  background: rgb(var(--v-theme-primary));
}
.progress {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stats-label {
  margin-left: 2px;
  text-transform: none;
  font-size: 14px;
}
.dialog-content {
  background: #fbfbfb;
  height: 100%;
  overflow: auto;
  box-shadow: inset 0px 2px 6px rgba(0,0,0,.1);
}
.v-theme--dark .dialog-content {
  background: #1c1c1c;
}
</style>
