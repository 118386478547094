// Types
import type { App } from "vue";
import moment from "moment";
import { Router, LocationQuery } from "vue-router";
import userStorage from "@/services/UserStorageService";
import { nextTick } from "vue";

let isActiveSetQueryFunction = false;
let query: LocationQuery = {};
export function registerGlobalProperties(app: App, router: Router) {
    // Momment
    app.config.globalProperties.moment = moment;
    moment.locale("en-gb");

    // Router
    app.config.globalProperties.$setComponentQuery = async function (key: string, value: any) {
        if (!isActiveSetQueryFunction) {
            query = { ...this.$route.query };
        }

        if (value) {
            var newVal = encodeURIComponent(JSON.stringify(value));
            if (newVal !== query[key]) {
                query[key] = newVal;
            }
        } else if (query[key]) {
            delete query[key];
        }

        if (!isActiveSetQueryFunction) {
            isActiveSetQueryFunction = true;

            await nextTick();

            if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
                const res = await this.$router.replace({ query });
            }

            isActiveSetQueryFunction = false;
            query = {};
        }
    };

    updateOptionsStorageKey();
}

// Remove it after December 2024.
function updateOptionsStorageKey() {
    ["advertsTable", "customersTable", "devicesTable", "issuesTable", "poiTable", "userGroupsTable", "usersTable"].forEach(
        (key) => {
            const options = userStorage.get(key);
            if (!options) return;
            const sortBy = options.sortBy;
            if (sortBy?.[0]?.key) return;
            if (sortBy) {
                userStorage.set(key, { ...options, sortBy: [{ key: sortBy[0], order: options?.sortDesc[0] ?? true }] });
            }
        }
    );
}
