<script setup lang="ts">
import { computed, PropType } from "vue";

const props = defineProps({
  itemsLength: {
    type: Number,
    default: 0,
  },
  itemsPerPage: {
    type: Number,
    default: 15,
  },
  page: {
    type: Number,
    default: 1,
  },
  items: { type: Array, required: true },
  itemsPerPageText: {
    type: String,
    default: "Rows per page:",
  },
  pageText: {
    type: String,
    default: "",
  },
  firstPageLabel: {
    type: String,
    default: "First page",
  },
  prevPageLabel: {
    type: String,
    default: "Previous page",
  },
  nextPageLabel: {
    type: String,
    default: "Next page",
  },
  lastPageLabel: {
    type: String,
    default: "Last page",
  },
  itemsPerPageOptions: {
    type: Array as PropType<readonly (number | { title: string; value: number })[]>,
    default: () => [
      { value: 10, title: "10" },
      { value: 25, title: "25" },
      { value: 50, title: "50" },
      { value: 100, title: "100" },
      { value: -1, title: "All" },
    ],
  },
  showCurrentPage: Boolean,
});
const emit = defineEmits(["update:page", "update:itemsPerPage"]);

const pageCount = computed(() => Math.ceil(props.itemsLength / props.itemsPerPage));
const pageStart = computed(() => (props.page - 1) * props.itemsPerPage + 1);
const pageStop = computed(() => Math.min(props.page * props.itemsPerPage, props.itemsLength));
const totalItems = computed(() => props.itemsLength);

const setPage = (value: any) => {
  if (value) {
    emit("update:page", Number(value));
  }
};

const setItemsPerPage = (value: any) => {
   if (!value) return
  if (props.page !== 1) emit("update:page", 1);
  emit("update:itemsPerPage", Number(value));
};

const itemsPerPageOptions = computed(() =>
  props.itemsPerPageOptions.map((option) => {
    if (typeof option === "number") {
      return {
        value: option,
        title: option === -1 ? "All" : String(option),
      };
    }

    return {
      ...option,
      title: !isNaN(Number(option.title)) ? option.title : option.title,
    };
  })
);
</script>

<template>
  <div class="v-data-table-footer">
    <div class="v-data-table-footer__items-per-page">
      <span>{{ props.itemsPerPageText }}</span>
      <VSelect
        :items="itemsPerPageOptions"
        :modelValue="itemsPerPage"
        @update:modelValue="setItemsPerPage"
        density="compact"
        variant="underlined"
        hide-details
        class="mb-2 mx-4 items-per-page-select"
      />
    </div>

    <div class="v-data-table-footer__info">
      <div>{{ `${pageText} ${!totalItems ? "-" : `${pageStart} - ${pageStop} of ${totalItems}`}` }}</div>
    </div>
    <!-- Maybe we need to use w-md-auto w-100 -->
    <div class="v-data-table-footer__pagination ">
      <VPagination
        :model-value="page"
        @update:model-value="setPage"
        density="compact"
        :first-aria-label="firstPageLabel"
        :last-aria-label="lastPageLabel"
        :length="pageCount"
        :next-aria-label="nextPageLabel"
        :previous-aria-label="prevPageLabel"
        rounded
        show-first-last-page
        :total-visible="showCurrentPage ? 1 : 0"
        variant="plain"
      ></VPagination>
    </div>
  </div>
</template>
<style scoped>
.items-per-page-select {
  max-width: 55px;
}

.items-per-page-select :deep(.v-select__selection-text) {
  font-size: 14px;
}
</style>
