<script setup lang="ts">
const props = withDefaults(defineProps<{ readonly show: boolean, loading:boolean }>(), {
  show: false,
  loading: false,
});
</script>

<template>
  <div class="container" v-if="props.show">
    <div v-if="props.loading" class="progress">
      <v-progress-linear indeterminate position="absolute" :height="3"/>
    </div>
    <slot />
  </div>
</template>

<style scoped>
.scrim {
  position: absolute;
}
.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 33, 33,  0.33);
  z-index: 101;
  flex-direction: column;
}

.progress {
  width: 25%;
  margin-bottom: 55px;
}
</style>
