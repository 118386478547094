import appResource from "@/resources/AppResource";
import UserProfile from "@/types/UserProfile";
import { UserPermissionType } from "@/types/UserPermissionType";
import authService from "@/services/AuthService";
import Vue from "vue";
import { reactive } from "vue"

const state = reactive({
    userProfile: {} as UserProfile,
});

class UserProfileService {
    private readonly state = state;

    get currentUser() {
        return this.state.userProfile.username ? this.state.userProfile : null;
    }

    init(): void {
        this.reloadUserProfile();
    }

    hasPermission(permissionType: UserPermissionType): boolean {
        if (this.currentUser != null) {
            return (
                this.currentUser?.isAdministrator ||
                this.currentUser?.userPermissions.some(
                    (item) => item === permissionType
                )
            );
        } else {
            return false;
        }
    }

    reloadUserProfile(): void {
        appResource
            .getUserProfile()
            .then((resp) => {
                if (resp.data) {
                    this.state.userProfile = resp.data;
                } else {
                    // No user data returned. This means user session ended or user profile is no longer available (deactivated, deleted etc.)
                    authService.signOut();
                }
            })
            .catch(appResource.defaultErrorHandler);
    }
}

const userProfileService = new UserProfileService();

export default userProfileService;
