<template>
  <div>
    <SideSheet v-model="showDialog" heading="Export devices" contentClass="medium">

        <h4>Customer filter</h4>
        <v-select 
          v-model="customerFilterSelectedItem"
          :items="customerFilterItems" 
          density="compact"
          hide-details
          variant="outlined"
          class="filter-item" 
          attach
        />

        <h4 class="mt-6">Device type filter</h4>
        <v-combobox
          attach
          v-model="deviceTypeFilterSelectedItems"
          :items="deviceTypeFilterItems"
          multiple
          variant="outlined"
          density="compact"
          hint="Leave empty to ignore this filter"
          persistent-hint
        ></v-combobox>

        <h4 class="mt-6">Firmware version filter</h4>
        <v-text-field 
          v-model="fwVer"
          density="compact"
          hint="Leave empty to ignore this filter, otherwise enter comma separated firmware versions. Example: 4.19, 4.30"
          persistent-hint
          variant="outlined"
        />

        <h4 class="mt-6">Memory address filter</h4>
        <v-text-field 
          v-model="memAddress"
          density="compact"
          hint="Leave empty to ignore this filter, otherwise enter comma separated memory addresses. Example: 0x404000, 0x484000, 0x0"
          persistent-hint
          variant="outlined"
        />

        <h4 class="mt-6">Bootloader version filter</h4>
        <v-text-field 
          v-model="bootloaderVer"
          density="compact"
          hint="Leave empty to ignore this filter, otherwise enter comma separated bootloader versions. Example: 1.0, 2.0"
          persistent-hint
          variant="outlined"
        />

        <h4 class="mt-6">Include SIM status</h4>
        <v-switch
          class="mt-0"
          v-model="includeDeviceStatus"
          density="compact"
          persistent-hint
          hint="NB! Including SIM status noticeably increases export time."
          variant="outlined"
        />

        <h4 class="mt-6">Include firmware update URL</h4>
        <v-switch
          class="mt-0"
          v-model="includeFwUpdateUrl"
          density="compact"
          persistent-hint
          hint="NB! Including firmware update URL noticeably increases export time."
          variant="outlined"
        />

        <h4 class="mt-6">Export format</h4>
        <v-select 
          v-model="exportFormat"
          :items="exportFormatItems" 
          density="compact"
          hide-details
          variant="outlined"
          class="filter-item" 
        />

        <div v-if="isBackgroundExport">
          <h4 class="mt-6">Email address</h4>
          <p class="text-caption text--secondary">Exporting devices based on the selected options may take a while. Once the export process is complete, we will deliver it to the specified email address below.</p>
          <v-text-field 
            v-model="emailAddress"
            density="compact"
            hide-details
            variant="outlined"
            class="filter-item" 
          />
        </div>

      <template v-slot:actions>
        <v-spacer />
        <v-btn @click="showDialog = false">Close</v-btn>
        <v-btn color="primary" class="ml-4" @click="exportDevices()">Export</v-btn>
      </template>
    </SideSheet>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue, toNative, Model } from "vue-facing-decorator";
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import { DeviceType } from "@/types/DeviceType";
import commonHelper from "@/helpers/commonHelper";
import userProfileService from "@/services/UserProfileService";
import deviceResource from "@/resources/DeviceResource";

@Component({
  components: {
    SideSheet,
    PropEditor,
  },
  emits: ["update:modelValue"]
})
class ExportDevices extends Vue {
  @Model({ default: null })
  readonly value!: boolean | null;

  get showDialog() {
    return this.value != null && this.value;
  }
  set showDialog(value: boolean) {
    this.$emit("update:modelValue", null);
  }

  customerFilterSelectedItem: string = "";
  customerFilterItems = [
    { title: "No filter", value: "" },
    { title: "Customer assigned", value: "true" },
    { title: "No customer assigned", value: "false" },
  ];

  deviceTypeFilterSelectedItems: { text: string, value: DeviceType }[] = [];
  deviceTypeFilterItems = [
    { title: "Pro", value: DeviceType.GpsRadarPro },
    { title: "ProX", value: DeviceType.GpsRadarProX },
  ];

  fwVer: string = "";
  memAddress: string = "";
  bootloaderVer: string = "";
  includeDeviceStatus: boolean = false;
  includeFwUpdateUrl: boolean = false;

  exportFormat: string = "xls";
  exportFormatItems = [
    { title: "Excel (XLS)", value: "xls" },
    { title: "CSV", value: "csv" },
  ];

  emailAddress: string = "";

  get isBackgroundExport() {
    var isBackgroundExport = this.includeDeviceStatus || this.includeFwUpdateUrl;

    // TODO Prefill email with current user's email if not set. This should be done somewhere else, but for now it's here.
    if (isBackgroundExport && !this.emailAddress) {
      this.emailAddress = userProfileService.currentUser?.email || "";
    }

    return isBackgroundExport;
  }

  exportDevices() {
    var queryParams: string[] = [];
    queryParams.push(`format=${this.exportFormat}`);

    // Optional export params
    if (this.customerFilterSelectedItem) {
      queryParams.push(`isCustomerAssigned=${this.customerFilterSelectedItem}`);
    }
    if (this.deviceTypeFilterSelectedItems.length) {
      queryParams.push(`deviceType=${this.deviceTypeFilterSelectedItems.map(el => el.value).join(",")}`);
    }
    if (this.fwVer) {
      queryParams.push(`fw=${this.fwVer}`);
    }
    if (this.memAddress) {
      queryParams.push(`memAddress=${this.memAddress}`);
    }
    if (this.bootloaderVer) {
      queryParams.push(`bootloader=${this.bootloaderVer}`);
    }
    if (this.includeDeviceStatus) {
      queryParams.push("includeDeviceStatus=true");
    }
    if (this.includeFwUpdateUrl) {
      queryParams.push("includeFwUpdateUrl=true");
    }
    if (this.emailAddress) {
      queryParams.push(`recipientEmail=${this.emailAddress}`);
    }

    // Execute export
    if (this.isBackgroundExport) {
      // Background export
      deviceResource
        .exportDevices(queryParams)
        .then(() => {
          this.$confirm
            .show(
              `Export started<br/><br/>An email will be sent to <b>${this.emailAddress}</b> once the export process is completed.`,
              {
                buttonTrueText: "OK",
                buttonFalseText: "",
                width: 500,
                persistent: true,
                overlayOpacity: 0.3,
              }
            );

          this.showDialog = false;
        })
        .catch(deviceResource.defaultErrorHandler);
    } else {
      // Direct export (browser download)
      const url = `${commonHelper.apiHost}/export/devices?${queryParams.join("&")}`;
      window.open(url, "_blank");
    }
  }
}
export default toNative(ExportDevices)
</script>