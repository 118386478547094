import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-52e4c39e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap d-block d-md-flex" }
const _hoisted_2 = { class: "chart-container mt-md-0 mt-5" }
const _hoisted_3 = { class: "chart-container" }
const _hoisted_4 = { class: "device-firmware-chart-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heatmap = _resolveComponent("Heatmap")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ActiveDevicesChart = _resolveComponent("ActiveDevicesChart")!
  const _component_ActiveDevicesHourlyChart = _resolveComponent("ActiveDevicesHourlyChart")!
  const _component_DeviceFirmwareChart = _resolveComponent("DeviceFirmwareChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, {
      sm: "12",
      md: "6",
      class: "map-container mb-md-0 mb-10"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Heatmap)
      ]),
      _: 1
    }),
    _createVNode(_component_v_col, {
      sm: "12",
      md: "6",
      class: "left-container"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ActiveDevicesChart)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ActiveDevicesHourlyChart)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_DeviceFirmwareChart)
        ])
      ]),
      _: 1
    })
  ]))
}