<template>
  <div>
    <div class="d-block mt-4">
      <v-select
        attach
        v-model="period"
        :items="periodOptions"
        variant="outlined"
        density="compact"
        class="flex-grow-0"
        style="min-width: 150px"
        hide-details
        label="Time window"
      />
    </div>
    <div class="d-block pt-4">
      <div>Studio API requests</div>
      <div class="user-activity-resp-status-chart-wrap">
        <div v-if="showChart || loading" class="user-activity-resp-status-chart">
          <Overlay :show="loading" :loading="loading" />
          <v-chart
            ref="chart"
            :key="option.series && option.series.length"
            :option="option"
            autoresize
            :class="{ 'opacity-50': loading }"
          />
        </div>

        <div v-else class="text-center text-body-2 text--disabled mt-2">No data available</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue, Watch, Setup, toNative } from "vue-facing-decorator";
import User from "@/types/User";
import axios, { CancelTokenSource } from "axios";
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { use, ComposeOption } from "echarts/core";
import moment from "moment";
import userResource from "@/resources/UserResource";
import { useTheme } from "vuetify";
//@ts-ignore Remove it after refactoring to classic vue 3 and change the configuration
import Overlay from "@/components/common/Overlay.vue";

use([CanvasRenderer, LineChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
type ECOption = ComposeOption<
  LineSeriesOption | TitleComponentOption | TooltipComponentOption | LegendComponentOption | GridComponentOption
>;

@Component({
  components: { VChart, Overlay },
})
class UserActivity extends Vue {
  showChart: boolean = false;
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;
  period = 7;

  periodOptions = [
    { title: "Last 7 days", value: 7 },
    { title: "Last 30 days", value: 30 },
    { title: "Last 90 days", value: 90 },
  ];

  colors = {
    dark: {
      textColor: "#fff",
      barTextColor: "#fedd10",
      secondaryColor: "#777",
    },
    light: {
      textColor: "#333",
      barTextColor: "#333",
      secondaryColor: "#ccc",
    },
  };
  
  @Setup(() => useTheme().global?.current?.value?.dark)
  isDark = useTheme().global?.current?.value?.dark;

  option: ECOption = {
    textStyle: {
      fontFamily: "Roboto, sans-serif",
    },

    grid: { top: 30, bottom: 30 },

    tooltip: {
      trigger: "axis",
      confine: true,
      formatter: (args: any) => {
        let tooltip = `<div>${moment(args[0].axisValue).format("D MMM, YYYY")}</div> `;

        args.forEach(({ value }: any) => {
          tooltip += `<div class="w-100 text-right">
                        <b>${value[1] || 0}</b>
                      </div>`;
        });

        return tooltip;
      },
      axisPointer: {
        type: "line",
      },
    },

    xAxis: {
      type: "time",
      axisLine: {
        lineStyle: { color: this.colors[`${this.isDark ? "dark" : "light"}`].secondaryColor },
      },
      axisLabel: { color: this.colors[`${this.isDark ? "dark" : "light"}`].textColor },
      splitLine: {
        show: true,
        lineStyle: { color: this.colors[`${this.isDark ? "dark" : "light"}`].secondaryColor },
      },
    },

    yAxis: {
      type: "value",
      alignTicks: true,
      minInterval: 1,
      axisLabel: { color: this.colors[`${this.isDark ? "dark" : "light"}`].textColor },
      splitLine: {
        show: true,
        lineStyle: { color: this.colors[`${this.isDark ? "dark" : "light"}`].secondaryColor },
      },
    },

    series: [],
    animation: false,
  };

  @Setup(() => useTheme().current.value.colors.primary)
  primaryColor = useTheme().current.value.colors.primary;



  @Model({ default: null })
  readonly value!: User | null;

  @Watch("period")
  onChangePeriod() {
    if (this.period) this.getData();
  }

  mounted() {
    this.getData();
  }
  destroyed() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
  }

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      if (!this.value) return;

      userResource
        .getApiUserActivity(this.value.userId, this.period, this.cancelToken)
        .then((resp) => {
          const data = resp.data;
          this.showChart = Object.values(data).length > 0;

          this.option.series = [
            {
              type: "line",
              areaStyle: { color: this.primaryColor },
              lineStyle: { color: this.primaryColor },
              showSymbol: false,
              dimensions: [{ type: "time" }, { type: "number" }],
              data: Object.entries(data),
            },
          ];
        })
        .catch(userResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
}

export default toNative(UserActivity);
</script>

<style scoped>
.user-activity-resp-status-chart-wrap {
  width: 100%;
  flex: 1;
}
.user-activity-resp-status-chart {
  height: 500px;
  max-height: 70dvh;
  width: 100%;
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;
}
</style>
