import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-grow-1" }
const _hoisted_2 = { class: "text-right align-self-end mt-2 mt-sm-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_TableConfiguration = _resolveComponent("TableConfiguration")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_EditUserGroup = _resolveComponent("EditUserGroup")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "d-block d-sm-flex" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.searchTerm,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTerm) = $event)),
                  "append-inner-icon": "mdi-magnify",
                  color: "primary",
                  variant: "underlined",
                  label: "Search",
                  "hide-details": "",
                  clearable: "",
                  "test-id": "search"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_btn, {
                  size: "x-small",
                  variant: "text",
                  icon: "",
                  class: "align-self-end ml-4",
                  onClick: _ctx.reload,
                  disabled: _ctx.loading,
                  title: "Refresh"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { size: "24" }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-reload")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]),
                _createVNode(_component_TableConfiguration, {
                  allHeaders: _ctx.headers,
                  modelValue: _ctx.selectedHeaders,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedHeaders) = $event)),
                  tableKey: "userGroupsTableColumns"
                }, null, 8, ["allHeaders", "modelValue"])
              ]),
              _createVNode(_component_v_spacer, { class: "d-none d-sm-block" }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_v_btn, {
                  size: "small",
                  color: "primary",
                  class: "align-self-end",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.newUserGroup())),
                  "test-id": "new-user-group"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" New user group ")
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_data_table, {
            density: "compact",
            headers: _ctx.selectedHeaders,
            items: _ctx.items,
            "row-props": _ctx.rowClass,
            "sort-by": _ctx.sortBy,
            "onUpdate:sortBy": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sortBy) = $event)),
            "must-sort": true,
            hover: "",
            mobile: false,
            loading: _ctx.loading,
            search: _ctx.searchTerm,
            "disable-pagination": true,
            "hide-default-footer": true,
            "items-per-page": "-1",
            "mobile-breakpoint": 0,
            "onClick:row": _cache[4] || (_cache[4] = (_, { item }) => _ctx.rowClick(item))
          }, {
            bottom: _withCtx(() => []),
            _: 1
          }, 8, ["headers", "items", "row-props", "sort-by", "loading", "search"]),
          _createVNode(_component_v_overlay, {
            "model-value": _ctx.loading,
            contained: "",
            persistent: "",
            opacity: "0",
            style: {"z-index":"999 !important"}
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_EditUserGroup, {
        modelValue: _ctx.userGroupToEdit,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userGroupToEdit) = $event)),
        onUpdated: _ctx.reload
      }, null, 8, ["modelValue", "onUpdated"])
    ]),
    _: 1
  }))
}