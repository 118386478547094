import Vue from "vue";
import { reactive } from "vue"

const state = reactive({
    isDark: false
});

class ThemeSwitcher {
    private readonly state = state;

    get isDark(): boolean {
        this.state.isDark = window.localStorage.isDarkTheme === "true" || window.localStorage.isDarkTheme === undefined;
        document.documentElement.setAttribute("theme", this.state.isDark ? "dark" : "light");
        return this.state.isDark;
    }
    set isDark(value: boolean) {
        window.localStorage.isDarkTheme = value;
        this.state.isDark = value;
        document.documentElement.setAttribute("theme", value ? "dark" : "light");
    }

    toggleTheme(): boolean {
        var val = this.isDark;
        val = !val;
        this.isDark = val;

        return val;
    }
}

const themeSwitcher = new ThemeSwitcher();
export default themeSwitcher;