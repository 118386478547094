import ResourceBase from "@/resources/ResourceBase";
import ReleaseData from "@/types/ReleaseData";
import { AxiosResponse, AxiosRequestConfig } from "axios";

class StaticResource extends ResourceBase {
    getHTML(src: string, cancelToken?: any): Promise<AxiosResponse<string>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.httpClient.get<string>(src, config);
    }

    getReleases(cancelToken?: any): Promise<AxiosResponse<ReleaseData[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.httpClient.get<ReleaseData[]>(`/releases/version.json`, config);
    }
}

const staticResource = new StaticResource();
export default staticResource;
