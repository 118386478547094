import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, { location: "bottom" }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps({ icon: "" }, props, {
        ripple: false,
        "x-small": _ctx.isXSmall,
        small: _ctx.isSmall,
        large: _ctx.isLarge,
        "x-large": _ctx.isXLarge,
        plain: _ctx.plain,
        variant: "text",
        density: "compact"
      }), {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            color: _ctx.dark ? 'black' : ''
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-dots-vertical")
            ]),
            _: 1
          }, 8, ["color"])
        ]),
        _: 2
      }, 1040, ["x-small", "small", "large", "x-large", "plain"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list, {
        light: "",
        density: "compact",
        class: "py-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuItemCopyId(_ctx.value)))
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_v_icon, { class: "mr-2" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-content-copy")
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Copy ID")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.canViewDeviceLocation)
            ? (_openBlock(), _createBlock(_component_v_list_item, {
                key: 0,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menuItemOpenLocationHistory(_ctx.value)))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_icon, { class: "mr-2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-map-marker-path")
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Open in Location History")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.canViewDeviceLogs)
            ? (_openBlock(), _createBlock(_component_v_list_item, {
                key: 1,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.menuItemOpenInLogs(_ctx.value)))
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_icon, { class: "mr-2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-text-search")
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Open in Logs")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}