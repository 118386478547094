<template>
  <div class="chart">
    <v-overlay contained :model-value="loading" class="progress" z-index="101" persistent>
      <v-progress-linear indeterminate position="absolute" />
    </v-overlay>
    <v-chart :option="option" autoresize :class="{ 'opacity-50': loading }" />
  </div>
</template>

<script lang="ts">
import { use, graphic, ComposeOption } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, BarSeriesOption } from "echarts/charts";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponentOption,
  TooltipComponent,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";
import { Component, Vue, Watch, toNative, Setup } from "vue-facing-decorator";
import statsResource from "@/resources/StatsResource";
import axios, { CancelTokenSource } from "axios";
import { useTheme, ThemeInstance } from "vuetify";

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, GridComponent]);
type ECOption = ComposeOption<BarSeriesOption | TitleComponentOption | TooltipComponentOption | GridComponentOption>;

@Component({
  name: "ActiveDevicesChart", // name is needed for keep-alive
  components: { VChart },
})
class ActiveDevicesChart extends Vue {
  @Setup(() => useTheme())
  theme: ThemeInstance = useTheme();

  yAxisMax = 100000;
  borderTopWidth = 0.02;
  timer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  loading = false;

  activated() {
    this.getData();
    this.timer = setInterval(() => this.getData(), 60 * 1000);
  }

  deactivated() {
    if (this.timer) {
      const timer = this.timer;
      clearInterval(timer);
      this.timer = 0;
    }
  }

  get textColor() {
    return this.theme?.global?.current?.value?.dark ? "#fff" : "#333";
  }
  get barTextColor() {
    return this.theme?.global?.current?.value?.dark ? "#fedd10" : "#333";
  }

  @Watch("theme.global.current.value.dark", { deep: true, immediate: true })
  onChangeTheme() {
    if (!Array.isArray(this.option.title) && this.option.title?.textStyle) {
      this.option.title.textStyle.color = this.textColor;
    }

    if (!Array.isArray(this.option.xAxis) && this.option.xAxis?.axisLabel) {
      this.option.xAxis.axisLabel.color = this.textColor;
    }

    if (!Array.isArray(this.option.series) && this.option.series?.label) {
      this.option.series.label.color = this.barTextColor;
    }
  }

  option: ECOption = {
    textStyle: { fontFamily: "Roboto, sans-serif" },

    title: {
      text: "Active devices",
      left: "center",
      textStyle: {
        fontSize: 20,
        fontWeight: 400,
        color: this.textColor,
      },
    },

    yAxis: { show: false, max: this.yAxisMax },

    xAxis: {
      type: "category",
      data: ["Minute", "Hour", "Today", "Week", "30 days", "60 days", "90 days"],

      axisLabel: { color: this.theme?.global?.current?.value?.dark ? "#fff" : "#333" },
      axisLine: { show: false },
      axisTick: { show: false },
    },

    series: {
      type: "bar",
      itemStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#fedd10" },
          {
            offset: (this.yAxisMax * this.borderTopWidth) / this.yAxisMax,
            color: "#fedd10",
          },
          {
            offset: (this.yAxisMax * this.borderTopWidth) / this.yAxisMax,
            color: "rgba(254,222,16, 0.5)",
          },
          { offset: 1, color: "rgba(254,222,16, 0.5)" },
        ]),
      },
      label: {
        show: true,
        position: "top",
        color: this.barTextColor,
      },

      barCategoryGap: "10%",
      data: [],
    },

    media: [
      {
        query: { maxWidth: 500 },
        option: {
          xAxis: {
            axisLabel: { fontSize: 10 },
            data: ["Min.", "Hour", "Today", "Week", "30d", "60d", "90d"],
          },
          series: [{ label: { fontSize: 10 } }],
        },
      },

      {
        query: { minWidth: 500 },
        option: {
          xAxis: { axisLabel: { fontSize: 14 } },
          series: [{ label: { fontSize: 14 } }],
        },
      },

      {
        query: { minWidth: 800 },
        option: {
          series: [{ label: { fontSize: 16 } }],
        },
      },
    ],
  };

  getData() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      statsResource
        .getActiveDevicesSummary(this.cancelToken)
        .then((resp) => {
          const data = resp.data;

          const maxValue = Math.max(...Object.values(data));
          const yMax = Math.min(this.yAxisMax, maxValue);
          const sortArr = [
            "lastMinute",
            "lastHour",
            "today",
            "lastSevenDays",
            "lastThirtyDays",
            "lastSixtyDays",
            "lastNinetyDays",
          ] as const;

          (this.option.series as BarSeriesOption).data = sortArr.map((key) => {
            const borderOffset = data[key] ? (yMax * this.borderTopWidth) / Math.min(yMax, data[key]) : 1;
            return {
              value: data[key],
              itemStyle: {
                color: new graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#fedd10" },
                  {
                    offset: borderOffset > 1 ? 1 : borderOffset,
                    color: "#fedd10",
                  },
                  {
                    offset: borderOffset > 1 ? 1 : borderOffset,
                    color: "rgba(254,222,16, 0.5)",
                  },
                  { offset: 1, color: "rgba(254,222,16, 0.5)" },
                ]),
              },
            };
          });
          if (!Array.isArray(this.option.yAxis) && this.option.yAxis?.max) {
            this.option.yAxis.max = yMax;
          }
        })
        .catch(statsResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
        });
    }, 10);
  }
}

export default toNative(ActiveDevicesChart);
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress :deep(.v-overlay__content) {
  width: 25%;
  margin-bottom: 40px;
}
</style>
