<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex justify-space-between flex-grow-1">
          <div class="d-flex flex-grow-1">
            <v-text-field
              v-model="searchTerm"
              append-inner-icon="mdi-magnify"
              variant="underlined"
              color="primary"
              label="Search"
              hide-details
              clearable
              @click:clear="search()"
              style="max-width"
              v-on:input="search()"
              v-on:keypress.enter="search(true)"
            ></v-text-field>
            <v-btn
              size="x-small"
              variant="text"
              icon
              class="align-self-end ml-4"
              @click="reload"
              :disabled="loading"
              title="Refresh"
            >
              <v-icon size="24">mdi-reload</v-icon>
            </v-btn>
            <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="devicesTableColumns" />
            <v-tooltip location="bottom" :disabled="!searchTerm">
              <template v-slot:activator="{ props }">
                <span class="align-self-end ml-2 p-relative" v-bind="props">
                  <v-btn
                    size="x-small"
                    variant="text"
                    icon
                    class="align-self-end"
                    @click="showFilter = !showFilter"
                    :color="showFilter ? 'primary' : undefined"
                    :disabled="loading || Boolean(searchTerm)"
                    title="Filters"
                  >
                    <v-icon size="24" v-if="searchTerm">mdi-filter-off</v-icon>

                    <v-icon size="24" v-if="!searchTerm">mdi-filter-variant</v-icon>
                    <v-badge
                      color="primary"
                      v-if="numberOfFilter && !searchTerm"
                      transition="v-fade-transition"
                      dot
                      bordered
                      offset-x="-1"
                      offset-y="-10"
                    />
                  </v-btn>
                </span>
              </template>
              Filters ignored during search
            </v-tooltip>
          </div>

          <v-spacer class="d-none d-sm-block"></v-spacer>
          <div class="text-right align-self-end mt-2 mt-sm-0">
            <v-menu offset-y v-if="isAdmin" location="bottom left">
              <template v-slot:activator="{ props }">
                <v-btn icon v-bind="props" class="ml-2" variant="text" density="compact">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="showExport = true" link>
                  <v-list-item-title>Export devices</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-title>

      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" :disabled="loading" />

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        :loading="loading && 'primary'"
        :mobile-breakpoint="0"
        @click:row="(event, { item }) => rowClick(item)"
        @contextmenu:row="openContenxMenu"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
      >
        <template v-slot:[`item.deviceId`]="{ item }">
          <div class="no-wrap">
            {{ item.deviceId }}
            <DeviceMenu v-model="item.deviceId" size="small" />
          </div>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ getDeviceTypeName(item.type) }}
        </template>
        <template v-slot:[`item.isBlocked`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isBlocked">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isDebugMode`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isDebugMode">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isUpdateAllowed`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isUpdateAllowed">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.customerId`]="{ item }">
          <span>{{ item.customerId }}</span>
          <v-btn
            v-if="canViewCustomers && item.customerId"
            @click="editCustomer(item.customerId, $event)"
            icon
            size="small"
            variant="text"
            density="compact"
          >
            <v-icon size="small">mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.subscriptionEndDate`]="{ item }">
          {{ moment(item.subscriptionEndDate).format("lll") }}
        </template>
        <template v-slot:[`item.lastSeenDate`]="{ item }">
          {{ item.lastSeenDate != undefined ? moment(item.lastSeenDate).format("lll") : "" }}
        </template>
        <template v-slot:[`item.notes`]="{ item }">
          <v-tooltip v-if="item.notes && item.notes.length > 15" location="bottom" color="secondary" max-width="500">
            <template v-slot:activator="{ props }">
              <div class="no-wrap" v-bind="props">
                {{ item.notes.substring(0, 15) + "..." }}
              </div>
            </template>
            <span class="pre-wrap">{{ item.notes }}</span>
          </v-tooltip>
          <span v-else>{{ item.notes }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div>{{ getDeviceStatusName(item.status) }}</div>
        </template>
        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay contained :model-value="loading" opacity="0" persistent style="z-index: 999 !important" />
    </v-card>

    <EditDevice v-model="deviceToEdit" v-on:updated="reload" :deviceInitTab="deviceInitTab" />
    <EditCustomer v-model="customerToEdit" v-on:updated="reload" :initData="initData" :customerInitTab="customerInitTab" />
    <ExportDevices v-model="showExport" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, toNative } from "vue-facing-decorator";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import EditDevice from "@/components/devices/EditDevice.vue";
import EditCustomer from "@/components/customers/EditCustomer.vue";
import deviceResource from "@/resources/DeviceResource";
import customerResource from "@/resources/CustomerResource";
import Device from "@/types/Device";
import Customer from "@/types/Customer";
import { DeviceType } from "@/types/DeviceType";
import DeviceHelper from "@/helpers/deviceHelper";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import userStorage from "@/services/UserStorageService";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import ExportDevices from "@/components/devices/ExportDevices.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import TableFilter from "@/types/TableFilter";
import Filters from "@/components/common/Filters/Filters.vue";
import { nextTick } from "vue";
//@ts-ignore
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { DeviceStatus } from "@/types/DeviceStatus";

@Component({
  name: "DeviceList", // name is needed for keep-alive
  components: {
    EditDevice,
    EditCustomer,
    DataTableContextMenu,
    DeviceMenu,
    ExportDevices,
    TableConfiguration,
    Filters,
    DataTableFooter,
  },
})
export default class DeviceList extends Vue {
  @Prop({ default: null })
  readonly initFilter!: { [key: string]: TableFilter["selected"] };

  @Prop({ default: null })
  initData!: {
    customerId?: number;
    customerTab?: string;
    deviceId?: number;
    deviceTab?: string | null;
  } | null;

  created() {
    this.showFilter = Boolean(Object.values(this.initFilter || {}).length);
    this.filter = [
      {
        title: "Type",
        icon: "mdi-format-list-bulleted-type",
        filterName: "type",
        searchable: false,
        selected: this.initFilter?.type || [],
        itemsCallback: (search?: string) => {
          if (!search) return DeviceHelper.getDeviceFilterByType();
          return DeviceHelper.getDeviceFilterByType().filter(({ text }) =>
            text.toLowerCase().includes(search.toLowerCase())
          );
        },
      },
      {
        title: "Blocked",
        icon: "mdi-cancel",
        filterName: "blocked",
        searchable: false,
        selected: this.initFilter?.blocked || [],
        disableMultiple: true,
        itemsCallback: (search?: string) => {
          const data = [
            { text: "Yes", value: true },
            { text: "No", value: false },
          ];
          if (!search) return data;
          return data.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
        },
      },
      {
        title: "Allow updates",
        icon: "mdi-upload",
        filterName: "updateAllowed",
        searchable: false,
        selected: this.initFilter?.updateAllowed || [],
        disableMultiple: true,
        itemsCallback: (search?: string) => {
          const data = [
            { text: "Yes", value: true },
            { text: "No", value: false },
          ];
          if (!search) return data;
          return data.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
        },
      },
      {
        title: "Status",
        icon: "mdi-checkbox-multiple-marked",
        filterName: "status",
        searchable: false,
        selected: this.initFilter?.status || [],
        itemsCallback: (search?: string) => {
          if (!search) return DeviceHelper.getDeviceFilterByStatus();
          return DeviceHelper.getDeviceFilterByStatus().filter(({ text }) =>
            text.toLowerCase().includes(search.toLowerCase())
          );
        },
      },
    ];
  }

  moment = moment;
  showFilter = false;
  filter: TableFilter[] = [];

  get numberOfFilter() {
    return Object.values(this.filter).reduce((acc, { selected }) => (acc += selected.length), 0);
  }

  total?: number = 0;
  items: Device[] = [];
  loading = false;

  optionsStorageKey = "devicesTable";
  itemsPerPage = userStorage.get(this.optionsStorageKey)?.itemsPerPage ?? 15;
  sortBy: { key: string; order: boolean | "asc" | "desc" }[] = userStorage.get(this.optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(this.optionsStorageKey)?.sortBy
    : [{ key: "deviceId", order: true }];

  page = userStorage.get(this.optionsStorageKey)?.page ?? 1;

  searchTermStorageKey = "devicesTableSearchTerm";
  searchTerm = userStorage.get(this.searchTermStorageKey) ?? "";
  searchThrottleTimer = 0;
  cancelToken: CancelTokenSource | undefined = undefined;
  cancelTokenArr: CancelTokenSource[] = [];

  deviceToEdit: Device | null = null;
  deviceInitTab: string | null = null;
  showExport: boolean = false;

  ignoreOptionsChange: boolean = false;

  @Watch("itemsPerPage")
  @Watch("sortBy")
  @Watch("page")
  async onPropertyChanged() {
    if (!this.ignoreOptionsChange) {
      await nextTick();
      this.getData();
    }
  }

  @Watch("customerToEdit")
  onChangeCustomerToEdit() {
    if (!this.customerToEdit) {
      this.customerInitTab = null;
      this.deviceInitTab = null;
    }
  }

  @Watch("deviceToEdit")
  onChangeDeviceToEdit() {
    if (!this.deviceToEdit) {
      this.deviceInitTab = null;
    }
  }
  selectedHeaders = [];
  get headers() {
    var headers = [
      { title: "ID", align: "start", key: "deviceId" },
      { title: "Name", key: "deviceName" },
      { title: "Type", key: "type" },
      { title: "Customer ID", key: "customerId" },
      { title: "Blocked", key: "isBlocked" },
      { title: "Debug mode", key: "isDebugMode" },
      { title: "Allow updates", key: "isUpdateAllowed" },
      { title: "Notes", key: "notes", sortable: false },
      { title: "Created", key: "created" },
      { title: "Subscription end", key: "subscriptionEndDate" },
      { title: "Last seen", key: "lastSeenDate" },
      { title: "Status", key: "status" },
    ];

    return headers;
  }
  get canViewCustomers() {
    return userProfileService.hasPermission(UserPermissionType.ViewCustomers);
  }
  get isAdmin() {
    return userProfileService.currentUser && userProfileService.currentUser.isAdministrator;
  }

  customerToEdit: Customer | null = null;
  customerInitTab: string | null = null;
  dataReloadTimeoutId: number | null = null;
  dataReloadIntervalSeconds = 180;
  componentActive = false;

  activated() {
    this.componentActive = true;

    if (this.initData?.customerId) {
      this.editCustomer(this.initData?.customerId, new Event("init"));
      return;
    }
    if (this.initData?.deviceId) {
      this.getDeviceById(this.initData?.deviceId, true);
    }

    // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
    if (this.dataReloadTimeoutId === 0 || this.dataReloadTimeoutId === null) {
      this.getData();
    }
  }
  deactivated() {
    this.componentActive = false;
  }

  restartDataReloadTimeout() {
    if (this.dataReloadTimeoutId) {
      clearTimeout(this.dataReloadTimeoutId);
    }

    this.dataReloadTimeoutId = setTimeout(() => {
      this.dataReloadTimeoutId = 0;
      if (this.componentActive) {
        this.getData();
      }
    }, this.dataReloadIntervalSeconds * 1000);
  }

  contextMenuEventItem: any = null;
  openContenxMenu(e: any) {
    this.contextMenuEventItem = e;
  }

  getDeviceTypeName(type: DeviceType) {
    return DeviceHelper.getDeviceTypeDisplayName(type);
  }

  getDeviceStatusName(status: DeviceStatus) {
    return DeviceHelper.getDeviceStatusDisplayName(status);
  }

  getDeviceById(deviceId: number, isInitData = false) {
    deviceResource
      .getDeviceById(deviceId)
      .then((resp) => {
        this.deviceToEdit = resp.data;
        if (isInitData) {
          this.deviceInitTab = this.initData?.deviceTab || null;
        }
      })
      .catch(deviceResource.defaultErrorHandler);
  }

  getDevicesByIds(deviceIds: number[]) {
    this.cancelExistingRequests();

    if (!deviceIds?.length) return;

    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.ignoreOptionsChange = true;
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      const promise = Promise.all(
        deviceIds.map((id) => {
          const token = axios.CancelToken.source();
          this.cancelTokenArr.push(token);
          return deviceResource
            .getDeviceById(id, token)
            .then((resp) => resp.data)
            .catch((err) => {
              if (axios.isCancel(err)) return err;
              if (err.response.status === 404) return;
              deviceResource.defaultErrorHandler(err);
            });
        })
      );

      promise
        .then((resp) => {
          if (resp.some((v) => axios.isCancel(v))) return;
          this.items = resp.filter((v) => v) as Device[];
          this.total = undefined;
        })
        .finally(() => {
          this.loading = false;
          this.cancelTokenArr = [];
        });
    }, 10);
  }

  getSearchTermAsIds(): number[] | null {
    const dividers = [" ", ",", ";"];
    let result: number[] | null = null;

    dividers.some((divider) => {
      const termArr = this.searchTerm
        ?.split(divider)
        .filter((v: string) => v.trim())
        .map((v: string) => v.trim())
        .filter((v: string, ind: number, arr: string[]) => arr.indexOf(v) === ind);

      if (termArr?.every((v: string) => !isNaN(Number(v))) && termArr.length > 1) {
        result = termArr;
      }
      return result;
    });
    return result;
  }

  cancelExistingRequests() {
    // Cancel existing request
    if (this.cancelToken) {
      this.cancelToken.cancel();
      this.cancelToken = undefined;
    }

    // Cancel existing requests when making a search by IDs
    if (this.cancelTokenArr.length) {
      this.cancelTokenArr.forEach((t) => t?.cancel());
      this.cancelTokenArr = [];
    }
  }

  getData(resetPagination: boolean = false) {
    this.cancelExistingRequests();

    // Reset pagination
    if (resetPagination) {
      this.ignoreOptionsChange = true;
      this.page = 1;
    }

    // Save sorting, filters and search terms
    userStorage.set(this.optionsStorageKey, {
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: this.sortBy,
    });
    userStorage.set(this.searchTermStorageKey, this.searchTerm);

    // Restart data reload timeout
    this.restartDataReloadTimeout();

    const deviceIds = this.getSearchTermAsIds();

    if (deviceIds) {
      if (deviceIds?.length > 300) {
        return infoMessageService.show(
          InfoMessageType.Info,
          "Too many device IDs. The maximum available number of IDs is 300."
        );
      }
      return this.getDevicesByIds(deviceIds);
    }
    setTimeout(() => {
      // Timeout is workaround for finaly() being executed after request was canceled and new request already began
      this.loading = true;
      this.cancelToken = axios.CancelToken.source();

      let typeFilter = undefined;
      let blockedFilter = undefined;
      let statusFilter = undefined;
      let updateAllowedFilter = undefined;
      if (!this.searchTerm) {
        typeFilter = this.filter.find(({ filterName }) => filterName === "type")?.selected.map(({ value }) => value);
        blockedFilter = this.filter
          .find(({ filterName }) => filterName === "blocked")
          ?.selected.map(({ value }) => value)[0];
        statusFilter = this.filter.find(({ filterName }) => filterName === "status")?.selected.map(({ value }) => value);
        updateAllowedFilter = this.filter
          .find(({ filterName }) => filterName === "updateAllowed")
          ?.selected.map(({ value }) => value);
      }

      const orderDesc =
        typeof this.sortBy[0].order === "boolean" ? this.sortBy[0].order : this.sortBy[0].order.toString() === "desc";
      deviceResource
        .getDevicesPaged(
          this.itemsPerPage,
          this.page,
          this.searchTerm,
          this.sortBy[0].key,
          orderDesc,
          typeFilter,
          blockedFilter,
          statusFilter,
          updateAllowedFilter?.[1] ? undefined : updateAllowedFilter?.[0],
          this.cancelToken
        )
        .then((resp) => {
          this.items = resp.data.items;
          this.total = resp.data.totalItems;
        })
        .catch(deviceResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
          this.cancelToken = undefined;
          this.ignoreOptionsChange = false;
        });
    }, 10);
  }

  search(noTheshold: boolean = false) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    if (noTheshold || !this.searchTerm) {
      this.getData(true);
    } else {
      this.searchThrottleTimer = setTimeout(() => {
        this.getData(true);
      }, 1000);
    }
  }

  reload() {
    this.getData();
  }

  rowClick(item: Device) {
    if (!this.contextMenuEventItem) {
      this.deviceToEdit = Object.assign({}, item);
    }
  }

  rowClass({ item }: { item: Device }) {
    if (item.isBlocked) {
      return { class: { "text-red cursor-default": true } };
    } else if (item.isDebugMode) {
      return { class: { "text-blue cursor-default": true } };
    }
    return { class: { "cursor-default": true } };
  }

  editCustomer(customerId: number, e: Event) {
    e.stopPropagation();

    if (customerId) {
      this.loading = true;

      customerResource
        .getCustomerById(customerId)
        .then((resp) => {
          this.customerToEdit = resp.data;
          if (e.type === "init") {
            this.customerInitTab = this.initData?.customerTab || null;
          }
        })
        .catch(customerResource.defaultErrorHandler)
        .finally(() => {
          this.loading = false;
        });
    }
  }

  updateFilter({ newFilter, eventOptions }: { newFilter: TableFilter[]; eventOptions: any }) {
    if (this.searchThrottleTimer) {
      clearTimeout(this.searchThrottleTimer);
      this.searchThrottleTimer = 0;
    }

    this.searchThrottleTimer = setTimeout(
      () => {
        this.filter = newFilter;
        if (this.page > 1) {
          this.page = 1;
          return;
        }
        this.getData();
      },
      eventOptions.byRemoveBtn ? 0 : 1000
    );
  }
}
</script>

<style scoped></style>
