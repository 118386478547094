import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7da84f0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "user-profile-name ml-1"
}
const _hoisted_2 = { class: "ver-info" }
const _hoisted_3 = { class: "ver-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_menu, {
      location: "bottom",
      offset: [0, 10],
      "z-index": "10000"
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_btn, _mergeProps({
          variant: "plain",
          size: "small",
          ripple: false,
          "min-width": "0"
        }, props, { class: "px-0" }), {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { size: "24px" }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-account-circle")
              ]),
              _: 1
            }),
            (_ctx.user)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.user.username), 1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1040)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, {
          density: "compact",
          nav: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              onClick: _ctx.toggleTheme,
              title: "Light/dark theme"
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  size: "x-small",
                  class: "mr-4"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-brightness-6")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_list_item, { onClick: _ctx.logout }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  size: "x-small",
                  class: "mr-4"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-logout")
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Sign out")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_v_list_item, { class: "app-info" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, { class: "pt-2" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, "APP v" + _toDisplayString(_ctx.appStatus.appVersion), 1),
                    _createElementVNode("div", _hoisted_3, "API v" + _toDisplayString(_ctx.appStatus.apiVersion), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}