import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    flat: "",
    rounded: "0",
    "min-height": "100%",
    class: "main-navigation"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_item, {
        density: "comfortable",
        class: "bg-primary py-3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            align: "center",
            "no-gutters": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "8",
                class: "pa-0 pr-2 text-dark"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { class: "text-h6 font-weight-medium" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Safedrive")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_list_item_subtitle, {
                    opacity: "1",
                    class: "text-darkgrey"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Studio")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "4",
                class: "pa-0 text-right"
              }, {
                default: _withCtx(() => [
                  (_ctx.environment)
                    ? (_openBlock(), _createBlock(_component_v_chip, {
                        key: 0,
                        label: "",
                        color: _ctx.environmentColor,
                        variant: "flat",
                        class: "font-weight-bold text-white"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.environment), 1)
                        ]),
                        _: 1
                      }, 8, ["color"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.user)
        ? (_openBlock(), _createBlock(_component_v_list, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, {
                to: "/dashboard",
                class: "py-4"
              }, {
                prepend: _withCtx(() => [
                  _createVNode(_component_v_icon, { class: "mr-8" }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-view-dashboard")
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Dashboard")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.canViewCustomers)
                ? (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 0,
                    to: "/customers",
                    class: "py-4"
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "mr-8" }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-account-box-multiple")
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Customers")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.canViewDevices)
                ? (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 1,
                    group: "devices",
                    "active-class": "nav-active",
                    "no-action": ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: "Devices",
                        class: "py-4"
                      }), {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { class: "mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-tablet-cellphone")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      (_ctx.canViewDeviceList)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 0,
                            "active-class": "nav-active",
                            to: "/devices/list"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("List")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.canViewDeviceLogs)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 1,
                            "active-class": "nav-active",
                            to: "/devices/logs"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Logs")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.canViewPoi)
                ? (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 2,
                    group: "poi",
                    "active-class": "nav-active",
                    "no-action": ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: "POI",
                        class: "py-4"
                      }), {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { class: "mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-map-marker-multiple")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/poi/list"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("List")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/poi/map"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Map")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.canViewAds)
                ? (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 3,
                    group: "advertisement",
                    "active-class": "nav-active",
                    "no-action": ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: "Advertisement",
                        class: "py-4"
                      }), {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { class: "mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-basket")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/advertisement/adverts"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Adverts")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.canEditAdsSettings)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 0,
                            "active-class": "nav-active",
                            to: "/advertisement/settings"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Settings")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.canViewSupport)
                ? (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 4,
                    group: "support",
                    "active-class": "nav-active",
                    "no-action": ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: "Support",
                        class: "py-4"
                      }), {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { class: "mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-message-reply-text")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      (_ctx.canViewIssues)
                        ? (_openBlock(), _createBlock(_component_v_list_item, {
                            key: 0,
                            "active-class": "nav-active",
                            to: "/support/issues"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Issues")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.canViewAdmin)
                ? (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 5,
                    group: "tools",
                    "active-class": "nav-active",
                    "no-action": ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: "Tools",
                        class: "py-4"
                      }), {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { class: "mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-wrench")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/tools/geohash-map"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Geohash Map")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/tools/device-message-coding"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Device msg de/encode")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/tools/location-history"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Device Location History")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.canViewAdmin)
                ? (_openBlock(), _createBlock(_component_v_list_group, {
                    key: 6,
                    group: "admin",
                    "active-class": "nav-active",
                    "prepend-icon": "",
                    "no-action": ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_list_item, _mergeProps(props, {
                        title: "Admin",
                        class: "py-4"
                      }), {
                        prepend: _withCtx(() => [
                          _createVNode(_component_v_icon, { class: "mr-8" }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-cog")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1040)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/admin/users"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Users")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/admin/user-groups"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("User Groups")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_list_item, {
                        "active-class": "nav-active",
                        to: "/admin/api-keys"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list_item_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("API Keys")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}