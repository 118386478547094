<template>
  <div>
    <v-snackbar v-model="show" location="top" timeout="-1" color="purple">
      Application update available! Refresh to get the latest features and improvements.
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="refresh()" :loading="refreshing">Refresh</v-btn>
        <v-btn icon size="small" color="white" @click="postpone()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { Component, Vue, toNative } from "vue-facing-decorator";
import appStatusService from "@/services/AppStatusService";

@Component({})
class AppUpdateNotification extends Vue {
  updatePosponed = false;
  refreshing = false;

  get show() {
    return appStatusService.updateAvailable && this.updatePosponed === false;
  }

  postpone() {
    this.updatePosponed = true;
  }

  refresh() {
    this.refreshing = true;
    window.location.reload();
  }
}

export default toNative(AppUpdateNotification)
</script>