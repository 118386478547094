import router from "@/router/router";
import userStorageService from "@/services/UserStorageService";

interface AuthInfo {
    username: string | undefined;
    token: string | undefined;
    expires: number | undefined;
    refreshAfter: number | undefined;
    requirePasswordUpdate: boolean | undefined;
    redirectUrl: string | undefined;
}

class AuthService {
    private readonly authStorageKey = "sdAuthInfo";

    get authInfo(): AuthInfo {
        return userStorageService.get(this.authStorageKey) ?? ({} as AuthInfo);
    }

    get isSignedIn(): boolean {
        return this.authInfo.token !== undefined;
    }

    initAuth(username: string, requirePasswordUpdate: boolean | undefined) {
        const info = this.authInfo;
        info.username = username;
        info.requirePasswordUpdate = requirePasswordUpdate;

        this.updateAuthInfo(info);
    }

    signIn(token: string, expires: string) {
        this.setToken(token, expires);
        this.redirect();
    }

    setRequirePasswordUpdate(requirePasswordUpdate: boolean) {
        const info = this.authInfo;
        info.requirePasswordUpdate = requirePasswordUpdate;
        this.updateAuthInfo(info);
    }

    setRedirectUrl(url: string) {
        const info = this.authInfo;
        info.redirectUrl = url ?? "/";
        this.updateAuthInfo(info);
    }

    redirect() {
        const authInfo = this.authInfo;
        if (authInfo.requirePasswordUpdate) {
            router.push("/password-update");
        } else {
            router.push(authInfo.redirectUrl ?? "/");
        }
    }

    setToken(token: string, expires: string) {
        const info = this.authInfo;
        info.token = token;
        info.expires = Date.parse(expires);

        // Calculate next token refresh time (half of the time from now to expire)
        var diff = info.expires - Date.now();
        info.refreshAfter = new Date(Date.now() + diff / 2).getTime();

        this.updateAuthInfo(info);
    }

    signOut() {
        this.updateAuthInfo({} as AuthInfo);
        router.push("/login");
    }

    private updateAuthInfo(authInfo: AuthInfo) {
        userStorageService.set(this.authStorageKey, authInfo);
    }
}

const authService = new AuthService();
export default authService;
