<template>
  <div>
    <div class="text-subtitle-1 mb-1">Customer Change History</div>
    <p class="text-caption">List of the last customer information changes.</p>

    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

    <div v-if="items && items.length">
      <v-divider />
      <v-data-table
        density="compact"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :loading="loading"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.valueOld`]="{ item }">
          {{ formatValue(item.valueOld) }}
        </template>
        <template v-slot:[`item.valueNew`]="{ item }">
          {{ formatValue(item.valueNew) }}
        </template>
        <template v-slot:[`item.modifiedDate`]="{ item }">
          {{ moment(item.modifiedDate).format("lll") }}
        </template>
      </v-data-table>
    </div>
    <div v-else-if="!loading">No changes found.</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch, toNative } from "vue-facing-decorator";
import moment from "moment";
import EntityChangeRecord from "@/types/EntityChangeRecord";
import customerResource from "@/resources/CustomerResource";
import userStorage from "@/services/UserStorageService";

@Component({
  components: {},
})
class CustomerChangeHistory extends Vue {
  moment = moment;

  @Prop()
  readonly customerId!: number | null;

  loading = false;
  items = [] as EntityChangeRecord[];

  @Watch("customerId", { immediate: true })
  onValueChanged() {
    this.items = [];
    this.getData();
  }

  headers = [
    { title: "Modified on", align: "start", key: "modifiedDate" },
    { title: "Modified by", key: "modifiedBy" },
    { title: "Field name", key: "fieldName" },
    { title: "Old value", key: "valueOld" },
    { title: "New value", key: "valueNew" },
  ];

  optionsStorageKey = "customerHistoryTable";
  options = userStorage.get(this.optionsStorageKey) ?? {
    sortBy: [],
    sortDesc: [false]
  };
  
  @Watch("options", { deep: true })
  onPropertyChanged() {
    userStorage.set(this.optionsStorageKey, this.options);
  }

  getData() {
    if (!this.customerId) {
      return;
    }

    this.loading = true;

    customerResource
      .getCustomerChangeHistory(this.customerId)
      .then((resp) => {
        this.items = resp.data;
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        this.loading = false;
      });
  }

  formatValue(val: any) {
    var result = val;
    if (typeof val === "object") {
      result = "";
    } else if (val.toString().match(/^\d\d\d\d-\d\d-\d\d/)) {
      result = moment(val).format("lll");
    }
    
    return result;
  }
}

export default toNative(CustomerChangeHistory)
</script>