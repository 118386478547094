import ResourceBase from "@/resources/ResourceBase";
import Geohashes from "@/types/Geohashes";
import { AxiosResponse, AxiosRequestConfig } from "axios";

class GeohashResource extends ResourceBase {
    getInRadius(
        latitude: number,
        longitude: number,
        radius: number,
        compress: boolean,
        forcePrecision: number | undefined = undefined,
        cancelToken: any | undefined = undefined)
        : Promise<AxiosResponse<Geohashes>> {
        const config = {
            params: {
                latitude,
                longitude,
                radius,
                compress,
                forcePrecision
            },
            cancelToken: cancelToken?.token
        } as AxiosRequestConfig;

        return this.apiClient.get<Geohashes>("/geohashes/inradius", config);
    }
}

const geohashResource = new GeohashResource();
export default geohashResource;
