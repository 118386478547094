export default class MapHelper {
    static readonly defaultMapTilesUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    static readonly defaultMapAttr = '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>';

    static offsetMeters(lat: number, lon: number, latOffsetMeters: number, lonOffsetMeters: number): { lat: number, lon: number } {
        // Number of km per degree = ~111km (111.32 in google maps, but range varies between 110.567km at the equator and 111.699km at the poles)
        const coefLat = latOffsetMeters / 1000 / 111.32;
        const newLat = lat + coefLat;

        // pi / 180 ~= 0.01745
        const coefLon = lonOffsetMeters / 1000 / 111.32;
        const newLon = lon + coefLon / Math.cos(newLat * 0.01745);

        return { lat: newLat, lon: newLon };
    }
}