import ResourceBase from "@/resources/ResourceBase";
import ApiKey from "@/types/ApiKey";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import GenericResponse from "@/types/GenericResponse";
import TopApiKeyEndpointsUsage from "@/types/TopApiKeyEndpointsUsage";

class ApiKeysResource extends ResourceBase {
    getApiKeys(cancelToken?: any): Promise<AxiosResponse<ApiKey[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<ApiKey[]>("/api-keys", config);
    }

    getApiKeyById(apiKeyId: number, cancelToken?: any): Promise<AxiosResponse<ApiKey>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<ApiKey>(`/api-keys/${apiKeyId}`, config);
    }

    addApiKey(apiKey: ApiKey, cancelToken?: any): Promise<AxiosResponse<string>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<string>("/api-keys", apiKey, config);
    }

    updateApiKey(apiKey: ApiKey, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.put<GenericResponse>(`/api-keys/${apiKey.apiKeyId}`, apiKey, config);
    }

    deleteApiKey(apiKeyId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.delete<GenericResponse>(`/api-keys/${apiKeyId}`, config);
    }

    regenerateApiKey(apiKeyId: number, cancelToken?: any): Promise<AxiosResponse<string>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<string>(`/api-keys/${apiKeyId}/rotate`, config)
    }

    getTopEndpointsUsage(apiKeyId: number, days?: number, cancelToken?: any): Promise<AxiosResponse<TopApiKeyEndpointsUsage[]>> {
        const config = { params: { days }, cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<TopApiKeyEndpointsUsage[]>(`/api-keys/${apiKeyId}/top-endpoints-usage`, config)
    }

    getDailyUsageByStatusCode(apiKeyId: number, days?: number, cancelToken?: any): Promise<AxiosResponse<{date: Date, values: {[key:string]: number}}[]>> {
        const config = { params: { days }, cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<{date: Date, values: {[key:string]: number}}[]>(`/api-keys/${apiKeyId}/daily-usage-by-status-code`, config)
    }
}

const apiKeysResource = new ApiKeysResource();
export default apiKeysResource;
