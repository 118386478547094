<template>
  <div>
    <div>
      <v-btn color="secondary" size="small" @click="show = true">History</v-btn>
    </div>
    <v-overlay v-model="show" :opacity="0.33">
      <v-dialog scrim="rgba(33, 33, 33)" eager v-model="show" :max-width="700" @keydown.esc="show = false" hide-overlay>
        <v-card tile>
          <v-toolbar flat color="primary" :height="4"> </v-toolbar>
          <v-card-text class="text-h6 pa-4 pa-md-5 pb-3">Manufacturer Warranty History</v-card-text>
          <div class="mx-3 mx-md-5 flex-grow-1 overflow-auto">
            <v-data-table
              density="compact"
              :loading="warrantyLoading"
              :headers="headers"
              :items="warranties"
              :disable-pagination="true"
              :hide-default-footer="true"
              items-per-page="-1"
              :mobile-breakpoint="0"
              show-expand
              v-model:expanded="expanded"
              item-value="deviceWarrantyId"
            >
              <template v-slot:[`item.deviceWarrantyId`]="{ item }">
                <span v-if="isActiveWarranty(item.warrantyEndDate, item.deviceWarrantyId)" class="status-value text-green">Active</span>
                <span v-else class="status-value text-red">Expired</span>
              </template>

              <template v-slot:[`item.warrantyStartDate`]="{ item }">
                <span class="status-value text-truncate">
                  {{
                    `${moment(item?.warrantyStartDate).format("YYYY-MM-DD")} - ${moment(item?.warrantyEndDate).format(
                      "YYYY-MM-DD"
                    )}`
                  }}
                </span>
              </template>

              <template v-slot:[`item.createdDate`]="{ item }">
                <span class="status-value">
                  {{ `${moment(item?.createdDate).format("YYYY-MM-DD HH:mm")} (${item.createdBy})` }}
                </span>
              </template>

              <template v-slot:[`item.data-table-expand`]="{ item }">
                <v-btn
                  v-if="item.notes"
                  icon
                  flat
                  size="xsmall"
                  @click="expanded = expanded.includes(item.deviceWarrantyId) ? [] : [item.deviceWarrantyId]"
                >
                  <v-icon>{{ expanded.includes(item.deviceWarrantyId) ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
                </v-btn>
              </template>

              <template v-slot:expanded-row="{ item }">
                <div class="mx-4 my-2">
                  {{ item.notes }}
                </div>
              </template>

              <template #bottom></template>
            </v-data-table>
          </div>

          <div class="text-right ma-3 ma-md-6 mt-3">
            <v-btn variant="text" @click="show = false"> Close </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import Device from "@/types/Device";
import axios, { CancelTokenSource } from "axios";
import deviceResource from "@/resources/DeviceResource";
import ManufacturerWarranty from "@/types/ManufacturerWarranty";
import moment from "moment";
import type { VDataTable } from "vuetify/components";

type ReadonlyHeaders = VDataTable["$props"]["headers"];

const props = withDefaults(defineProps<{ device?: Device | null }>(), {
  device: null,
});

const expanded = ref<any[]>([]);
const warranties = ref<ManufacturerWarranty[]>([]);
const warrantyLoading = ref(false);
let cancelWarrantyToken: CancelTokenSource | undefined = undefined;
const show = ref(false);
const headers: ReadonlyHeaders = [
  { title: "Status", align: "start", key: "deviceWarrantyId", sortable: false },
  { title: "Period", key: "warrantyStartDate", sortable: false },
  { title: "Created", key: "createdDate", sortable: false },
  { title: "", key: "data-table-expand", sortable: false },
];

const isActiveWarranty = (warrantyEndDate: Date, deviceWarrantyId: number) => moment().isBefore(moment(warrantyEndDate)) && warranties.value[0]?.deviceWarrantyId === deviceWarrantyId;

const getWarranty = () => {
  // Cancel existing request
  if (cancelWarrantyToken) {
    cancelWarrantyToken.cancel();
  }

  if (props.device === null || !show) {
    return;
  }
  warrantyLoading.value = true;
  cancelWarrantyToken = axios.CancelToken.source();

  deviceResource
    .getManufacturerWarranties(props.device.deviceId, cancelWarrantyToken)
    .then((resp) => {
      warranties.value = resp.data;
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      warrantyLoading.value = false;
      cancelWarrantyToken = undefined;
    });
};
watch(
  show,
  () => {
    if (!show.value) {
      warranties.value = [];
      return;
    }
    getWarranty();
  },
  { immediate: true }
);
</script>
<style scoped>
.title {
  width: 100px;
  font-size: 13px;
}
</style>
