import ResourceBase from "@/resources/ResourceBase";
import PagedData from "@/types/PagedData";
import Tag from "@/types/Tag";
import { TagType } from "@/types/TagType";
import { AxiosResponse, AxiosRequestConfig } from "axios";

class TagResource extends ResourceBase {
    getTagsPaged(
        itemsPerPage: number,
        pageNumber: number,
        searchTerm?: string,
        typeOf?: TagType[],
        orderBy?: string,
        orderDesc?: boolean,
        cancelToken?: any
    ): Promise<AxiosResponse<PagedData<Tag>>> {
        const config = {
            params: {
                itemsPerPage: itemsPerPage,
                pageNumber: pageNumber,
                search: searchTerm,
                typeOf,
                orderBy: orderBy,
                orderDesc: orderDesc,
            },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<Tag>>("/tags", config);
    }

    addTag(tag: Tag, cancelToken: any | undefined = undefined): Promise<AxiosResponse<Tag>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<Tag>("/tags", tag, config);
    }
}

const issueResource = new TagResource();
export default issueResource;
