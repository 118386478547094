<template>
  <div>
    <div v-if="showMap && deviceLocation" class="map-wrap">
      <l-map class="map-container" :zoom.sync="mapZoom" :center="mapCenter" @contextmenu="() => false">
        <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
        <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
        <!-- ACTIVE MARKER -->
        <l-marker-rotate
          :lat-lng="[deviceLocation.latitude, deviceLocation.longitude]"
          :rotationAngle="mapIcon.roatationOrigin ? deviceLocation.heading : 0"
          :rotationOrigin="mapIcon.roatationOrigin ? mapIcon.roatationOrigin : null"
        >
          <l-icon
            :icon-url="mapIcon.iconUrl"
            :icon-size="mapIcon.iconSize"
            :icon-anchor="mapIcon.iconAnchor"
            :tooltipAnchor="[25, 0]"
          ></l-icon>
          <l-tooltip>
            <div class="poi-tooltip">
              <div>Date: {{ moment(deviceLocation.timestamp).format("lll") }}</div>
              <div class="mt-1">Speed: {{ deviceLocation.speed }} km/h</div>
            </div>
          </l-tooltip>
        </l-marker-rotate>
      </l-map>
    </div>

    <div v-if="deviceLocation">
      <span @click="showMap = true" :style="{ cursor: showMap ? 'default' : 'pointer' }">
        {{ deviceLocation.latitude }}, {{ deviceLocation.longitude }}
      </span>
      <v-btn class="ml-2" icon x-small variant="plain" title="Copy coordinates to clipboard" @click="copyToClipboard(`${deviceLocation?.latitude},${deviceLocation?.longitude }`)">
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </div>
    <div v-if="noData">No data available</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue , toNative , Model  } from "vue-facing-decorator";
import moment from "moment";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControlScale, LIcon, LTooltip, LControl } from "@vue-leaflet/vue-leaflet";
//@ts-ignore
import { LMarkerRotate } from 'vue-leaflet-rotate-marker';
import MapHelper from "@/helpers/mapHelper";
import MapIcon from "@/types/MapIcon";
import DeviceLocation from "@/types/DeviceLocation";
import deviceResource from "@/resources/DeviceResource";
import MapIcons from "@/types/MapIcons";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

@Component({
  components: {
    L,
    LMap,
    LTileLayer,
    LMarker,
    LMarkerRotate,
    LControlScale,
    LIcon,
    LTooltip,
    LControl,
  },
})
class DeviceTelemetryView extends Vue {
  moment = moment;

  // Device ID
  @Model()
  readonly value!: number | undefined;

  showMap = false;
  mapCenter = [0, 0];
  mapZoom = 15;
  leaflet = {
    url: MapHelper.defaultMapTilesUrl,
    attribution: MapHelper.defaultMapAttr,
  };
  mapIcon: MapIcon = MapIcons.device;
  deviceLocation: DeviceLocation | null = null;

  noData = false;

  created() {
    this.getTelemetry();
  }


  copyToClipboard(content: string | null) {
    if (content) {
      navigator.clipboard
        .writeText(content)
        .then(() => {
          infoMessageService.show(InfoMessageType.Success, "Copied to clipboard");
        })
        .catch((e) => {
          infoMessageService.show(InfoMessageType.Error, "Failed to copy content");
        });
    }
  }

  getTelemetry() {
    if (!this.value) {
      return;
    }

    deviceResource
      .getLastKnownDeviceLocation(this.value)
      .then((resp) => {
        this.mapCenter = [resp.data.latitude, resp.data.longitude];
        this.deviceLocation = resp.data;
      })
      .catch((e) => {
        if (e.response && e.response.status == 404) {
          this.noData = true;
        } else {
          deviceResource.defaultErrorHandler(e);
        }
      });
  }
}

export default toNative(DeviceTelemetryView)
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 360px;
  margin-bottom: 15px;
}
</style>
