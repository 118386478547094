import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e4361b18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_HtmlSource = _resolveComponent("HtmlSource")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "container"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versionList.slice(0, _ctx.lastLoadInd), (data, i) => {
        return (_openBlock(), _createBlock(_component_v_card, {
          key: i,
          class: "mb-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(" v" + _toDisplayString(data.version), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_card_subtitle, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.moment(data.date).format("MMMM DD, YYYY")), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_divider),
            _createVNode(_component_v_card_text, { class: "ml-6 ma-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_HtmlSource, {
                  src: `/releases/${data.version}.html`
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128)),
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.loading && _ctx.versionList.length > _ctx.lastLoadInd)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              class: "my-2",
              variant: "text",
              size: "small",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.lastLoadInd += _ctx.limit))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, { start: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-arrow-down-bold")
                  ]),
                  _: 1
                }),
                _createTextVNode(" Show more ")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_v_overlay, {
        contained: "",
        "model-value": _ctx.loading,
        opacity: "0.46",
        "z-index": "99999",
        persistent: "",
        class: "align-center justify-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_progress_circular, {
            indeterminate: "",
            color: "primary",
            size: "64"
          })
        ]),
        _: 1
      }, 8, ["model-value"])
    ]),
    _: 1
  }))
}