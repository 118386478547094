import { AdvertType } from "@/types/AdvertType";

export default class AdvertHelper {
    static getAdvertTypeDisplayName(type: AdvertType): string {
        switch (type) {
            case AdvertType.National: return "National";
            case AdvertType.Area: return "Area";
            case AdvertType.Spot: return "Spot";
            default: return type;
        }
    }
}