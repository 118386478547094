<template>
  <div>
    <v-menu location="bottom" :offset="[0, 10]" z-index="10000">
      <template v-slot:activator="{ props }">
        <v-btn variant="plain" size="small" :ripple="false" min-width="0" v-bind="props" class="px-0">
          <v-icon size="24px">mdi-account-circle</v-icon>
          <span v-if="user" class="user-profile-name ml-1">{{ user.username }}</span>
        </v-btn>
      </template>
      <v-list density="compact" nav>
        <v-list-item @click="toggleTheme" title="Light/dark theme">
          <template v-slot:prepend>
            <v-icon size="x-small" class="mr-4">mdi-brightness-6</v-icon>
          </template>
        </v-list-item>
        <v-list-item @click="logout">
          <template v-slot:prepend>
            <v-icon size="x-small" class="mr-4">mdi-logout</v-icon>
          </template>
          <v-list-item-title>Sign out</v-list-item-title>
        </v-list-item>
        <v-list-item class="app-info">
          <v-list-item-title class="pt-2">
            <div class="ver-info">APP v{{ appStatus.appVersion }}</div>
            <div class="ver-info">API v{{ appStatus.apiVersion }}</div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative, Setup  } from "vue-facing-decorator";
import themeSwitcher from "@/services/ThemeSwitcher";
import userProfileService from "@/services/UserProfileService";
import authService from "@/services/AuthService";
import appStatusService from "@/services/AppStatusService";
import authResource from "@/resources/AuthResource";
import { useTheme, ThemeInstance } from "vuetify";

@Component({
  name: "AppBar",
  components: {}
})
class AppBar extends Vue {
  appStatus = appStatusService;


  @Setup((props, ctx) => useTheme())
  theme!: ThemeInstance

  get user() {
    return userProfileService.currentUser;
  }

  logout() {
    authResource
      .signOut()
      .then((resp) => {
        authService.signOut();
      })
      .catch(authResource.defaultErrorHandler);
  }

  toggleTheme() {
    themeSwitcher.toggleTheme();
    this.theme.global.name.value =  this.theme.global.current.value.dark ? 'light' : 'dark'
  }
}

export default toNative(AppBar);
</script>

<style scoped>
.user-profile-name {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-info {
  min-height: 0;
}
.ver-info {
  text-align: right;
  font-size: 0.66rem;
  font-weight: 400;
  opacity: 0.3;
}

:deep() .v-list-item__spacer {
  width: 0px !important;
}
</style>
