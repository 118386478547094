import ResourceBase from "@/resources/ResourceBase";
import RepairItem from "@/types/RepairItem";
import PagedData from "@/types/PagedData";
import Repair from "@/types/Repair";
import RepairTemplate from "@/types/RepairTemplate";
import RepairWorkType from "@/types/RepairWorkType";
import RepairHistory from "@/types/RepairHistory";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import GenericResponse from "@/types/GenericResponse";

class RepairResource extends ResourceBase {
    getRepairs(deviceId: number, cancelToken: any | undefined = undefined): Promise<AxiosResponse<PagedData<RepairItem>>> {
        const config = {
            params: { itemsPerPage: 100, pageNumber: 1, deviceId},
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<RepairItem>>("/repairs", config);
    }

    addRepair(repair: Partial<Repair>, cancelToken?: any): Promise<AxiosResponse<Repair>> {
        const config = { cancelToken: cancelToken?.token} as AxiosRequestConfig;

        return this.apiClient.post<Repair>("/repairs", repair, config);
    }

    updateRepair(repair: Repair, cancelToken?: any): Promise<AxiosResponse<Repair>> {
        const config = {
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.put<Repair>(`/repairs/${repair.repairId}`, repair, config);
    }

    deleteRepair(repairId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = {cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/repairs/${repairId}`, config);
    }

    getRepairById(repairId: number, cancelToken?: any): Promise<AxiosResponse<Repair>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<Repair>(`/repairs/${repairId}`, config);
    }

    getRepairTemplates(cancelToken?: any): Promise<AxiosResponse<RepairTemplate[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<RepairTemplate[]>(`/repairs/templates`, config);
    }

    getRepairWorkTypes(cancelToken?: any): Promise<AxiosResponse<RepairWorkType[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<RepairWorkType[]>(`/repairs/work-types`, config);
    }
    
    getRepairHistory(repairId: number, cancelToken?: any): Promise<AxiosResponse<RepairHistory[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<RepairHistory[]>(`/repairs/${repairId}/history`, config);
    }
}

const repairResource = new RepairResource();
export default repairResource;