<template>
  <side-sheet v-model="showFilters" heading="Filter POI" contentClass="filters">
    <h4>Filter presets</h4>
    <v-select
      attach
      v-model="c_filterPresetsSelected"
      :items="filterPresetsItems"
      density="compact"
      hide-details
      class="filter-item"
      @update:modelValue="applyFilterPreset()"
    />

    <h4 class="mt-6">POI Types</h4>
    <div>
      <v-btn size="x-small" link @click="selectAllPoiTypes()">Select all</v-btn> /
      <v-btn size="x-small" link @click="deselectAllPoiTypes()">Deselect all</v-btn>
    </div>
    <div v-for="type in typeItems" :key="type.value">
      <v-checkbox
        v-model="c_typeSelected"
        :label="type.text"
        :value="type.value"
         density="compact"
        class="type"
        hide-details
        @click="setCustomFilterPreset()"
      />
    </div>

    <h4 class="mt-6">Active status</h4>
    <v-select
      v-model="c_activeSelected"
      :items="activeItems"
      density="compact"
      hide-details
      class="filter-item"
      @update:model-value="setCustomFilterPreset()"
    />

    <template v-slot:actions>
      <v-spacer />
      <v-btn variant="text" @click="resetFilters()">Reset</v-btn>
      <v-btn color="primary" class="ml-4" @click="applyFilters(true)">Apply</v-btn>
    </template>
  </side-sheet>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop, toNative, Model } from "vue-facing-decorator";
import { PoiType } from "@/types/PoiType";
import PoiHelper from "@/helpers/poiHelper";
import SideSheet from "@/components/layout/SideSheet.vue";

@Component({
  name: "PoiFilter", // name is needed for keep-alive
  components: { SideSheet },
  emits: ["update:modelValue", "update"],
})
class PoiFilter extends Vue {
  @Model({ default: false })
  readonly value!: boolean;

  @Prop({ default: "" })
  readonly filterPresetsSelected!: string;

  @Prop({ default: [] })
  readonly typeSelected!: PoiType[];

  @Prop({ default: "" })
  readonly activeSelected!: string;

  c_activeSelected: string = "";
  activeItems = [
    { title: "Any", value: "any" },
    { title: "Active only", value: "active" },
    { title: "Inactive only", value: "inactive" },
  ];

  c_filterPresetsSelected: string = "";
  filterPresetsItems = [
    { title: "All POI", value: "all" },
    { title: "Active non-static POI only", value: "active" },
    { title: "Static POI only", value: "static" },
    { title: "Custom", value: "custom" },
  ];
  c_typeSelected: PoiType[] = [];
  typeItems = [
    { text: this.getPoiTypeDisplayName(PoiType.Checkpoint), value: PoiType.Checkpoint },
    { text: this.getPoiTypeDisplayName(PoiType.SpeedControl), value: PoiType.SpeedControl },
    { text: this.getPoiTypeDisplayName(PoiType.SeatBeltControl), value: PoiType.SeatBeltControl },
    { text: this.getPoiTypeDisplayName(PoiType.TechnicalControl), value: PoiType.TechnicalControl },
    { text: this.getPoiTypeDisplayName(PoiType.CustomsControl), value: PoiType.CustomsControl },
    { text: this.getPoiTypeDisplayName(PoiType.RoadCondition), value: PoiType.RoadCondition },
    { text: this.getPoiTypeDisplayName(PoiType.Animal), value: PoiType.Animal },
    { text: this.getPoiTypeDisplayName(PoiType.SpeedCamera), value: PoiType.SpeedCamera },
    { text: this.getPoiTypeDisplayName(PoiType.AverageCamera), value: PoiType.AverageCamera },
    { text: this.getPoiTypeDisplayName(PoiType.AverageCameraEnd), value: PoiType.AverageCameraEnd },
  ];

  @Watch("showFilters")
  showFiltersData() {
    if (this.showFilters) {
      this.c_filterPresetsSelected = this.filterPresetsSelected;
      this.c_typeSelected = [...this.typeSelected];
      this.c_activeSelected = this.activeSelected;
    }
  }

  get showFilters() {
    return this.value;
  }
  set showFilters(value: boolean) {
    this.$emit("update:modelValue", value);
  }

  created() {
    this.c_activeSelected = this.activeSelected;
    this.c_filterPresetsSelected = this.filterPresetsSelected;
    this.c_typeSelected = this.typeSelected;
    this.applyFilterPreset();
    this.applyFilters(false);
  }

  getPoiTypeDisplayName(type: PoiType) {
    return PoiHelper.getPoiTypeDisplayName(type);
  }

  resetFilters() {
    this.c_filterPresetsSelected = "active";
    this.applyFilterPreset();
    this.applyFilters(true);
  }

  applyFilters(reloadPoi: boolean) {
    const filterPresetsName = this.filterPresetsItems.find((item) => item.value === this.c_filterPresetsSelected)?.title;
    this.$emit(
      "update",
      this.c_filterPresetsSelected,
      [...this.c_typeSelected],
      this.c_activeSelected,
      filterPresetsName,
      reloadPoi
    );

    if (reloadPoi) {
      this.showFilters = false;
    }
  }

  setCustomFilterPreset() {
    this.c_filterPresetsSelected = "custom";
  }

  selectAllPoiTypes() {
    this.c_typeSelected = this.typeItems.map((el) => el.value);
    this.setCustomFilterPreset();
  }

  deselectAllPoiTypes() {
    this.c_typeSelected = [];
    this.setCustomFilterPreset();
  }

  applyFilterPreset() {
    if (this.c_filterPresetsSelected === "all") {
      this.c_typeSelected = this.typeItems.map((el) => el.value);
      this.c_activeSelected = "any";
    } else if (this.c_filterPresetsSelected === "active") {
      this.c_typeSelected = PoiHelper.getNonPermanentPoiTypes();
      this.c_activeSelected = "active";
    } else if (this.c_filterPresetsSelected === "static") {
      this.c_typeSelected = PoiHelper.getPermanentPoiTypes();
      this.c_activeSelected = "any";
    }
  }
}

export default toNative(PoiFilter);
</script>

<style scoped>
.type {
  font-size: 13px;
}
</style>
