<template>
  <v-menu location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn
        icon
        v-bind="props"
        :ripple="false"
        :x-small="isXSmall"
        :small="isSmall"
        :large="isLarge"
        :x-large="isXLarge"
        :plain="plain"
        variant= "text"
        density="compact"
      >
        <v-icon :color="dark ? 'black' : ''">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list light density="compact" class="py-0">
      <v-list-item @click="menuItemCopyId(value)">
        <template v-slot:prepend>
          <v-icon class="mr-2">mdi-content-copy</v-icon>
        </template>
        <v-list-item-title>Copy ID</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canViewDeviceLocation" @click="menuItemOpenLocationHistory(value)">
        <template v-slot:prepend>
          <v-icon class="mr-2">mdi-map-marker-path</v-icon>
        </template>
        <v-list-item-title>Open in Location History</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canViewDeviceLogs" @click="menuItemOpenInLogs(value)">
        <template v-slot:prepend>
          <v-icon class="mr-2">mdi-text-search</v-icon>
        </template>
        <v-list-item-title>Open in Logs</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue, toNative, Model } from "vue-facing-decorator";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import router from "@/router/router";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";

@Component({
  components: {},
})
class DeviceMenu extends Vue {
  @Model({ default: null })
  readonly value!: number;

  @Prop({ default: false })
  readonly dark!: boolean;

  @Prop({ default: "" })
  readonly size!: string;

  @Prop({ default: false })
  readonly plain!: boolean;

  get isXSmall() {
    return this.size === "x-small";
  }

  get isSmall() {
    return this.size === "small";
  }

  get isXLarge() {
    return this.size === "x-large";
  }

  get isLarge() {
    return this.size === "large";
  }

  get canViewDeviceLogs() {
    return userProfileService.hasPermission(UserPermissionType.ViewDeviceLogs);
  }
  get canViewDeviceLocation() {
    return userProfileService.currentUser?.isAdministrator;
  }

  menuItemCopyId(deviceId: number) {
    navigator.clipboard.writeText(deviceId.toString()).catch((e) => {
      infoMessageService.show(InfoMessageType.Error, "Failed to copy text");
    });
  }

  menuItemOpenInLogs(deviceId: number) {
    router.push({ name: "Device Logs", query: { deviceId: deviceId.toString() } }).catch((e) => {
      if (e.message.includes("Navigation aborted")) return;
      console.error(e);
    });
  }

  menuItemOpenLocationHistory(deviceId: number) {
    router.push({ name: "Device Location History", query: { deviceId: deviceId.toString() } }).catch((e) => {
      if (e.message.includes("Navigation aborted")) return;
      console.error(e);
    });
  }
}

export default toNative(DeviceMenu);
</script>
