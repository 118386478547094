<template>
  <div v-click-outside="onBlur" @click="onFocus" class="rich-text-container" :class="styles">
    <QuillEditor :key="`${showToolbar.toString()}-${disabled.toString()}`" :options="editorOption"
      v-model:content="inputValue" contentType="html" :readOnly="disabled" :class="styles" ref="quillEditor" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, toNative, Model, Watch } from "vue-facing-decorator";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";

@Component({
  components: { QuillEditor },
  emits: ["update:modelValue", "focus", "blur"],
})
class RichText extends Vue {
  @Model({ default: "" })
  readonly value!: string;

  @Prop({ default: false })
  readonly disabled!: boolean;

  @Prop({ default: true })
  readonly small!: any;

  @Prop({ default: false })
  readonly placeholder!: string;

  @Prop({ default: false })
  readonly alwaysFocus!: boolean;

  showToolbar = false;
  lastUpdate = "";

  mounted() {
    if (this.alwaysFocus) {
      this.onFocus();
    }
  }

  @Watch("value")
  syncValue() {
    if (this.lastUpdate !== this.value) {
      //@ts-ignore
      if (this.$refs?.quillEditor?.setContents) {
        //@ts-ignore
        this.$nextTick(() => this.$refs?.quillEditor?.setContents?.(this.value));
      }
    }
  }
  get styles() {
    return {
      "w-hover": true,
      "hide-toolbar": !this.showToolbar,
      activeRichText: this.showToolbar,
      "small-rich-text": this.small,
    };
  }

  get editorOption() {
    return { placeholder: this.value ? '' : this.placeholder, modules: { toolbar: true } };
  }

  get inputValue() {
    return this.value;
  }

  set inputValue(newValue) {
    const editorValue = newValue === "<p></p>" || newValue === "<p><br></p>" ? "" : newValue;
    this.lastUpdate = editorValue;

    this.$emit("update:modelValue", editorValue);
  }

  onFocus() {
    if (!this.disabled && !this.showToolbar) {
      //@ts-ignore
      if (this.$refs?.quillEditor?.focus && this.value?.length) {
        this.$nextTick(() => {
          setTimeout(() => {
            //@ts-ignore
            const quill = this.$refs?.quillEditor?.getQuill();
            quill.setSelection(this.value.length, 0, "user");
          }, 0);
        });
      }

      this.showToolbar = true;
      this.$emit("focus");
    }

    //@ts-ignore
    if (this.$refs?.quillEditor?.focus) {
      //@ts-ignore
      this.$nextTick(() => this.$refs?.quillEditor?.focus?.());
    }
  }

  onBlur() {
    if (this.alwaysFocus) return;

    if (this.showToolbar) {
      this.showToolbar = false;
      this.$emit("blur");
    }
  }
}
export default toNative(RichText);
</script>

<style>
.rich-text-container {
  display: flex;
  flex-direction: column-reverse;
  border: none;
  border-radius: 4px;
  position: relative;
}

.rich-text-container .ql-toolbar,
.rich-text-container .ql-container {
  border: none;
}

.v-theme--dark .ql-tooltip.ql-editing {
  background-color: #2d2d2d;
  box-shadow: none;
  color: #fff;
}

.v-theme--dark .ql-snow .ql-picker-options {
  background-color: #2d2d2d;
}

.v-theme--dark .ql-snow .ql-tooltip {
  background-color: #2d2d2d;
  box-shadow: none;
  color: #fff;
}

.v-theme--dark .ql-tooltip.ql-editing input[type="text"] {
  background-color: #2d2d2d;
  box-shadow: none;
  color: #fff;
  outline: none;
}

.ql-tooltip.ql-editing {
  z-index: 99999;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.v-theme--dark .ql-editor.ql-blank::before {
  color: #fff;
  opacity: 0.4;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  stroke: rgb(var(--v-theme-primary)) !important;
  color: rgb(var(--v-theme-primary)) !important;
}

.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  background: none;
}

:root[theme="dark"] .ql-toolbar .ql-stroke {
  stroke: #bbbbbb;
}

:root[theme="dark"] .ql-toolbar .ql-fill {
  fill: #bbbbbb;
}

:root[theme="dark"] .ql-toolbar .ql-picker {
  color: #bbbbbb;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
  background: none;
  color: rgb(var(--v-theme-primary)) !important;
  stroke: rgb(var(--v-theme-primary)) !important;
}

:root[theme="dark"] .ql-snow .ql-picker-options {
  background: #2d2d2d;
}

.small-rich-text .ql-container.ql-snow {
  min-height: 0;
}

.ql-container.ql-snow {
  min-height: 150px;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hide-toolbar .ql-toolbar {
  padding: 0;
}

.hide-toolbar .ql-toolbar {
  display: none;
}

.ql-editor {
  font-family: Roboto, sans-serif !important;
  font-size: 0.875rem;
  padding: 5px 0;
}

.ql-editor.ql-blank::before {
  right: 0;
  left: 0;
}

.activeRichText .ql-editor.ql-blank::before {
  right: 15px;
  left: 15px;
}

.rich-text-container.activeRichText {
  border: 1px solid rgb(var(--v-theme-primary));
  box-shadow: inset 0px 0px 0px 1px rgb(var(--v-theme-primary));
}

.activeRichText .ql-editor {
  font-family: Roboto, sans-serif !important;
  font-size: 0.875rem;
  padding: 10px 15px;
}

.ql-snow a {
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
