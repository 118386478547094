import { createApp, defineComponent, h } from "vue";
import ConfirmDialog from "@/components/layout/ConfirmDialog.vue";
import vuetify from "@/plugins/vuetify";

export class ConfirmService {
    createDialogCmp(options: any): Promise<boolean> {
        const container = document.createElement("div");
        container.id = "confirm-dialog";
        return new Promise((resolve) => {
            const app = createApp(
                defineComponent({
                    render() {
                        return h(ConfirmDialog, {
                            ...options,
                            onResult: (value: boolean) => {
                                document.body.removeChild(container);
                                if (app) app.unmount(); //remove the instance}
                                resolve(value);
                            },
                        });
                    },
                })
            );

            app.use(vuetify);

            document.body.appendChild(container);
            app.mount(container);
        });
    }

    show(message: string, options: any = {}): Promise<boolean> {
        options.message = message;
        return this.createDialogCmp(options);
    }
}

const confirmService = new ConfirmService()
export default function Confirm(app: ReturnType<typeof createApp>): void {
    app.config.globalProperties.$confirm = confirmService;
}

export function useConfirm() {
    return confirmService;
}