import "vuetify/styles";
import themeSwitcher from "@/services/ThemeSwitcher";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VTimePicker } from "vuetify/labs/VTimePicker";

const vuetify = createVuetify({
    components: { ...components, VTimePicker },
    directives,
    defaults: {
        VTextField: { variant: "underlined", color: "primary", clearIcon: "mdi-close" },
        VFileInput: { variant: "underlined", color: "primary", clearIcon: "mdi-close" },
        VSwitch: { color: "primary", density: "compact" },
        VCard: { class: "overflow-initial", style: "z-index: initial" },
        VCardTitle: { style: "white-space: normal" },
        VSelect: { variant: "underlined", color: "primary", listProps: { density: "compact" } },
        VCheckboxBtn: { color: "primary" },
        VSlider: { color: "primary", density: "compact", thumbSize: 12, trackSize: 2 },
        VRangeSlider: { color: "primary", density: "compact", thumbSize: 12, trackSize: 2 },
        VAutocomplete: { variant: "underlined", clearIcon: "mdi-close", listProps: { density: "compact" } },
        VTab: { baseColor: "tabColor" },
        VProgressLinear: { color: "primary", height: "2" },
        VWindow: { class: "overflow-visible" },
        VCombobox: { clearIcon: "mdi-close" },
    },

    theme: {
        defaultTheme: themeSwitcher.isDark ? "dark" : "light",
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: "#FEDD10",
                    'primary-darken-1':"#FFA50F",
                    secondary: "#2D2D2B",
                    accent: "#FEDD10",
                    error: "#FF5252",
                    info: "#2196F3",
                    success: "#4CAF50",
                    warning: "#FFC107",
                    dark: "#1D1D1B",
                    darkgrey: "#3D3D3D",
                    "dark-lighten3": "#60605e",
                    "darkgrey-darken1": "#272727",
                    tabColor: "#808080",
                    badge: "#bebebe",
                    deepRed: "AF2C2C",
                },
            },
            dark: {
                dark: true,
                colors: {
                    primary: "#FEDD10",
                    'primary-darken-1':"#FFA50F",
                    secondary: "#2D2D2B",
                    accent: "#FEDD10",
                    error: "#FF5252",
                    info: "#2196F3",
                    success: "#4CAF50",
                    warning: "#FFC107",
                    dark: "#1D1D1B",
                    darkgrey: "#3D3D3D",
                    "dark-lighten3": "#60605e",
                    "darkgrey-darken1": "#272727",
                    "surface-bright": "#bdbdbd",

                    tabColor: "#aeaeae",
                    badge: "#555",
                    deepRed: "AF2C2C",
                },
            },
        },
    },
});

export default vuetify;
