import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_PropEditor = _resolveComponent("PropEditor")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_side_sheet = _resolveComponent("side-sheet")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.value)
      ? (_openBlock(), _createBlock(_component_side_sheet, {
          key: 0,
          modelValue: _ctx.showDialog,
          "onUpdate:modelValue": _ctx.close,
          onClickOutside: _ctx.close,
          heading: _ctx.dialogHeading,
          noClickAnimation: true
        }, {
          actions: _withCtx(() => [
            (_ctx.allowDelete && _ctx.value.userGroupId && _ctx.isDeleteBtn)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  color: "secondary",
                  onClick: _ctx.deleteUserGroupConfirm,
                  loading: _ctx.deleting,
                  disabled: _ctx.deleting
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Delete")
                  ]),
                  _: 1
                }, 8, ["onClick", "loading", "disabled"]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_spacer),
            _createVNode(_component_v_btn, {
              variant: "text",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel")
              ]),
              _: 1
            }),
            _createVNode(_component_v_btn, {
              color: "primary",
              class: "ml-4",
              onClick: _ctx.submit,
              loading: _ctx.loading,
              disabled: _ctx.loading || _ctx.disabledSubmitBtn
            }, {
              default: _withCtx(() => [
                _createTextVNode("Submit")
              ]),
              _: 1
            }, 8, ["onClick", "loading", "disabled"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_form, {
              ref: "userForm",
              modelValue: _ctx.valid,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valid) = $event)),
              "lazy-validation": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PropEditor, {
                  name: "Name",
                  desc: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      density: "compact",
                      variant: "outlined",
                      modelValue: _ctx.value.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.name) = $event)),
                      rules: _ctx.nameRules
                    }, null, 8, ["modelValue", "rules"])
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.permissions, (pg) => {
                  return (_openBlock(), _createBlock(_component_PropEditor, {
                    key: pg.name,
                    name: pg.name,
                    valign: 'top'
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pg.permissions, (p) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "d-flex justify-start align-center",
                          key: p.name
                        }, [
                          _createVNode(_component_v_switch, {
                            class: "my-0",
                            modelValue: p.active,
                            "onUpdate:modelValue": ($event: any) => ((p.active) = $event),
                            onClick: _ctx.setChangesStatus,
                            label: p.name
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick", "label"]),
                          (p.desc)
                            ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                key: 0,
                                location: "bottom",
                                "max-width": "400px",
                                "open-on-click": ""
                              }, {
                                activator: _withCtx(({ props }) => [
                                  _createVNode(_component_v_icon, _mergeProps({ ref_for: true }, props, {
                                    size: "small",
                                    class: "ml-2 opacity-70"
                                  }), {
                                    default: _withCtx(() => [
                                      _createTextVNode("mdi-help-circle")
                                    ]),
                                    _: 2
                                  }, 1040)
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(p.desc), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["name"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["modelValue", "onUpdate:modelValue", "onClickOutside", "heading"]))
      : _createCommentVNode("", true)
  ]))
}