import ResourceBase from "@/resources/ResourceBase";
import GenericResponse from "@/types/GenericResponse";
import Authorization from "@/types/Authorization";
import MfaResponse from "@/types/MfaResponse";

import { AxiosResponse, AxiosRequestConfig } from "axios";
import AuthRefresh from "@/types/AuthRefresh";

class AuthResource extends ResourceBase {
    signIn(username: string, password: string): Promise<AxiosResponse<Authorization>> {
        const data = { username, password } as any;
        const config = { withCredentials: true } as AxiosRequestConfig;
        return this.apiClient.post<Authorization>(`/auth/sign-in`, data, config);
    }

    verify(code: string, username: string): Promise<AxiosResponse<MfaResponse>> {
        const data = { mfaChallengeAnswer: code, username } as AxiosRequestConfig;
        const config = { withCredentials: true } as AxiosRequestConfig;
        return this.apiClient.post<MfaResponse>(`/auth/mfa`, data, config);
    }

    resendCode(username: string): Promise<AxiosResponse<GenericResponse>> {
        const data = { username } as any;
        return this.apiClient.post<GenericResponse>(`/auth/mfa-resend`, data);
    }

    updatePassword(
        currentPassword: string,
        newPassword: string,
        repeatNewPassword: string
    ): Promise<AxiosResponse<GenericResponse>> {
        const data = {
            currentPassword,
            newPassword,
            repeatNewPassword,
        } as any;
        return this.apiClient.post<GenericResponse>(`/userprofile/update-password`, data);
    }

    refreshToken(cancelToken: any | undefined = undefined): Promise<AxiosResponse<AuthRefresh>> {
        const data = {
            cancelToken: cancelToken?.token,
        } as any;
        const config = { withCredentials: true } as AxiosRequestConfig;

        return this.apiClient.post<AuthRefresh>("auth/refresh", data, config);
    }

    signOut(): Promise<AxiosResponse<Authorization>> {
        const data = {} as any;
        const config = { withCredentials: true } as AxiosRequestConfig;
        return this.apiClient.post<Authorization>(`/auth/sign-out`, data, config);
    }
}

const authResource = new AuthResource();
export default authResource;
