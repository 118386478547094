import InfoMessage from "@/types/InfoMessage";
import { InfoMessageType } from "@/types/InfoMessageType";
import { reactive } from "vue"

const state = reactive({
    messages: [] as InfoMessage[],
});

class InfoMessageService {
    readonly state = state;

    show(type: InfoMessageType, msg: string, timeout: number = 5000): void {
        if (this.state.messages.some(({ message }) => message === msg)) {
            return;
        }
        const message: InfoMessage = {
            id: Date.now(),
            type: type,
            message: msg,
        };
        this.state.messages.push(message);

        setTimeout(() => {
            this.state.messages = state.messages.filter((m) => m.id != message.id);
        }, timeout);
    }

    showMany(type: InfoMessageType, msgs: string[]): void {
        for (var msg of msgs) {
            this.show(type, msg);
        }
    }

    clear(): void {
        this.state.messages = [];
    }
}

const infoMessageService = new InfoMessageService();
export default infoMessageService;
