<template>
  <RouterView />
</template>

<script setup lang="ts"></script>
<style>
  /**
    Overwrite vuetify's default v-application--wrap min-height: 100vh;
    Due to the many problems with vh on mobile devices.
   */
  .v-application--wrap {
    min-height: 100dvh !important;;
  }
</style>